import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import * as S from "./styles";
import Cropper from "react-easy-crop";
import {
  Col,
  Progress,
  notification,
  Modal,
  Slider,
  Button,
  Popover,
} from "antd";
import { useSelector } from "react-redux";
import { getUser } from "../../app/store/authSlice";
import { useAPITag } from "../../services/api";
import { ConfigUserAPI } from "../FormConfigUser/ConfigUserAPI";
import { useTranslation } from "react-i18next";
import {
  ZoomInOutlined,
  ZoomOutOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  CameraOutlined,
  FileImageOutlined,
} from "@ant-design/icons";

const AvatarChanger = ({ link, text, ...props }) => {
  const { t } = useTranslation();
  const user = useSelector(getUser);
  const { http } = useAPITag();
  const { updateAvatarUrl } = ConfigUserAPI();

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [optimizedImageUrl, setOptimizedImageUrl] = useState("");
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1.3);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [isCameraSupported, setIsCameraSupported] = useState(false);

  const fileInputRef = useRef(null);
  const isDraggingSliderRef = useRef(false);
  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    const checkCameraSupport = async () => {
      try {
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
          setIsCameraSupported(false);
          return;
        }

        const devices = await navigator.mediaDevices.enumerateDevices();
        const hasCamera = devices.some(
          (device) => device.kind === "videoinput"
        );
        setIsCameraSupported(hasCamera);
      } catch (error) {
        console.error("Erro ao verificar suporte à câmera:", error);
        setIsCameraSupported(false);
      }
    };

    checkCameraSupport();
  }, []);

  useEffect(() => {
    return () => {
      if (imageUrl) URL.revokeObjectURL(imageUrl);
      if (optimizedImageUrl) URL.revokeObjectURL(optimizedImageUrl);
    };
  }, []);

  const showModal = () => {
    setModalVisible(true);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleZoomChange = useCallback(
    (newZoom) => {
      if (newZoom === zoom) return;
      setZoom(newZoom);
    },
    [zoom]
  );

  const handleRotationChange = useCallback(
    (newRotation) => {
      if (newRotation === rotation) return;
      setRotation(newRotation);
    },
    [rotation]
  );

  const zoomSliderProps = useMemo(
    () => ({
      min: 1,
      max: 3,
      step: 0.01,
      value: zoom,
      onChange: handleZoomChange,
      style: { flex: 1 },
      trackStyle: { backgroundColor: "#f67e48" },
      handleStyle: { borderColor: "#f67e48" },
      onBeforeChange: () => {
        isDraggingSliderRef.current = true;
      },
      onAfterChange: () => {
        isDraggingSliderRef.current = false;
      },
    }),
    [zoom, handleZoomChange]
  );

  const rotationSliderProps = useMemo(
    () => ({
      min: -180,
      max: 180,
      step: 1,
      value: rotation,
      onChange: handleRotationChange,
      style: { flex: 1 },
      trackStyle: { backgroundColor: "#f67e48" },
      handleStyle: { borderColor: "#f67e48" },
      onBeforeChange: () => {
        isDraggingSliderRef.current = true;
      },
      onAfterChange: () => {
        isDraggingSliderRef.current = false;
      },
    }),
    [rotation, handleRotationChange]
  );

  const getTag = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < 16; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  const NOTIFY_ID = getTag();

  const openNotification = useCallback(
    (totalFiles, filesCount, currentFileName, percentage) => {
      notification.open({
        key: NOTIFY_ID,
        message:
          t("uploadingFiles", "Arquivos sendo enviados") +
          ` ${filesCount} / ${totalFiles}`,
        description: (
          <Col>
            <p>{t("total", "Total")}</p>
            <Progress percent={Math.floor((filesCount / totalFiles) * 100)} />
          </Col>
        ),
      });
    },
    [NOTIFY_ID, t]
  );

  const optimizeImage = useCallback((file) => {
    return new Promise((resolve, reject) => {
      const tempUrl = URL.createObjectURL(file);

      const img = new Image();
      img.onload = () => {
        const MAX_WIDTH = 1200;
        const MAX_HEIGHT = 1200;

        let width = img.width;
        let height = img.height;

        if (width > MAX_WIDTH || height > MAX_HEIGHT) {
          const ratio = Math.min(MAX_WIDTH / width, MAX_HEIGHT / height);
          width = Math.floor(width * ratio);
          height = Math.floor(height * ratio);
        }

        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            const optimizedUrl = URL.createObjectURL(blob);

            URL.revokeObjectURL(tempUrl);

            resolve(optimizedUrl);
          },
          "image/jpeg",
          0.85
        );
      };

      img.onerror = () => {
        URL.revokeObjectURL(tempUrl);
        reject(new Error("Erro ao carregar a imagem"));
      };

      img.src = tempUrl;
    });
  }, []);

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.src = url;
    });

  const getCroppedImg = async (imageSrc, pixelCrop, rotation = 0) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const maxOutputSize = 500;
    const scale = Math.min(1, maxOutputSize / pixelCrop.width);

    canvas.width = pixelCrop.width * scale;
    canvas.height = pixelCrop.height * scale;

    ctx.fillStyle = "#ffffff";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate((rotation * Math.PI) / 180);
    ctx.translate(-canvas.width / 2, -canvas.height / 2);

    ctx.beginPath();
    ctx.arc(
      canvas.width / 2,
      canvas.height / 2,
      canvas.width / 2,
      0,
      2 * Math.PI
    );
    ctx.clip();

    ctx.drawImage(
      image,
      pixelCrop.x * scale,
      pixelCrop.y * scale,
      pixelCrop.width * scale,
      pixelCrop.height * scale,
      0,
      0,
      canvas.width,
      canvas.height
    );

    return canvas;
  };

  const uploadFilePromise = useCallback(
    (canvas, index, totalFiles, name) => {
      return new Promise((resolve, reject) => {
        canvas.toBlob(
          (blob) => {
            const prefixedName = `Up_${name || "avatar.jpg"}`;
            const bodyFormData = new FormData();
            bodyFormData.append("files", blob, prefixedName);
            bodyFormData.append("companyId", user._id);
            bodyFormData.append("filename", name || "avatar.jpg");

            http
              .post("/files", bodyFormData, {
                headers: { "Content-Type": "multipart/form-data" },
                onUploadProgress: (progressEvent) => {
                  const percentage = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  setUploadProgress(percentage);
                  openNotification(totalFiles, index, name, percentage);
                },
              })
              .then((response) => {
                resolve(response);
              })
              .catch((error) => {
                reject(error);
              });
          },
          "image/jpeg",
          0.85
        );
      });
    },
    [http, openNotification, user._id]
  );

  const onChangeFiles = useCallback(
    async (e) => {
      const file = e.target.files && e.target.files[0];
      if (file) {
        if (file.type.startsWith("image/")) {
          try {
            setSelectedImage(file);

            const url = URL.createObjectURL(file);
            setImageUrl(url);

            const optimizedUrl = await optimizeImage(file);
            setOptimizedImageUrl(optimizedUrl);

            showModal();
          } catch (error) {
            console.error("Erro ao processar imagem:", error);
            notification.error({
              message: t("error", "Erro"),
              description: t(
                "imageProcessingError",
                "Erro ao processar a imagem"
              ),
              placement: "bottom",
            });
          }
        } else {
          setSelectedImage(null);
          notification.error({
            message: t("error", "Erro"),
            description: t(
              "invalidImageType",
              "Tipo de arquivo inválido. Selecione uma imagem."
            ),
            placement: "bottom",
          });
        }
      }
    },
    [t, optimizeImage]
  );

  const handleModalOk = async () => {
    if (!selectedImage || !croppedAreaPixels) return;

    setIsUploading(true);
    try {
      const sourceUrl = optimizedImageUrl || imageUrl;
      const canvas = await getCroppedImg(
        sourceUrl,
        croppedAreaPixels,
        rotation
      );

      const response = await uploadFilePromise(
        canvas,
        1,
        1,
        selectedImage.name || "avatar.jpg"
      );

      notification.success({
        message: t("success", "Sucesso"),
        description: t(
          "photoUpdatedSuccessfully",
          "Foto atualizada com sucesso"
        ),
        placement: "bottom",
      });

      if (response && response.data && response.data.url) {
        await updateAvatarUrl({ avatar: response.data.url });

        resetFileInput();
        setSelectedImage(null);
        setImageUrl("");
        setOptimizedImageUrl("");
        setModalVisible(false);

        setTimeout(() => {
          window.location.reload();
        }, 800);
      } else {
        throw new Error("URL do avatar não encontrada na resposta");
      }
    } catch (error) {
      console.error(error);
      notification.error({
        message: t("error", "Erro"),
        description: t("uploadError", "Erro ao enviar a imagem"),
        placement: "bottom",
      });
      setIsUploading(false);
      setUploadProgress(0);
    }
  };

  const handleModalCancel = () => {
    setModalVisible(false);
    setSelectedImage(null);
    setImageUrl("");
    setOptimizedImageUrl("");
    setCrop({ x: 0, y: 0 });
    setZoom(1.3);
    setRotation(0);
    resetFileInput();
  };

  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handlePopoverVisibleChange = (visible) => {
    setPopoverVisible(visible);
  };

  const handleClosePopover = useCallback(() => {
    setPopoverVisible(false);
  }, []);

  const handleCaptureFromCamera = useCallback(() => {
    if (fileInputRef.current) {
      const captureValue = "environment";

      const newInput = document.createElement("input");
      newInput.type = "file";
      newInput.accept = "image/*";
      newInput.setAttribute("capture", captureValue);
      newInput.style.display = "none";

      const handleChange = (e) => {
        onChangeFiles(e);
        document.body.removeChild(newInput);
      };

      newInput.addEventListener("change", handleChange);

      document.body.appendChild(newInput);

      setTimeout(() => {
        newInput.click();
      }, 100);
    }

    setPopoverVisible(false);
  }, [onChangeFiles]);

  const handleSelectFromGallery = useCallback(() => {
    if (fileInputRef.current) {
      const newInput = document.createElement("input");
      newInput.type = "file";
      newInput.accept = "image/*";
      newInput.style.display = "none";

      const handleChange = (e) => {
        onChangeFiles(e);
        document.body.removeChild(newInput);
      };

      newInput.addEventListener("change", handleChange);

      document.body.appendChild(newInput);

      setTimeout(() => {
        newInput.click();
      }, 100);
    }

    setPopoverVisible(false);
  }, [onChangeFiles]);

  const handleInputFileClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setPopoverVisible(true);
  }, []);

  const popoverContent = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        padding: "5px 0",
      }}
    >
      {isCameraSupported && (
        <Button
          icon={<CameraOutlined />}
          onClick={handleCaptureFromCamera}
          style={{
            height: isMobile ? "40px" : "36px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <span style={{ marginLeft: "8px" }}>
            {t("takePhoto", "Tirar foto")}
          </span>
        </Button>
      )}

      <Button
        icon={<FileImageOutlined />}
        onClick={handleSelectFromGallery}
        style={{
          height: isMobile ? "40px" : "36px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <span style={{ marginLeft: "8px" }}>
          {t("chooseFromGallery", "Escolher da galeria")}
        </span>
      </Button>
    </div>
  );

  return (
    <>
      <Popover
        content={popoverContent}
        title={t("selectImageSource", "Selecionar origem da imagem")}
        trigger="click"
        open={popoverVisible}
        onOpenChange={handlePopoverVisibleChange}
        placement="bottom"
        overlayStyle={{
          width: isMobile ? "220px" : "250px",
        }}
        destroyTooltipOnHide
      >
        <S.Upload>
          <input
            ref={fileInputRef}
            type="file"
            accept="image/*"
            onChange={onChangeFiles}
            onClick={handleInputFileClick}
            style={{
              display: "none",
              position: "absolute",
              pointerEvents: "none",
            }}
            tabIndex="-1"
            aria-hidden="true"
            {...props}
          />
          {text}
        </S.Upload>
      </Popover>

      <Modal
        title={t("adjustPhoto", "Ajustar foto")}
        open={modalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        okText={t("save", "Salvar")}
        okButtonProps={{
          disabled: !selectedImage || isUploading,
          style: {
            backgroundColor: selectedImage && !isUploading ? "#f67e48" : "gray",
            height: isMobile ? "44px" : "32px",
            fontSize: isMobile ? "16px" : "14px",
          },
        }}
        cancelButtonProps={{
          style: {
            height: isMobile ? "44px" : "32px",
            fontSize: isMobile ? "16px" : "14px",
          },
        }}
        width={isMobile ? "94vw" : 400}
        centered
        maskClosable={false}
        destroyOnClose={true}
      >
        {selectedImage && (optimizedImageUrl || imageUrl) ? (
          <div>
            <div
              style={{
                position: "relative",
                height: 300,
                backgroundColor: "#f0f0f0",
                borderRadius: "8px",
                overflow: "hidden",
              }}
            >
              <Cropper
                image={optimizedImageUrl || imageUrl}
                crop={crop}
                zoom={zoom}
                rotation={rotation}
                aspect={1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={
                  isDraggingSliderRef.current ? undefined : handleZoomChange
                }
                onRotationChange={
                  isDraggingSliderRef.current ? undefined : handleRotationChange
                }
                cropShape="round"
                showGrid={false}
              />

              {isUploading && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 10,
                  }}
                >
                  <Progress
                    type="circle"
                    percent={uploadProgress}
                    width={80}
                    strokeColor="#f67e48"
                  />
                  <p style={{ marginTop: 15, color: "#333", fontSize: "16px" }}>
                    {t("uploading", "Enviando")}...
                  </p>
                </div>
              )}
            </div>

            <div style={{ margin: "20px 10px 10px 10px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "12px",
                }}
              >
                <ZoomOutOutlined
                  style={{
                    fontSize: "16px",
                    marginRight: "8px",
                    color: "#555",
                  }}
                />
                <Slider {...zoomSliderProps} />
                <ZoomInOutlined
                  style={{ fontSize: "16px", marginLeft: "8px", color: "#555" }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <RotateLeftOutlined
                  style={{
                    fontSize: "16px",
                    marginRight: "8px",
                    color: "#555",
                  }}
                />
                <Slider {...rotationSliderProps} />
                <RotateRightOutlined
                  style={{ fontSize: "16px", marginLeft: "8px", color: "#555" }}
                />
              </div>
            </div>

            <div
              style={{
                marginTop: 15,
                textAlign: "center",
                color: "#888",
                fontSize: "14px",
              }}
            >
              {t("dragAndPinchToAdjust", "Arraste e redimensione para ajustar")}
            </div>
          </div>
        ) : (
          <div
            style={{
              padding: "20px",
              textAlign: "center",
              color: "#555",
            }}
          >
            {t("selectImageFirst", "Selecione uma imagem primeiro")}
          </div>
        )}
      </Modal>
    </>
  );
};

export default AvatarChanger;
