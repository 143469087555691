import { useEffect } from "react";
import { Spin } from "antd";
import ConfirmCodeAPI from "../confirm-code/ConfirmCodeAPI2";
import { useSelector } from "react-redux";
import { selectRegisterstate } from "../RegisterPetSlice";

export const FinishPage = () => {
  const { CheckCode } = ConfirmCodeAPI();
  const registerState = useSelector(selectRegisterstate);

  useEffect(() => {
    CheckCode({
      code: registerState.code,
    });
  }, []);

  return (
    <div className="flex items-center justify-center w-full h-screen">
      <Spin size="large" />
    </div>
  );
};
