import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import RegisterPetLayout from "../../../components/layouts/RegisterPetLayout";
import { Spin } from "antd";
import Swal from "sweetalert2";
import { WhatsAppOutlined } from "@ant-design/icons";
import * as S from "../auth-phone/styles";
import { saveState, selectRegisterstate } from "../RegisterPetSlice";
import { useAPITag } from "../../../services/api";
import AuthPhoneApiPre from "./AuthPhoneApiPre";

export const FinalRegister2 = () => {
  const { http } = useAPITag();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { ValidateTag } = AuthPhoneApiPre();

  const registerState = useSelector(selectRegisterstate);
  const [isLoading, setIsLoading] = useState(false);
  const isClickedRef = useRef(false);

  useEffect(() => {
    ValidateTag(id);
  }, []);

  const fetchGeneratedCodeAndRedirect = async () => {
    setIsLoading(true);
    try {
      const response = await http.post("/user-token/whatsapp", {
        tagCode: id,
        userId: "",
        phone: "",
      });

      dispatch(saveState());

      if (response.data.status) {
        const message = `Olá, vim ativar a coleira ${id} e o código gerado é: ${response.data.code}`;
        const whatsappUrl = `https://wa.me/15551850122?text=${encodeURIComponent(
          message
        )}`;
        window.location.href = whatsappUrl;
      } else {
        Swal.fire({
          icon: "error",
          title: "Erro",
          text: "Não foi possível gerar o código. Tente novamente mais tarde.",
        });
        isClickedRef.current = false;
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: "Ocorreu um erro ao gerar o código. Verifique sua conexão e tente novamente.",
      });
      isClickedRef.current = false;
    } finally {
      setIsLoading(false);
    }
  };

  const handleWhatsAppRedirect = () => {
    if (isClickedRef.current) return;
    isClickedRef.current = true;
    fetchGeneratedCodeAndRedirect();
  };

  const handleSMSRedirect = () => {
    navigate(`/authSms/${id}`);
  };

  return !registerState.config.logo ? (
    <div className="flex items-center justify-center w-full h-screen">
      <Spin size="large" />
    </div>
  ) : (
    <RegisterPetLayout
      hasHeader
      description="Escolha a opção para ativar a coleira."
      title="Cadastrar nova coleira"
      size={5}
      step={1}
    >
      <div className="flex flex-col w-full gap-4 mt-4 justify-center items-center">
        <S.SubmitWhatsappButton
          onClick={handleWhatsAppRedirect}
          type="primary"
          htmlType="button"
          disabled={isLoading}
        >
          {isLoading ? (
            <Spin size="small" />
          ) : (
            <>
              <WhatsAppOutlined /> Ativar coleira pelo WhatsApp
            </>
          )}
        </S.SubmitWhatsappButton>

        <S.SubmitWhatsappButton
          onClick={handleSMSRedirect}
          type="primary"
          htmlType="button"
        >
          Ativar coleira via SMS
        </S.SubmitWhatsappButton>
      </div>
    </RegisterPetLayout>
  );
};

export default FinalRegister2;
