import { Button, Col, DatePicker, Form, Modal, Row } from "antd";
import * as S from "./styles";
import Swal from "sweetalert2";
import { GestorPetAPI } from "./gestorPetAPI";
import { useForm } from "antd/es/form/Form";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUser } from "../../app/store/authSlice";
import { UploadInput } from "../../components/uploadInput";
import { useTranslation } from 'react-i18next';

export const Vacines = (props) => {
  const { t } = useTranslation();
  const { updatePet } = GestorPetAPI();
  const [newConsultModal, setNewCosultModal] = useState(false);
  const [form] = useForm();
  const [photo, setPhoto] = useState([]);
  const [vacine, setVacine] = useState("");
  const user = useSelector(getUser);
  const createHistoric = async () => {
    setNewCosultModal(true);
  };

  const vacines = [
    {
      label: "Antiparasitório",
      value: "antipulgas",
    },
    {
      label: "Antirrábica",
      value: "vacinaAntirrabica",
    },
    {
      label: "FivFelv",
      value: "vacinaFivFelv",
    },
    {
      label: "Leishmaniose",
      value: "vacinaLeishmaniose",
    },
    {
      label: "V3V4",
      value: "vacinaV3V4",
    },
    {
      label: "VacinaV8",
      value: "vacinaV8",
    },
    {
      label: "Vermifugado",
      value: "vermifugado",
    },
  ];

  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  const onFinish = async (e) => {
    if (e.vacine == "other") {
      if (!props.pet.vacines) {
        props.pet.vacines = [];
      }
      await updatePet(props.pet._id, {
        vacines: [
          ...props.pet.vacines,
          {
            ativo: true,
            data: e.data.toISOString(),
            nome: e.title,
            descricao: e.descricao,
            photo: photo[0] ?? "",
            remember: e.remember >= 1 ? addDays(Date.now(), e.remember) : 0,
          },
        ],
      });
    } else {
      await updatePet(props.pet._id, {
        [e.vacine]: {
          ativo: true,
          remember: e.remember >= 1 ? addDays(Date.now(), e.remember) : 0,

          data: e.data.toISOString(),
          descricao: e.descricao,
          photo: photo[0] ?? "",
        },
      });
    }

    setNewCosultModal(false);
    props.refresh();
    form.setFieldsValue({});
  };

  const isOwner = () => {
    if (user == null) return false;

    return props.pet.tutor?._id === user._id;
  };
  return (
    <Col span={24}>
      <Modal
        onCancel={() => {
          setNewCosultModal(false);
          form.setFieldsValue({});
        }}
        onOk={form.submit}
        open={newConsultModal}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 24 }}
          labelAlign="top"
          labelWrap
          wrapperCol={{ flex: 1 }}
          colon={false}
          style={{ width: "80%" }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item name="vacine" label={t('vaccine')}>
            <S.StyledSelect
              options={[
                ...vacines,
                {
                  label: t('other'),
                  value: "other",
                },
              ]}
              onChange={(e) => setVacine(e)}
            />
          </Form.Item>

          {form.getFieldValue("vacine") == "other" && (
            <Form.Item
              name="title"
              label={t('vaccineName')}
              rules={[{ required: true, message: t('enterVaccineName') }]}
            >
              <S.StyledInput />
            </Form.Item>
          )}

          <Form.Item
            name="descricao"
            label={t('vaccineDescription')}
            rules={[{ required: true, message: t('enterDiagnosis') }]}
          >
            <S.StyledInput />
          </Form.Item>

          <Form.Item
            name="remember"
            label={t('reminderDays')}
            initialValue={0}
            rules={[]}
          >
            <S.StyleInputNumber />
          </Form.Item>

          <Form.Item
            name="data"
            label={t('vaccineDate')}
            rules={[{ required: true, message: t('enterConsultationDate') }]}
          >
            <DatePicker
              format={"DD/MM/YYYY"}
              placeholder={t('selectConsultationDate')}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Form>
        <UploadInput        
          label={t('uploadVaccineImage')}
          onChange={setPhoto}
          paths={photo}
        ></UploadInput>
      </Modal>
      {isOwner() && (
        <Row justify={"end"}>
          <Button onClick={createHistoric}>{t('addVaccine')}</Button>
        </Row>
      )}
      <Col className="w-full">
        {vacines.map((e) => {
          return props.pet[e.value] && props.pet[e.value].ativo ? (
            <Row style={{ marginTop: "3rem" }}>
              <InfoPet title={t('vaccine')} info={e.label} />
              <InfoPet title={t('description')} info={props.pet[e.value].descricao} />
              <InfoPet title={t('dose')} info={t('oneDose')} />
              <InfoPet
                title={t('date')}
                info={new Date(props.pet[e.value].data).toLocaleDateString(
                  "pt-BR"
                )}
              />
              <Button
                onClick={() => {
                  if (props.pet[e.value].photo) {
                    Swal.fire({
                      imageUrl: props.pet[e.value].photo.url,
                      imageAlt: "imagemUrl",
                    });
                  }
                }}
              >
                {t('viewImage')}
              </Button>
            </Row>
          ) : (
            ""
          );
        })}

        {props.pet.vacines?.map((e) => {
          return (
            <Row style={{ marginTop: "3rem" }}>
              <InfoPet title={t('vaccine')} info={e.nome} />
              <InfoPet title={t('description')} info={e.descricao} />
              <InfoPet title={t('dose')} info={t('oneDose')} />
              <InfoPet
                title={t('date')}
                info={new Date(e.data).toLocaleDateString("pt-BR")}
              />
              <Button
                onClick={() => {
                  if (e.photo) {
                    Swal.fire({
                      imageUrl: e.photo.url,
                      imageAlt: "imagemUrl",
                    });
                  }
                }}
              >
                {t('viewImage')}
              </Button>
            </Row>
          );
        })}
      </Col>
    </Col>
  );
};

const InfoPet = (props) => {
  return (
    <Col span={5}>
      <Row>
        <Col span={24}>
          <Col>
            <S.InfoTitle>{props.title}</S.InfoTitle>
          </Col>
          <S.InfoPet>{props.info}</S.InfoPet>
        </Col>
      </Row>
    </Col>
  );
};
