import { Button, Input, Col, InputNumber, Layout, Select } from "antd";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  width: 440px;
  min-height: 100vh;
  background-color: white;
  border-radius: 25px;
  padding: 1.4rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2rem;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Tutor = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
export const AboutWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const PetName = styled.h1`
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  margin-left: 1rem;
  margin-right: 1rem;
  line-height: 20px;
  /* identical to box height, or 125% */

  letter-spacing: -0.01em;

  /* Neutrals/2 */

  color: #23262f;
`;

export const TutorName = styled.h1`
  margin-left: 1rem;
  margin-right: 2rem;
  font-family: Readex Pro;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #777e90;
  margin-top: 2rem;
`;

export const WhatsappButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 12px;
  color: white;
  width: 92%;
  height: 48px;
  background: ${({ theme }) => theme.primaryColor} !important;
  border-radius: 90px;
  margin-top: 2rem;
  &:hover {
    color: white !important;
    border-color: ${({ theme }) => theme.primaryColor} !important;
  }
`;

export const WhatsappFloatingButton = styled(Button)`
  display: flex;
  position: fixed;
  bottom: 15px;
  color: white !important;
  border-color: white !important;
  /* -webkit-box-pack: center; */
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  padding: 16px 24px;
  /* gap: 12px; */
  color: white;
  width: 120px;
  height: 60px;
  border-radius: 90px;
  z-index: 50;
  right: 20px;
  /* margin-top: 2rem; */
  background: #128c7e !important;
`;

export const CallButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 12px;
  color: ${({ theme }) => theme.primaryColor};
  width: 92%;
  height: 48px;
  background: white !important;
  border-color: ${({ theme }) => theme.primaryColor} !important;
  border-radius: 90px;
  margin-top: 2rem;
  &:hover {
    color: ${({ theme }) => theme.primaryColor} !important;
    border-color: ${({ theme }) => theme.primaryColor} !important;
  }
`;

export const AboutPet = styled.h1`
  font-family: "Readex Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 13px;
  /* identical to box height, or 93% */
  margin-top: 3rem;
  display: flex;
  align-items: center;

  color: #2c2d42;
`;

export const SubAbout = styled.h1`
  font-family: "Readex Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 9.61772px;
  line-height: 19px;
  /* identical to box height, or 200% */

  display: flex;
  align-items: center;
  text-transform: uppercase;

  /* Neutrals 1 */

  color: #141416;
`;
export const PetInfo = styled.h1`
  font-family: "Readex Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14.4266px;
  line-height: 19px;
  /* identical to box height, or 133% */

  /* Neutrals 4 */

  color: #777e91;
`;

export const Avatar = styled.div`
  height: 80vw !important;
  width: 80vw !important;
  border-radius: 16px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${(props) => props.url});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
  }

  &::before {
    content: "";
    position: absolute;
    top: -12px;
    left: -12px;
    right: -12px;
    bottom: -12px;
    border-radius: 20px;
    border: 2px solid #f67e48;
    pointer-events: none;
    z-index: 0;
  }

  @media screen and (min-width: 320px) {
    width: 300px !important;
    height: 300px !important;
    min-height: 300px;
    padding-top: 1rem;
    margin-top: 3rem;
  }

  @media screen and (min-width: 375px) {
    width: 320px !important;
    height: 320px !important;
    min-height: 320px;
    padding-top: 1rem;
  }

  @media screen and (min-width: 440px) {
    width: 360px !important;
    height: 360px !important;
    min-height: 360px;
    padding-top: 1rem;
  }
`;
export const StyledInput = styled(Input)`
  height: 50px;
  border-radius: 50px;
`;

export const StylePassInput = styled(Input.Password)`
  height: 50px;
  border-radius: 50px;
`;

export const SubmitButton = styled(Button)`
  border-radius: 50px;
  height: 50px;
`;

export const StyledLayout = styled(Layout)`
  height: auto;
  flex: auto;
  flex-direction: column;
  display: flex;
  background-color: white;
`;

export const GestorPetWrapper = styled(Col)`
  padding: 3rem;

  @media screen and (max-width: 480px) {
    padding: 0;
  }
`;

export const BreedCrumbFinal = styled.span`
  font-style: normal;
  font-weight: 700;
  margin-left: 0.5rem;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  /* Neutrals/2 */

  color: #b1b5c3;
`;

export const BreedCrumbBegin = styled.span`
  font-style: normal;
  font-weight: 700;
  margin-left: 0.5rem;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  /* Neutrals/2 */

  color: #777e90;
`;

export const BackButton = styled(Button)`
  background-color: white;
  border: 2px solid lightgray;
  border-radius: 80px;
  width: 120px;
  height: 50px;
`;

export const BackButtonTitle = styled.span`
  font-style: normal;
  font-weight: 700;
  margin-left: 0.5rem;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  /* Neutrals/2 */

  color: #23262f;
`;

export const InfoTitle = styled.span`
  font-weight: 700;
  font-size: 12px;
  line-height: 21px;
  /* identical to box height, or 175% */

  text-transform: uppercase;
  font-feature-settings: "ss03" on;

  color: #777e90;
`;

export const InfoPet = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 12px;
  /* identical to box height, or 150% */

  font-feature-settings: "ss03" on;

  color: #23262f;
`;

export const PetTitleWrapper = styled(Col)`
  margin-left: 1rem;
  @media screen and (max-width: 480px) {
    margin-left: 0;
    span {
      text-align: left;
    }
  }
`;

export const HealthStateWrapper = styled(Col)`
  width: 123px;
  height: 46px;
  margin-top: 2rem;
  margin-left: 0.5rem;
  @media screen and (max-width: 480px) {
    margin-top: 0;
    margin-left: 2rem;
  }
`;

export const HealthTitle = styled.span`
  margin-left: 1rem;
  color: var(--main-colors-dark-1, #192126);
  font-feature-settings: "ss01" on;
  font-family: Readex Pro;
  font-size: 10.444px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 15.667px */
`;
export const HealthDescribe = styled.span`
  margin-left: 1rem;

  color: var(--greyscale-grey-400, #9ea3ae);
  font-feature-settings: "ss01" on;
  font-family: Readex Pro;
  font-size: 7.833px;
  font-style: normal;
  font-weight: 500;
`;

export const PetTitle = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* identical to box height, or 127% */
  color: #353945;
`;

export const PetEditTitle = styled.span`
  font-family: "Readex Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  cursor: pointer;
  letter-spacing: 0.1px;

  color: #747a80;
  @media screen and (max-width: 480px) {
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const PetAvatar = styled(Avatar)`
  border: 3px #f67e48 solid !important;
  @media screen and (max-width: 480px) {
    margin-top: -1rem;
  }
`;

export const StyledTextArea = styled(Input.TextArea)`
  height: 50px;
  border-radius: 8px;
  resize: none;
`;

export const StyleInputNumber = styled(InputNumber)`
  min-height: 38px !important;
  width: 100%;
  border-radius: 8px;
`;

export const StyledSelect = styled(Select)`
  .ant-select-selector {
    min-height: 38px !important;
    border-radius: 8px;
  }
`;
