import { Form, Modal, Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  closeModal,
  isModalOpen,
  isRecoveringPassword,
  setRecovering,
} from "../../app/store/authSlice";
import * as S from "./styles";
import Logo from "../../assets/logo.png";
import { AuthAPI } from "./AuthAPI";
import { useState } from "react";
import { useTranslation } from 'react-i18next';

export const ModalAuth = () => {
  const { t } = useTranslation();
  const modalIsOpen = useSelector(isModalOpen);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [inputDisabled, setInputDisabled] = useState(true);
  const isRecovering = useSelector(isRecoveringPassword);

  const [currentAction, setCurrentAction] = useState("login");

  const closeAuthModal = () => {
    setCurrentAction("login");
    dispatch(closeModal());
  };

  const { authUser, auth, tryRecover, tryResetCode } = AuthAPI(closeAuthModal);

  const AuthPhone = () => { };

  const onChange = (e) => {
    if (e.unmaskedValue.length > 5) {
      setInputDisabled(false);
    } else {
      setInputDisabled(true);
    }
  };

  return (
    <Modal
      okCancel={false}
      okButtonProps={{ style: { display: "none" } }}
      cancelButtonProps={{ style: { display: "none" } }}
      onCancel={closeAuthModal}
      open={modalIsOpen}
    >
      <Tabs
        defaultActiveKey="1"
        centered
        items={[
          {
            label: t('email'),
            key: "1",
            children: (
              <S.Content>
                <img
                  style={{ marginBottom: "20px" }}
                  width={250}
                  src={Logo}
                ></img>

                {isRecovering ? (
                  <Form
                    name="basic"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    style={{ width: "100%" }}
                    initialValues={{ remember: true }}
                    onFinish={
                      currentAction === "resetCode" ? tryResetCode : tryRecover
                    }
                    autoComplete="off"
                  >
                    <Form.Item
                      name="email"
                      label={t('email1')}
                      rules={[
                        {
                          required: true,
                          message: t('enterEmail'),
                          type: "email",
                        },
                      ]}
                    >
                      <S.StyledInput />
                    </Form.Item>

                    <Form.Item>
                      <S.SubmitButton
                        style={{ width: "100%", backgroundColor: "#f67e48", }}
                        type="primary"
                        htmlType="submit"
                      >
                        {t('sendCode')}
                      </S.SubmitButton>
                    </Form.Item>
                  </Form>
                ) : (
                  <Form
                    name="basic"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    style={{ width: "100%" }}
                    initialValues={{ remember: true }}
                    onFinish={authUser}
                    autoComplete="off"
                  >
                    <Form.Item
                      name="email"
                      label={t('email1')}
                      rules={[
                        {
                          required: true,
                          message: t('enterEmail'),
                          type: "email",
                        },
                      ]}
                    >
                      <S.StyledInput />
                    </Form.Item>

                    <Form.Item
                      name="password"
                      label={t('password')}
                      rules={[{ required: true, message: t('enterPassword') }]}
                    >
                      <S.StylePassInput style={{ color: "black !important" }} />
                    </Form.Item>

                    <Form.Item style={{ textAlign: "end" }}>
                      <S.SubmitButton
                        onClick={() => {
                          setCurrentAction("resetCode");
                          dispatch(setRecovering(true));
                        }}
                        style={{
                          border: "none",
                          height: "30px",
                          color: "#f67e48",
                        }}
                        type="secondary"
                      >
                        {t('forgotPassword')}
                      </S.SubmitButton>

                      <S.SubmitButton
                        style={{
                          width: "100%",
                          backgroundColor: "#f67e48",
                        }}
                        type="primary"
                        htmlType="submit"
                      >
                        {t('login')}
                      </S.SubmitButton>
                    </Form.Item>

                    <a
                      href="/register-user"
                      style={{ textDecoration: "none", width: "100%" }}
                    >
                      <S.SubmitButton
                        style={{
                          width: "100%",
                          border: "1px solid #f67e48",
                          color: "#f67e48",
                          marginBottom: "1rem",
                        }}
                        type="secondary"
                      >
                        {t('register')}
                      </S.SubmitButton>
                    </a>

                    <S.SubmitButton
                      onClick={() => {
                        setCurrentAction("recover");
                        dispatch(setRecovering(true));
                      }}
                      style={{
                        width: "100%",
                        border: "none",
                        height: "30px",
                        color: "#f67e48",
                      }}
                      type="secondary"
                    >
                      {t('loginWithoutPassword')}
                    </S.SubmitButton>
                  </Form>
                )}
              </S.Content>
            ),
          },
        ]}
      ></Tabs>
    </Modal>
  );
};

/**


 {
            label: `Celular`,
            key: "2",
            children: (
              <S.Content>
                <img
                  style={{ marginBottom: "20px" }}
                  width={250}
                  src={Logo}
                ></img>
                <Form
                  form={form}
                  name="basic"
                  labelCol={{ span: 8 }}
                  labelAlign="top"
                  labelWrap
                  layout="inline"
                  wrapperCol={{ flex: 8 }}
                  colon={false}
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: 25,
                  }}
                  initialValues={{ remember: true }}
                  onFinish={AuthPhone}
                  autoComplete="off"
                >
                  <Form.Item
                    name="ddd"
                    rules={[{ required: true, message: "Informe o DDD" }]}
                  >
                    <S.StyledAreaInput maxLength={2} />
                  </Form.Item>
                  <Form.Item
                    name="telefone"
                    rules={[{ required: true, message: "Informe o telefone" }]}
                  >
                    <S.StyledInputPhone
                      onChange={onChange}
                      mask={"0000-00000"}
                    />
                  </Form.Item>
                </Form>
                <S.SubmitButton
                  style={{ width: "100%" }}
                  type="primary"
                  onClick={() => form.submit()}
                  disabled={inputDisabled}
                  htmlType="submit"
                >
                  Entrar
                </S.SubmitButton>

                <S.SubmitButton
                  style={{
                    width: "100%",
                    border: "1px solid #f67e48",
                    color: "#f67e48",
                    marginTop: "2rem",
                  }}
                  type="secondary"
                >
                  <a href="/registro">Cadastrar</a>
                </S.SubmitButton>
              </S.Content>
            ),
          },*/
