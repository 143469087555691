import { useNavigate, useParams } from "react-router-dom";
import { useAPITag } from "../../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { setConfig, setTagCode, setWhatsappStatus } from "../RegisterPetSlice";
import { getUser, isLoggedIn } from "../../../app/store/authSlice";

const AuthPhoneAPI = () => {
  const tagApi = useAPITag();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLogged = useSelector(isLoggedIn);
  const user = useSelector(getUser);

  const ValidateTag = async (tagCode) => {
    dispatch(setTagCode({ tagCode }));
    try {
      const perfilResponse = await tagApi.http.get("tags/" + tagCode);
      if (perfilResponse.data.tag.statusQr === "pending") {
        navigate(`/preRegister/profile/${tagCode}`);
        return;
      }
      if (perfilResponse.data.hasPet && !isLogged) {
        navigate("/profile/location/" + tagCode);
        return;
      }
      if (perfilResponse.data.hasPet && isLogged) {
        navigate("/profile/" + tagCode + "?scanned=true");
        return;
      }
      if (window.location.hostname === "6e.ae") {
        window.location.href = "https://ajuda.pet/6e" + tagCode;
      }
      dispatch(setConfig(perfilResponse.data.config));

      // Implementar a rota de health check para o whatsapp
      dispatch(setWhatsappStatus({ status: true }));

      if (isLogged) {
        const petsResponse = await tagApi.http.get("pets/user/" + user._id);
        // await tagApi.http.patch('/tags/change-status/' + tagCode, { statusQr: "pending", userPhone: user.phone });
        if (petsResponse.data.length > 0) {
          navigate("/select-pet");
          return;
        }
        navigate("/register-pet");
      }
    } catch {
      console.log("ValidateTag error");
    }
  };

  return { ValidateTag };
};

export default AuthPhoneAPI;
