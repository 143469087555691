import { useState } from "react";
import {
  WhatsAppOutlined,
  HomeOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import confetti from "canvas-confetti";
import Header from "../../components/Header";

const RedirectToWhatsApp = () => {
  const [redirecting, setRedirecting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleRedirect = () => {
    const phoneNumber = "5531988884400";
    const message = encodeURIComponent(
      "Olá, estou com uma dúvida. Pode me ajudar?"
    );

    const isAndroid = /Android/i.test(navigator.userAgent);
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

    let whatsappUrl = "";

    if (isAndroid) {
      whatsappUrl = `intent://send?phone=${phoneNumber}&text=${message}#Intent;scheme=whatsapp;package=com.whatsapp;end;`;
    } else if (isIOS) {
      whatsappUrl = `whatsapp://send?phone=${phoneNumber}&text=${message}`;
    } else {
      whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;
    }

    window.location.href = whatsappUrl;
    setRedirecting(true);

    setTimeout(() => {
      setIsModalOpen(true);
    }, 2000);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setRedirecting(false);
  };

  const handleConfirmResolution = () => {
    setIsModalOpen(false);

    confetti({
      particleCount: 150,
      spread: 180,
      origin: { y: 0.6 },
    });

    navigate("/");
  };

  return (
    <div className="relative w-full min-h-screen bg-gray-100">
      <div className="absolute top-0 left-0 w-full z-50">
        <Header />
      </div>

      <div className="flex flex-col items-center justify-center min-h-screen p-4 pt-16">
        <div className="bg-white shadow-lg rounded-2xl p-6 w-full max-w-sm text-center">
          <h2 className="text-2xl font-semibold text-gray-700 mb-4">
            Atendimento via WhatsApp
          </h2>
          <p className="text-gray-500 mb-6">
            Clique no botão abaixo para abrir uma conversa diretamente no
            WhatsApp.
          </p>

          {redirecting ? (
            <div className="flex flex-col items-center">
              <svg
                className="animate-spin h-8 w-8 text-[#f67e48] mb-4"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8v8H4z"
                ></path>
              </svg>
              <p className="text-gray-500 text-sm">Aguarde...</p>
            </div>
          ) : (
            <>
              <button
                className="flex items-center justify-center gap-2 w-full bg-[#f67e48] hover:bg-[#e86d3e] text-white font-bold py-3 px-6 rounded-xl text-lg transition-all shadow-md hover:shadow-lg transform hover:scale-105 mb-4"
                onClick={handleRedirect}
              >
                <WhatsAppOutlined className="text-xl" /> Iniciar conversa
              </button>

              <button
                className="flex items-center justify-center gap-2 w-full bg-gray-400 hover:bg-gray-500 text-white font-bold py-3 px-6 rounded-xl text-lg transition-all shadow-md hover:shadow-lg transform hover:scale-105"
                onClick={() => navigate("/")}
              >
                <HomeOutlined className="text-xl" /> Voltar para Home
              </button>
            </>
          )}
        </div>
      </div>

      {isModalOpen && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm"
          onClick={handleCloseModal}
        >
          <div
            className="bg-white rounded-xl shadow-lg p-6 w-full max-w-md text-center relative"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              onClick={handleCloseModal}
            >
              <CloseOutlined />
            </button>
            <h3 className="text-xl font-semibold text-gray-800">
              Problema resolvido?
            </h3>
            <p className="text-gray-600 mt-2 mb-6">
              Seu problema foi resolvido após a conversa no WhatsApp?
            </p>
            <div className="flex justify-center gap-4">
              <button
                className="bg-gray-400 hover:bg-gray-500 text-white font-bold py-2 px-4 rounded-lg transition-all"
                onClick={handleCloseModal}
              >
                Ainda preciso de ajuda
              </button>
              <button
                className="bg-[#f67e48] hover:bg-[#e86d3e] text-white font-bold py-2 px-4 rounded-lg transition-all"
                onClick={handleConfirmResolution}
              >
                Sim, problema resolvido 🎉
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RedirectToWhatsApp;
