import { Button, Input } from "antd";
import { MaskedInput } from "antd-mask-input";
import styled from "styled-components";

export const Wrapper = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const InputWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;

  input {
    width: 45px;
    height: 45px;
    border: solid 1px #d9d9d9;
    border-radius: 12px;
    background-color: #f4f5f6;
    text-align: center;
    font-size: 20px;

    &:focus-visible {
      border: solid 1px black !important;
      outline-width: 0;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  width: 95%;
  background-color: white;
  padding: 0 2rem 0 2rem;
  border-radius: 25px;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
`;

export const StyledInput = styled(MaskedInput)`
  height: 50px;
  border-radius: 50px;
`;

export const StylePassInput = styled(Input.Password)`
  height: 50px;
  border-radius: 50px;
`;

export const SubmitButton = styled(Button)`
  border-radius: 50px;
  height: 50px;
  width: 344px;

  @media screen and (max-width: 480px) {
    width: 95%;
    position: fixed;
    bottom: 15px;
    margin-bottom: 0;
    margin: auto;
  }
`;

export const ResendCodeButton = styled.button`
  margin-top: 24px;
  border: unset;
  background-color: unset;
  display: flex;
  cursor: pointer;
`;
