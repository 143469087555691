import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useGetPet from "../../data/usecases/get-pet";
import * as S from "./styles";
import {
  Badge,
  Modal,
  Card,
  Col,
  Row,
  Spin,
  Form,
  Tag,
  DatePicker,
  Typography,
} from "antd";
import Logo from "../../components/logo";
import { useSelector } from "react-redux";
import edit from "../../assets/editIcon.png";
import { useForm } from "antd/es/form/Form";
import { UploadInput } from "../../components/uploadInput";
import TextArea from "antd/es/input/TextArea";
import { NumericFormat } from "react-number-format";
import { GestorPetAPI } from "../../features/gestor-pet/gestorPetAPI";
import {
  petFeaturesProfile,
  racasCachorro,
  racasGato,
  tiposSaude,
  petFeatures,
} from "../../features/register-pet/register-pet/RegisterPetMock";
import dayjs from "dayjs";
import { getUser, isLoggedIn, setCurrentPet } from "../../app/store/authSlice";
import {
  selectConfig,
  selectRegisterstate,
} from "../register-pet/RegisterPetSlice";
import { CheckCircleTwoTone } from "@ant-design/icons";
import RegisterPetLayout from "../../components/layouts/RegisterPetLayout";
import { WhatsappButton } from "../../components/WhatsappButton";
import { ModalAuth } from "../../components/modalAuth";
import { useTranslation } from "react-i18next";
import { FaPaw, FaRegTrashAlt } from "react-icons/fa";
import { sortOptions } from "../../data/utils/sortOptions";

const ProfilePet = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [scanned, setScanned] = useState(searchParams.get("scanned"));
  const registerState = useSelector(selectRegisterstate);
  const [modalEditPet, setModalEditPet] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [qrCodeToDelete, setQrCodeToDelete] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const isLogged = useSelector(isLoggedIn);

  const [pet, setPet] = useState({
    tutor: {},
    notes:
      "32324324324fds fsdfjifsdf pdsjfdsfjisdf sdfujds8fydsfhdsfdsfmnsf msdifjsdfsdfd",
  });
  const getPet = useGetPet();
  const config = useSelector(selectConfig);
  const [form] = useForm();
  const [count, setCount] = useState(0);
  const [photo, setPhoto] = useState([]);
  const { Text } = Typography;
  const user = useSelector(getUser);

  const { getPetData, updatePet, deactivateTag } = GestorPetAPI();

  useEffect(() => {
    setSearchParams([]);
    loadPet();
  }, []);

  const vacinas = [
    { value: "vacinaFivFelv", label: "Vacina FivFelv" },
    { value: "vacinaAntirrabica", label: "Vacina Antirrábica" },
    { value: "vacinaLeishmaniose", label: "Vacina Leishmaniose" },
    { value: "vacinaV3V4", label: "Vacina V3 V4" },
    { value: "vacinaV8", label: "Vacina V8" },
    { value: "vermifugado", label: "Vermifugado" },
  ];

  const loadPet = async () => {
    setIsLoading(true);
    try {
      const response = await getPet(id);
      setPet(response[0]);

      const fullPhone = `${
        response[0].tutor.countryCode +
        (response[0].tutor.phone || response[0].tutor.telefone)
      }`;
      setUserPhone(fullPhone);
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const calculateAge = (dateBirth) => {
    var dob = new Date(dateBirth);
    // Calcula a diferença em milissegundos entre a data de nascimento e a data atual
    var ageInMilliseconds = Date.now() - dob.getTime();

    // Converte a diferença em milissegundos para anos, meses e dias
    var ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);
    var ageInMonths = ageInYears * 12;
    var ageInDays = ageInMilliseconds / (1000 * 60 * 60 * 24);

    if (ageInDays < 30) {
      return ageInDays < 1
        ? `${Math.round(ageInDays)} dias`
        : `${Math.round(ageInDays)} dia(s)`;
    } else if (ageInMonths < 12) {
      return ageInMonths < 1
        ? `${Math.round(ageInMonths)} meses`
        : `${Math.round(ageInMonths)} mês(es)`;
    } else {
      return ageInYears < 1
        ? `${Math.round(ageInYears)} ano`
        : `${Math.round(ageInYears)} anos`;
    }
  };

  const handleEditClick = () => {
    setModalEditPet(true);
    console.log("Editando");
  };
  const handleEditPetClose = () => {
    setModalEditPet(false);
  };

  const onFinish = async (e) => {
    if (photo.length > 0) {
      e.avatar = photo[0].url;
      e.fotos = photo.map((photo) => photo.url);
    }
    setIsLoading(true);
    await updatePet(pet._id, e);

    setModalEditPet(false);
    form.setFieldsValue({});
    loadPet();
    setIsLoading(false);
  };

  const showDeleteModal = (qrCode) => {
    setQrCodeToDelete(qrCode);
    setDeleteModalVisible(true);
  };

  const handleDeleteQrCode = async () => {
    try {
      const updatedPet = await deactivateTag(pet._id, qrCodeToDelete);
      setPet(updatedPet);
      setDeleteModalVisible(false);
      setQrCodeToDelete("");
    } catch (error) {
      console.error("Erro ao excluir o QR Code:", error);
    } finally {
      navigate("/");
    }
  };

  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
    setQrCodeToDelete("");
  };

  const isOwner = () => {
    if (user == null) return false;

    return pet.tutor?._id === user._id;
  };

  return !registerState.config.logo ? (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    ></div>
  ) : (
    <>
      {modalEditPet && (
        <Modal
          visible={modalEditPet}
          onCancel={handleEditPetClose}
          okButtonProps={{ style: { backgroundColor: "#f67e48" } }}
          onOk={() => form.submit()}
          okText="Salvar"
        >
          <Form
            form={form}
            name="basic"
            labelCol={{ span: 24 }}
            labelAlign="top"
            labelWrap
            wrapperCol={{ flex: 1 }}
            colon={false}
            style={{ width: "100%" }}
            initialValues={{ remember: true }}
            onValuesChange={() => {
              setCount(count + 1);
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <UploadInput
              onChange={setPhoto}
              label={"Atualizar foto do Pet"}
              paths={photo}
            ></UploadInput>

            <Form.Item
              name="nome"
              label="Nome"
              rules={[{ required: true, message: "Informe a cor do pelo" }]}
            >
              <S.StyledInput />
            </Form.Item>
            <Form.Item
              name="especie"
              label="Espécie"
              initialValue="dog"
              style={{ marginTop: "2rem" }}
              rules={[{ required: true, message: "Informe a Espécie do Pet" }]}
            >
              <S.StyledSelect
                initialValue="dog"
                options={[
                  { value: "dog", label: "Cachorro" },
                  { value: "cat", label: "Gato" },
                ]}
              />
            </Form.Item>

            {form.getFieldValue("especie") === "dog" ? (
              <Form.Item
                name="raca"
                label="Raça"
                rules={[{ required: true, message: "Informe a Raça do Pet" }]}
              >
                <S.StyledSelect options={sortOptions(racasCachorro)} />
              </Form.Item>
            ) : (
              <Form.Item
                name="raca"
                label="Raça"
                rules={[{ required: true, message: " Informe a Raça do Pet" }]}
              >
                <S.StyledSelect options={sortOptions(racasGato)} />
              </Form.Item>
            )}

            <Form.Item
              name="genero"
              label="Gênero"
              rules={[{ required: true, message: "Informe o genêro do Pet" }]}
            >
              <S.StyledSelect
                initialValue="female"
                options={[
                  { value: "fêmea", label: "Fêmea" },
                  { value: "macho", label: "Macho" },
                ]}
              />
            </Form.Item>

            <Form.Item
              initialValue="tudoCerto"
              name="saude"
              label="Estado de saúde"
            >
              <S.StyledSelect defaultValue="tudoCerto" options={tiposSaude} />
            </Form.Item>

            <Form.Item
              name="corPelo"
              label="Cor do Pêlo"
              rules={[{ required: true, message: "Informe a cor do pelo" }]}
            >
              <S.StyledInput />
            </Form.Item>
            <Col style={{ marginBottom: "10px" }} span={24}>
              <Row gutter={16}>
                <Col span={24}>
                  <Text>Peso do Pet (KG)</Text>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={32}>
                  <Text type="secondary">
                    Ex: 20,300 = 20 Quilos e 300 gramas
                  </Text>
                </Col>
              </Row>
            </Col>

            <Form.Item initialValue={0} name="peso">
              <NumericFormat
                className="w-full px-2 border h-[38px] rounded-[8px] hover:border hover:border-[#f67e48] transition ease-out delay-100 outline-none focus:border-[#f67e48]"
                maxLength={6}
                allowLeadingZeros
                thousandSeparator=","
              />
            </Form.Item>

            <Form.Item name="chip" label="Possui chip?">
              <S.StyledSelect
                options={[
                  { label: "Sim", value: true },
                  { label: "Não", value: false },
                ]}
              />
            </Form.Item>
            {form.getFieldValue("chip") && (
              <Form.Item name="chipN" label="Numero chip">
                <S.StyledInput />
              </Form.Item>
            )}

            <Form.Item initialValue={false} name="castrado" label="Castrado?">
              <S.StyledSelect
                options={[
                  { label: "Sim", value: true },
                  { label: "Não", value: false },
                ]}
              />
            </Form.Item>

            <Form.Item name="temperamento" label="Temperamento">
              <S.StyledSelect
                mode="multiple"
                maxTagCount={undefined}
                options={petFeaturesProfile}
              />
            </Form.Item>

            <Form.Item
              name="dataNascimento"
              label="Data de Nascimento"
              rules={[
                { required: true, message: "Informe a data da consulta" },
              ]}
            >
              <DatePicker
                format={"DD/MM/YYYY"}
                placeholder="Selecione a Data da consulta"
                style={{ width: "100%" }}
              />
            </Form.Item>

            <Form.Item
              label={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ marginRight: "10px" }}>{t("qrCodes")}</span>
                  {/* <Button
                    type="primary"
                    style={{
                      backgroundColor: '#f67e48',
                    }}
                    onClick={() => {}}
                  >
                    {t('add')}
                  </Button> */}
                </div>
              }
              style={{
                marginBottom: "20px",
              }}
            >
              <div>
                {pet?.qrCodes?.length > 0 ? (
                  pet.qrCodes.map((qrCode) => (
                    <div
                      key={qrCode}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <Typography.Text style={{ marginRight: "10px" }}>
                        {qrCode}
                      </Typography.Text>
                      <FaRegTrashAlt
                        onClick={() => showDeleteModal(qrCode)}
                        style={{
                          fontSize: "18px",
                          color: "#f67e48",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  ))
                ) : (
                  <Typography.Text type="secondary">
                    {t("noQrCodes")}
                  </Typography.Text>
                )}
              </div>
            </Form.Item>

            <Form.Item name="notes" label="Observações">
              <TextArea rows={4} />
            </Form.Item>
          </Form>
        </Modal>
      )}
      <RegisterPetLayout
        hasHomeHeader
        style={{ marginTop: "0.5rem", marginBottom: "50px" }}
        Height
        size={5}
        step={5}
      >
        <Modal
          open={deleteModalVisible}
          onOk={handleDeleteQrCode}
          title={t("confirmDelete")}
          onCancel={handleDeleteCancel}
          okButtonProps={{ style: { backgroundColor: "#f67e48" } }}
        >
          {t("areYouSureDelete")} {qrCodeToDelete}?
        </Modal>
        {/* {scanned && <Geolocation code={id}></Geolocation>} */}
        <WhatsappButton></WhatsappButton>

        <ModalAuth></ModalAuth>
        <div className="w-full h-12 xxxs:mt-4">
          <S.TitleWrapper>
            <S.PetName>{pet.nome}</S.PetName>
            <Tag color={pet.gender === "macho" ? "blue" : "magenta"}>
              {pet.gender === "macho" ? "Macho" : "Fêmea"}
            </Tag>
          </S.TitleWrapper>

          {pet.tutor ? (
            <S.TitleWrapper>
              <S.TutorName>{pet.tutor.name}</S.TutorName>
            </S.TitleWrapper>
          ) : (
            ""
          )}
        </div>
        <div className="relative flex flex-col items-center">
          <S.Avatar className="relative" url={pet.avatar}>
            {!pet.avatar && (
              <div className="absolute inset-0 flex flex-col items-center justify-center z-10 bg-gray-100">
                <FaPaw className="w-16 h-16" />
                <span className="mt-2 text-gray-500">Sem foto</span>
              </div>
            )}

            {isOwner() && (
              <button
                onClick={() => {
                  setShowOptions(!showOptions);
                }}
                className="w-[136px] flex flex-row px-5 justify-between items-center bg-white h-[36px] rounded-full absolute bottom-6 left-1/2 transform -translate-x-1/2 z-20 shadow-md hover:shadow-lg transition-shadow"
              >
                <img className="w-6 h-6" src={edit} alt="" />
                <p className="font-bold text-[14px]">Editar</p>
              </button>
            )}
          </S.Avatar>

          {isOwner() && showOptions && (
            <div className="absolute top-full -mt-4 w-[90%] max-w-xs bg-white rounded-xl shadow-lg z-30 border border-gray-100">
              <div className="p-4 flex flex-col gap-2">
                <div className="flex justify-between items-center mb-2">
                  <span className="text-sm font-bold uppercase text-gray-600">
                    O que gostaria de editar?
                  </span>
                  <button
                    onClick={() => setShowOptions(false)}
                    className="text-gray-400 hover:text-gray-600 p-1"
                  >
                    <span className="text-xl">&times;</span>
                  </button>
                </div>

                <button
                  className="mb-1 bg-gray-50 hover:bg-gray-100 w-full p-3 text-left rounded-lg transition-colors"
                  onClick={() => {
                    form.setFieldsValue({
                      dataNascimento: dayjs(new Date(pet.birthDate)),
                      peso: pet.weight,
                      corPelo: pet.furColor,
                      genero: pet.gender,
                      especie: pet.species,
                      raca: pet.breed,
                      chip: pet.chip,
                      chipN: pet.chipN,
                      castrado: pet.castrated,
                      nome: pet.nome,
                      notes: pet.notes,
                    });
                    setModalEditPet(true);
                    setShowOptions(false);
                  }}
                >
                  <div className="flex items-center">
                    <div className="w-5 h-5 border border-gray-500 rounded-full mr-3"></div>
                    <p>Dados do {pet.nome}</p>
                  </div>
                </button>

                <button
                  className="mb-1 bg-gray-50 hover:bg-gray-100 w-full p-3 text-left rounded-lg transition-colors"
                  onClick={() => {
                    navigate("/");
                    setShowOptions(false);
                  }}
                >
                  <div className="flex items-center">
                    <div className="w-5 h-5 border border-gray-500 rounded-full mr-3"></div>
                    <p>Outros pets</p>
                  </div>
                </button>

                <button
                  className="mb-1 bg-gray-50 hover:bg-gray-100 w-full p-3 text-left rounded-lg transition-colors"
                  onClick={() => {
                    navigate("/user-settings");
                    setShowOptions(false);
                  }}
                >
                  <div className="flex items-center">
                    <div className="w-5 h-5 border border-gray-500 rounded-full mr-3"></div>
                    <p>Meus dados</p>
                  </div>
                </button>

                <button
                  className="bg-gray-50 hover:bg-gray-100 w-full p-3 text-left rounded-lg transition-colors"
                  onClick={() => {
                    navigate("/leitor");
                    setShowOptions(false);
                  }}
                >
                  <div className="flex items-center">
                    <div className="w-5 h-5 border border-gray-500 rounded-full mr-3"></div>
                    <p>Adicionar novo pet</p>
                  </div>
                </button>
              </div>
            </div>
          )}
        </div>
        <S.WhatsappButton
          theme={config}
          onClick={() => {
            window.open(`https://wa.me/${userPhone.replace(/\D/g, "")}`);
          }}
        >
          Whatsapp
        </S.WhatsappButton>
        <S.CallButton
          theme={config}
          onClick={() => {
            window.open(`tel:${userPhone.replace(/\D/g, "")}`);
          }}
        >
          Ligar
        </S.CallButton>
        <S.AboutWrapper style={{ paddingLeft: "1.2rem" }}>
          <S.TitleWrapper>
            <S.AboutPet>Sobre o Pet</S.AboutPet>
          </S.TitleWrapper>
          <Row gutter={[48, 12]} justify={"space-evenly"}>
            <Col span={6}>
              <S.SubAbout>Idade</S.SubAbout>
              <S.PetInfo>{calculateAge(pet.birthDate)}</S.PetInfo>
            </Col>

            <Col span={6}>
              <S.SubAbout>Raça</S.SubAbout>
              <S.PetInfo>{pet.breed}</S.PetInfo>
            </Col>

            <Col span={6}>
              <S.SubAbout>Espécie</S.SubAbout>
              <S.PetInfo>
                {pet.species === "cachorro" || pet.species === "dog"
                  ? "Cachorro"
                  : "Gato"}
              </S.PetInfo>
            </Col>
            <Col span={6}>
              <S.SubAbout>Adoção?</S.SubAbout>
              <S.PetInfo>{pet.adoption ? "Sim" : " Não"}</S.PetInfo>
            </Col>
          </Row>
        </S.AboutWrapper>

        <S.AboutWrapper style={{ paddingLeft: "1.2rem" }}>
          <S.TitleWrapper>
            <S.AboutPet>Saúde</S.AboutPet>
          </S.TitleWrapper>
          <Row
            gutter={[48, 12]}
            style={{ paddingLeft: "1.2rem" }}
            justify={"space-evenly"}
          >
            {vacinas.map((e) => {
              if (!pet[e.value]) {
                return "";
              }
              return pet[e.value].ativo ? (
                <Tag icon={<CheckCircleTwoTone twoToneColor="#52c41a" />}>
                  {e.label}
                </Tag>
              ) : (
                ""
              );
            })}
          </Row>
        </S.AboutWrapper>

        <S.AboutWrapper style={{ paddingLeft: "1.2rem", marginBottom: "1rem" }}>
          <S.TitleWrapper>
            <S.AboutPet>Características</S.AboutPet>
          </S.TitleWrapper>
          <Row
            gutter={[48, 12]}
            style={{ paddingLeft: "1.2rem" }}
            justify={"space-evenly"}
          >
            {petFeaturesProfile.map((e) => {
              if (!pet[e.value]) {
                return "";
              }
              return pet[e.value] >= 4 ? (
                <Tag icon={<CheckCircleTwoTone twoToneColor="#52c41a" />}>
                  {e.label}
                </Tag>
              ) : (
                ""
              );
            })}
          </Row>
        </S.AboutWrapper>

        {pet.notes.length > 0 && (
          <S.AboutWrapper
            style={{ paddingLeft: "1.2rem", marginBottom: "4rem" }}
          >
            <S.TitleWrapper>
              <S.AboutPet>Observações</S.AboutPet>
            </S.TitleWrapper>
            <Row
              gutter={[48, 12]}
              style={{ paddingLeft: "1.2rem" }}
              justify={"space-evenly"}
            >
              <Card>{pet.notes}</Card>
            </Row>
          </S.AboutWrapper>
        )}
      </RegisterPetLayout>
    </>
  );
};

export default ProfilePet;
