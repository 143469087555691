import { useNavigate } from "react-router-dom";
import { useAPITag } from "../../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthState } from "../login/loginSlice";

const PetsAPI = () => {
  const { http } = useAPITag();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authState = useSelector(selectAuthState);
  const getAllPets = async () => {
    try {
      const response = await http.get("/tags/pets");
      return response.data;
    } catch (error) {}
  };

  const getAllPetsByCode = async (code) => {
    try {
      const response = await http.get(`/tags/pets/by-code/${code}`);
      return response.data;
    } catch (error) {}
  };

  const getPets = async () => {
    try {
      const response = await http.get("/tags/pets/" + authState.user._id);

      return response.data;
    } catch (error) {}
  };

  const getPetsByCode = async (code) => {
    try {
      const response = await http.get(`/tags/pets/${authState.user._id}/code/${code}`);

      return response.data;
    } catch (error) {}
  };

  const getPet = async (id) => {
    try {
      const response = await http.get("/pets/id/" + id);

      return response.data;
    } catch (error) {}
  };

  const savePet = async (id, data) => {
    try {
      const response = await http.patch("pets/" + id, data);

      return response.data;
    } catch (e) {}
  };

  const createPet = async (data) => {
    try {
      const response = await http.post("/pets", data);
      navigate("/admin/pets/me");
      return response.data;
    } catch (error) {}
  };

  const getTags = async () => {
    try {
      const response = await http.get("/tags");

      return response.data;
    } catch (error) {}
  };

  const getTag = async (id) => {
    try {
      const response = await http.get("/tags/" + id);
      return response.data;
    } catch (error) {}
  };

  const getFilterTags = async (tags) => {
    try {
      const queryString = tags.map(tag => `tags[]=${encodeURIComponent(tag)}`).join('&');
      const response = await http.get(`/tags/search?${queryString}`);

      return response.data;
    } catch (error) {}
  };

  return {
    getPets,
    getAllPets,
    createPet,
    getPet,
    savePet,
    getTags,
    getTag,
    getFilterTags,
    getPetsByCode,
    getAllPetsByCode
  };
};

export default PetsAPI;
