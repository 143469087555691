import { Button, Input, InputNumber, Select } from "antd";
import styled from "styled-components";

export const Wrapper = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  width: 95%;
  background-color: white;
  padding: 0 2rem 0 2rem;
  border-radius: 25px;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;

  @media screen and (max-width: 767px) {
    margin-bottom: unset;
  }
`;

export const StyledInput = styled(Input)`
  height: 38px;
  border-radius: 8px;
`;

export const StylePassInput = styled(Input.Password)`
  height: 50px;
  width: "100%";

  border-radius: 8px;
`;

export const StyleInputNumber = styled(InputNumber)`
  min-height: 38px !important;
  width: "100%";
  border-radius: 8px;
  .ant-input-number-input-wrap {
    display: flex;
    align-items: center;
    min-height: 38px !important;
  }
`;

export const StyledSelect = styled(Select)`
  border-radius: 8px;
  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
  .ant-select-selector {
    min-height: 38px !important;
  }
`;

export const SubmitButton = styled(Button)`
  border-radius: 8px;
  height: 50px;
  width: 344px;
  margin-bottom: 15px;
  margin-top: 25px;
  background-color: #f67e48;

  @media screen and (max-width: 480px) {
    width: 100%;
    margin-bottom: 15px;
  }
`;
