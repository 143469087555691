import { Button, Col, Row } from "antd";
import * as S from "./styles";
import Swal from "sweetalert2";
import { GestorPetAPI } from "./gestorPetAPI";
import { useSelector } from "react-redux";
import { getUser } from "../../app/store/authSlice";
import { useTranslation } from 'react-i18next';

export const Historic = (props) => {
  const { t } = useTranslation();
  const { setNewHistoric } = GestorPetAPI();
  const user = useSelector(getUser);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const isOwner = () => {
    if (user == null) return false;

    return props.pet.tutor?._id === user._id;
  };

  const vacines = [
    {
      label: "Antipulgas",
      value: "antipulgas",
    },
    {
      label: "Antirrábica",
      value: "vacinaAntirrabica",
    },
    {
      label: "FivFelv",
      value: "vacinaFivFelv",
    },
    {
      label: "Leishmaniose",
      value: "vacinaLeishmaniose",
    },
    {
      label: "V3V4",
      value: "vacinaV3V4",
    },
    {
      label: "VacinaV8",
      value: "vacinaV8",
    },
    {
      label: "Vermifugado",
      value: "vermifugado",
    },
  ];

  const petVacines = [];

  vacines.forEach((e) => {
    if (props.pet[e.value] && props.pet[e.value].ativo) {
      props.pet[e.value]["createdAt"] = props.pet[e.value].data;
      props.pet[e.value]["type"] = "vacines";
      props.pet[e.value]["label"] = e.label;
      petVacines.push(props.pet[e.value]);
    }
  });

  const medicals = props.pet.medicalAppointments
    ? props.pet.medicalAppointment.map((e) => {
        e["type"] = "medical";
        e["createdAt"] = e.data;
        return e;
      })
    : [];

  const result = props.pet.historic
    ? [...props.pet.historic, ...medicals, ...petVacines].reduce((acc, obj) => {
        const date = new Date(obj.createdAt);
        const key = date.toLocaleDateString("pt-BR", options);
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }, {})
    : [];

  const createHistoric = async () => {
    const { value: answer } = await Swal.fire({
      title: t('enterNewHistory'),
      input: "textarea",
      inputLabel: t('description'),
      inputValue: 0,
      showCancelButton: true,
      inputValidator: (value) => {
        if (value.length === 0) {
          return "Descreva o histórico!";
        }
      },
    });

    if (!props.pet.historic) {
      props.pet.historic = [];
    }

    props.pet.historic.push({
      createdAt: new Date().toISOString(),
      data: answer,
    });

    await setNewHistoric(props.pet._id, props.pet.historic);
    props.refresh();
  };

  const renderResults = () => {
    const rendered = [];

    for (let i in result) {
      rendered.push(
        <Col key={`col_historic_${i}`} span={24}>
          <Row key={i}>
            <Col>
              <Row>
                <S.InfoTitle>{i}</S.InfoTitle>
              </Row>

              <Col>
                {result[i].reverse().map((e) => {
                  if (e.type === "medical") {
                    return (
                      <Row style={{ marginTop: "1.5rem" }}>
                        <S.InfoPet>
                          Consulta {e.local} com o diagnóstico {e.descricao}
                        </S.InfoPet>
                      </Row>
                    );
                  }

                  if (e.type === "vacines") {
                    return (
                      <Row style={{ marginTop: "1.5rem" }}>
                        <S.InfoPet>Vacina de {e.label} aplicada</S.InfoPet>
                      </Row>
                    );
                  }
                  return (
                    <Row style={{ marginTop: "1.5rem" }}>
                      <S.InfoPet> {e.data}</S.InfoPet>
                    </Row>
                  );
                })}
              </Col>
            </Col>
          </Row>
        </Col>
      );
    }

    return rendered;
  };

  return (
    <Col span={24}>
      {isOwner() && (
        <Row className="w-full" justify={"end"}>
          <Button onClick={createHistoric}>{t('newRecord')}</Button>
        </Row>
      )}
      <Row>{renderResults()}</Row>
    </Col>
  );
};
