import Swal from "sweetalert2";
import React, { useEffect } from "react";
import * as S from "./styles";
import { useSelector } from "react-redux";
import { selectRegisterstate } from "../RegisterPetSlice";
import RegisterPetLayout from "../../../components/layouts/RegisterPetLayout";
import { useNavigate } from "react-router-dom";
import { useAPITag } from "../../../services/api";
import { getUser } from "../../../app/store/authSlice";

const Sucess = () => {
  const registerPetState = useSelector(selectRegisterstate);
  const { http } = useAPITag();
  const navigate = useNavigate();
  const user = useSelector(getUser);

  useEffect(() => {
    const changeQrStatus = async (code) => {
      try {
        await http.patch(`/tags/change-status-admin/${code}`, {
          statusQr: "ok",
          userPhone: user.phone,
          countryCode: user.countryCode,
        });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Erro ao confirmar o pet",
          text:
            error?.response?.data?.message ||
            "Ocorreu um erro inesperado. Por favor, tente novamente.",
          confirmButtonText: "Confirmar",
        });
      }
    };

    if (registerPetState.tagCode) {
      changeQrStatus(registerPetState.tagCode);
    }
  }, []);

  return (
    <RegisterPetLayout>
      <S.WrapperRegisterCellphone>
        <S.WrapperDataUser>
          <S.WrapperSucessDescription>
            <S.SucessWrapperTitle>
              <S.SucessTitle>Coleira ativada com sucesso!</S.SucessTitle>
              <S.SucessDescription>
                Os dados do pet foram vinculados ao seu número de celular e ele
                já está identificado e seguro
              </S.SucessDescription>
            </S.SucessWrapperTitle>

            <S.WrapperSucessButton>
              <S.SubmitButton
                onClick={(e) => {
                  navigate("/profile/" + registerPetState.tagCode);
                }}
              >
                Ir para o perfil
              </S.SubmitButton>
            </S.WrapperSucessButton>
          </S.WrapperSucessDescription>
        </S.WrapperDataUser>
      </S.WrapperRegisterCellphone>
    </RegisterPetLayout>
  );
};

export default Sucess;
