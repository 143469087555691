import { Button, Dropdown, Row, Table, Input } from "antd";
import Dashboard from "../../../components/layouts/DashLayout";
import * as S from "./styles";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { EllipsisOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import UsersAPI from "../users/UsersAPI";

const MySwal = withReactContent(Swal);

export const AllUsers = () => {
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);

  const { getAllUsers, deleteUser } = UsersAPI();
  const navigate = useNavigate();

  const loadUsers = async (searchValue = "") => {
    try {
      const response = await getAllUsers(searchValue);
      setUsers(response);
    } catch (error) {
      console.error("Erro ao carregar usuários:", error);
    }
  };

  useEffect(() => {
    loadUsers();
  }, []);

  const columns = [
    {
      title: "Nome",
      render: (row) => `${row.name || row.nome}`,
    },
    {
      title: "Email",
      render: (row) => `${row.email}`,
    },
    {
      title: "Data de criação",
      render: (row) => `${new Date(row.createdAt || row.dataCadastro).toLocaleDateString("pt-BR")}`,
    },
    {
      title: "Ações",
      render: (row, index) => {
        const items = [
          {
            key: "actions_1_" + index,
            label: (
              <Button
                color="danger"
                onClick={() => navigate("/admin/users/" + row._id)}
              >
                Editar
              </Button>
            ),
          },
          {
            key: "actions_2_" + index,
            label: (
              <Button
                color="danger"
                onClick={() => {
                  Swal.fire({
                    title: "Deseja mesmo deletar esse usuário?",
                    showDenyButton: true,
                    confirmButtonText: "Deletar",
                    denyButtonText: "Não Deletar",
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      try {
                        await deleteUser(row._id);
                        users.splice(index, 1);
                        setUsers([...users]);
                        Swal.fire("Usuário deletado com sucesso!", "", "success");
                      } catch (error) {
                        Swal.fire("Erro ao deletar o usuário!", "", "error");
                      }
                    }
                  });
                }}
              >
                Deletar
              </Button>
            ),
          },
        ];

        return (
          <Dropdown
            menu={{
              items: [...items],
            }}
          >
            <Button href="#" onClick={(e) => e.preventDefault()}>
              <EllipsisOutlined style={{ fontSize: "25px" }} />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const handleSearch = async () => {
    loadUsers(search.trim());
  };

  return (
    <Dashboard selected="21">
      <S.Wrapper>
        <Row>
        <Button
            onClick={() => {
              navigate("/admin/users/create");
            }}
          >
            Criar novo usuário
          </Button>
        </Row>
        <div className="flex flex-row gap-2 w-80">
          <Input
            type="text"
            placeholder="Busca por nome, email, CPF, etc."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <Button
            type="primary"
            className="bg-orange-600 text-white"
            onClick={handleSearch}
          >
            Buscar
          </Button>
        </div>
        <Table
          style={{ width: "80vw" }}
          columns={columns}
          dataSource={users}
          rowKey="_id"
        />
      </S.Wrapper>
    </Dashboard>
  );
};
