import { useNavigate, useParams } from "react-router-dom";
import { GestorPetAPI } from "./gestorPetAPI";
import { useEffect, useState } from "react";
import {
  Avatar,
  Col,
  DatePicker,
  Grid,
  Button,
  Form,
  Layout,
  Modal,
  Row,
  Space,

  Tabs,
  Tag,
  Typography,
  InputNumber,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { UploadInput } from "../../components/uploadInput";
import TextArea from "antd/es/input/TextArea";
import { NumericFormat } from "react-number-format";
import * as S from "./styles";
import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons";

import {
  IconBehavior,
  IconCalendar,
  IconCastred,
  IconFur,
  IconGender,
  IconHealth,
  IconMicroship,
  IconPaw,
  IconPerson,
  IconSpecies,
  IconWeigth,
} from "../../components/Icons";
import { getPetAge } from "../../data/utils/getPetAge";
import { Historic } from "./historic";
import { Consults } from "./consults";
import { Vacines } from "./vacines";
import { Medicines } from "./medicines";
import dayjs from "dayjs";
import {
  petFeaturesProfile,
  racasCachorro,
  racasGato,
  tiposSaude,
} from "../register-pet/register-pet/RegisterPetMock";
import { useDispatch, useSelector } from "react-redux";
import { getUser, setCurrentPet } from "../../app/store/authSlice";
import Header from "../../components/Header";
import ExpandableComponent from "../../components/ExpandableComponent";
import { ModalAuth } from "../../components/modalAuth";
import { Documents } from "./documents";
import { CheckCircleTwoTone } from "@ant-design/icons";
import Loader from "../../components/Loader";
import { useTranslation } from 'react-i18next';

const { useBreakpoint } = Grid;

export const GestorPet = (props) => {
  const { t } = useTranslation();
  const [ isLoading, setIsLoading ] = useState(false);
  const { Text } = Typography;
  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const { getPetData, updatePet, deactivateTag } = GestorPetAPI();
  const { id } = useParams();
  const [pet, setPet] = useState();
  const navigate = useNavigate();
  const [photo, setPhoto] = useState([]);
  const [count, setCount] = useState(0);
  const [isScanning, setIsScanning] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [qrCodeToDelete, setQrCodeToDelete] = useState('');
  const user = useSelector(getUser);
  const [modalEditPet, setModalEditPet] = useState(false);
  const [form] = useForm();
  
  
  const editPet = async () => {
    setModalEditPet(true);
  };

  const isOwner = () => {
    if (user == null) return false;

    return pet.tutor?._id === user._id;
  };

  const onFinish = async (e) => {
    if (photo.length > 0) {
      e.avatar = photo[0].url;
      e.fotos = photo.map((photo) => photo.url);
    }
    setIsLoading(true);
    await updatePet(pet._id, e);

    setModalEditPet(false);
    form.setFieldsValue({});
    loadPetData();
    setIsLoading(false);
  };

  const loadPetData = async () => {
    setIsLoading(true);
    const response = await getPetData(id);
    setPet(await getPetData(id));
    setIsLoading(false);
  };
  useEffect(() => {
    loadPetData();
  }, []);

  const onCancelHandler = () => {
    if (props && props.cancel) {
      props.cancel();
      setModalEditPet(false);
    }
  };

  const deleteQrCode = async () => {
    const uptadePetQrCode = deactivateTag(pet._id, qrCodeToDelete);
    const updatedPet = await uptadePetQrCode;

    setPet(updatedPet);
    setDeleteModalVisible(false);
    setQrCodeToDelete('');
    await loadPetData();
  };
  

  const showDeleteModal = (e) => {
    console.log('qrcode', e);
    setQrCodeToDelete(e);
    setDeleteModalVisible(true);
  };

  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
    setQrCodeToDelete('');
  };

  return (
    <>
      <ModalAuth></ModalAuth>
        <>
          {isLoading && <Loader></Loader>}
          <Row>
            <Header></Header>
          </Row>
          <S.StyledLayout>
            <Modal
              onCancel={() => setModalEditPet(false)}
              open={modalEditPet}
              okButtonProps={{ style: { backgroundColor: '#f67e48' } }}
              onOk={() => form.submit()}
              okText="Salvar"
            >
              <Form
                form={form}
                name="basic"
                labelCol={{ span: 24 }}
                labelAlign="top"
                labelWrap
                wrapperCol={{ flex: 1 }}
                colon={false}
                style={{ width: "100%" }}
                initialValues={{ remember: true }}
                onValuesChange={() => {
                  setCount(count + 1);
                }}
                onFinish={onFinish}
                autoComplete="off"
              >
                <UploadInput
                  onChange={setPhoto}
                  label={t('updatePetPhoto')}
                  paths={photo}
                ></UploadInput>

                <Form.Item
                  name="nome"
                  label={t('name')}
                  rules={[{ required: true, message: t('enterFurColor1') }]}
                >
                  <S.StyledInput />
                </Form.Item>
                <Form.Item
                  name="especie"
                  label={t('species')}
                  initialValue="dog"
                  style={{ marginTop: "2rem" }}
                  rules={[
                    { required: true, message: t('enterPetSpecies') },
                  ]}
                >
                  <S.StyledSelect
                    initialValue="dog"
                    options={[
                      { value: "dog", label: t('dog') },
                      { value: "cat", label: t('cat') },
                    ]}
                  />
                </Form.Item>

                {form.getFieldValue("especie") === "dog" ? (
                  <Form.Item
                    name="raca"
                    label={t('breed')}
                    rules={[
                      { required: true, message: t('enterPetBreed') },
                    ]}
                  >
                    <S.StyledSelect options={racasCachorro} />
                  </Form.Item>
                ) : (
                  <Form.Item
                    name="raca"
                    label={t('breed')}
                    rules={[
                      { required: true, message: t('enterPetBreed') },
                    ]}
                  >
                    <S.StyledSelect options={racasGato} />
                  </Form.Item>
                )}

                <Form.Item
                  name="genero"
                  label={t('gender')}
                  rules={[
                    { required: true, message: t('enterPetGender1') },
                  ]}
                >
                  <S.StyledSelect
                    initialValue="female"
                    options={[
                      { value: "fêmea", label: t('female') },
                      { value: "macho", label: t('male') },
                    ]}
                  />
                </Form.Item>

                <Form.Item initialValue="tudoCerto" name="saude" label={t('healthStatus')}>
                  <S.StyledSelect defaultValue="tudoCerto" options={tiposSaude} />
                </Form.Item>

                <Form.Item
                  name="corPelo"
                  label={t('furColor')}
                  rules={[{ required: true, message: t('enterFurColor1') }]}
                >
                  <S.StyledInput />
                </Form.Item>
                <Col style={{ marginBottom: '10px' }} span={24}>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Text>{t('petWeight')}</Text>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                      <Col span={32}>
                        <Text type="secondary">{t('exampleWeight')}</Text>
                      </Col>
                  </Row>
                </Col>
                
                <Form.Item initialValue={0} name="peso">
                  <NumericFormat className="w-full px-2 border h-[38px] rounded-[8px] hover:border hover:border-[#f67e48] transition ease-out delay-100 outline-none focus:border-[#f67e48]" maxLength={6} allowLeadingZeros thousandSeparator=","/>
                </Form.Item>

                <Form.Item name="chip" label={t('hasChip')}>
                  <S.StyledSelect
                    options={[
                      { label: t('yes'), value: true },
                      { label: t('no'), value: false },
                    ]}
                  />
                </Form.Item>
                {form.getFieldValue("chip") && (
                  <Form.Item name="chipN" label={t('chipNumber')}>
                    <S.StyledInput />
                  </Form.Item>
                )}

                <Form.Item
                  initialValue={false}
                  name="castrado"
                  label={t('neutered1')}
                >
                  <S.StyledSelect
                    options={[
                      { label: t('yes'), value: true },
                      { label: t('no'), value: false },
                    ]}
                  />
                </Form.Item>

                <Form.Item name="temperamento" label={t('temperament')}>
                  <S.StyledSelect
                    mode="multiple"
                    maxTagCount={undefined}
                    options={petFeaturesProfile}
                  />
                </Form.Item>

                <Form.Item
                  name="dataNascimento"
                  label={t('birthDate')}
                  rules={[
                    { required: true, message: t('enterConsultationDate') },
                  ]}
                >
                  <DatePicker
                    format={"DD/MM/YYYY"}
                    placeholder={t('selectConsultationDate')}
                    style={{ width: "100%" }}
                  />
                </Form.Item>

                <Form.Item
                  name="notes"
                  label={t('observations')}
                >
                  <TextArea rows={4} />
                </Form.Item>
                {/* <div className="flex justify-end mt-4 gap-3">
                  <button onClick={() => setModalEditPet(false)} className="w-20 p-2 rounded-md border border-zinc-300 ml-4">
                    Cancelar
                  </button>
                  <button className="w-20 rounded-md p-2 bg-orange-500 text-white"  onClick={() => form.submit()}>
                    Salvar
                  </button>
                </div> */}
              </Form>
            </Modal>
            {pet && (
              <S.GestorPetWrapper span={24}>
                <Row className="p-4" justify={"space-between"}>
                  <S.BackButton
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <Row justify={"center"}>
                      <ChevronLeftIcon></ChevronLeftIcon>
                      <S.BackButtonTitle>{t('back')}</S.BackButtonTitle>
                    </Row>
                  </S.BackButton>
                  <Row className="xxxs:hidden lg:flex" style={{ marginTop: ".5rem" }}>
                    <S.BreedCrumbBegin>{t('home')}</S.BreedCrumbBegin>
                    <ChevronRightIcon></ChevronRightIcon>
                    <S.BreedCrumbFinal>{t('petProfile')}</S.BreedCrumbFinal>
                  </Row>
                </Row>

                <Row className="w-full justify-between gap-4 mt-6">
                  <div className="lg:w-96 w-full mt-10  flex justify-center items-center">
                    <div className="flex flex-col items-center lg:flex-row">
                      <S.PetAvatar size={100} src={pet.avatar}></S.PetAvatar>
                      <S.PetTitleWrapper>
                        <S.PetTitle>{pet.nome}</S.PetTitle>

                        {isOwner() && (
                          <div
                            onClick={() => {
                              form.setFieldsValue({
                                dataNascimento: dayjs(new Date(pet.birthDate)),
                                peso: pet.weight,
                                corPelo: pet.furColor,
                                genero: pet.gender,
                                especie: pet.species,
                                raca: pet.breed,
                                chip: pet.chip,
                                chipN: pet.chipN,
                                castrado: pet.castrated,
                                nome: pet.nome,
                                notes: pet.notes
                              });
                              setModalEditPet(true);
                            }}
                          >
                            <S.PetEditTitle>{t('perfil')}</S.PetEditTitle>
                          </div>
                        )}
                      </S.PetTitleWrapper>
                    </div>
                  </div>
                  <Col className="xxxs:hidden lg:flex">
                    <Row gutter={[16, 24]}>
                      <InfoPet
                        icon={IconPerson}
                        title={t('tutorVet')}
                        info={pet.tutor?.nome}
                      />
                      <InfoPet
                        icon={IconCalendar}
                        title={t('age')}
                        info={getPetAge(new Date(), new Date(pet.birthDate))}
                      />
                      <InfoPet
                        icon={IconWeigth}
                        title={t('weight')}
                        info={`${parseFloat(pet.weight).toFixed(3).replace(".", ",")} Kgs`}
                      />

                      <InfoPet
                        icon={IconFur}
                        title={t('fur')}
                        info={pet.furColor}
                      />

                      <InfoPet
                        icon={IconSpecies}
                        title={t('species')}
                        info={pet.species === "dog" ? t('dog') : t('cat')}
                      />
                      <InfoPet
                        icon={IconGender}
                        title={t('gender')}
                        info={pet.gender === "macho" ? t('male') : t('female')}
                      />

                      <InfoPet icon={IconPaw} title={t('breed')} info={pet.breed} />

                      <InfoPet
                        icon={IconCastred}
                        title={t('neutered2')}
                        info={pet.castrated ? t('yes') : t('no')}
                      />

                      <InfoPet
                        icon={IconMicroship}
                        title={t('microchip')}
                        info={pet.chip ? pet.chipN : t('doesNotHave')}
                      />

                      <InfoPet
                        icon={IconBehavior}
                        title={t('temperament')}
                        pet={pet}
                        petFeaturesProfile={petFeaturesProfile}
                      />
                    </Row>
                  </Col>

                  <Col className="xxxs:flex lg:hidden mt-4">
                    <ExpandableComponent>
                      <Row gutter={[24, 24]}>
                        <InfoPet
                          icon={IconPerson}
                          title={t('tutorVet')}
                          info={pet.tutor?.name}
                        />
                        <InfoPet
                          icon={IconCalendar}
                          title={t('age')}
                          info={getPetAge(new Date(), new Date(pet.birthDate))}
                        />
                        <InfoPet
                          icon={IconWeigth}
                          title={t('weight')}
                          info={`${pet.weight}Kgs`}
                        />
                        <InfoPet
                          icon={IconFur}
                          title={t('fur')}
                          info={pet.furColor}
                        />

                        <InfoPet
                          icon={IconSpecies}
                          title={t('species')}
                          info={pet.species === "dog" ? t('dog') : t('cat')}
                        />
                        <InfoPet
                          icon={IconGender}
                          title={t('gender')}
                          info={pet.gender === "macho" ? t('male') : t('female')}
                        />
                        <InfoPet
                          icon={IconBehavior}
                          title={t('temperament')}
                          pet={pet}
                          petFeaturesProfile={petFeaturesProfile}
                        />

                        <InfoPet icon={IconPaw} title={t('breed')} info={pet.breed} />

                        <InfoPet
                          icon={IconCastred}
                          title={t('neutered2')}
                          info={pet.castrated ? t('yes') : t('no')}
                        />
                        <InfoPet
                          icon={IconMicroship}
                          title={t('microchip')}
                          info={pet.chip ? pet.chipN : t('doesNotHave')}
                        />
                      </Row>
                    </ExpandableComponent>
                  </Col>
                </Row>

                {deleteModalVisible && (
                  <Modal
                  open={deleteModalVisible}
                  onOk={deleteQrCode}
                  title={t('confirmDelete')}
                  onCancel={handleDeleteCancel}
                  okButtonProps={{ style: { backgroundColor: '#f67e48' } }}
                >
                  {t('areYouSureDelete')} {pet.nome}?
                </Modal>
                )}

                <Row
                  style={{
                    marginTop: "3rem",
                    width: "100%",
                    padding:"5px"
                  }}
                >
                  <Tabs
                    style={{ marginTop: "3rem", width: "100%", padding:"10px" }}
                    defaultActiveKey="1"
                    items={[
                      {
                        label: `QRCodes`,
                        key: "0",
                        children: (
                          <Col className="w-full" span={24}>
                            {pet.qrCodes?.map((e) => {
                              return (
                                <Row>
                                  <span className="text-lg text-neutral-600 mb-4">
                                    {e}
                                    <button onClick={() => showDeleteModal(e)} className="ml-4 text-lg text-red-500">
                                      X
                                    </button>
                                  </span>
                                </Row>
                              );
                            })}

                            <Row>
                              {/* <Button
                                onClick={() => {
                                  dispatch(setCurrentPet(pet));
                                  navigate("/reader-new");
                                }}
                              >
                                Adicionar QRCode
                              </Button> */}
                            </Row>
                          </Col>
                        ),
                      },
                      {
                        label: t('history'),
                        key: "1",
                        children: (
                          <Historic pet={pet} refresh={loadPetData}></Historic>
                        ),
                      },
                      {
                        label: t('consultations'),
                        key: "2",
                        children: (
                          <Consults refresh={loadPetData} pet={pet}></Consults>
                        ),
                      },
                      {
                        label: t('vaccines'),
                        key: "3",
                        children: (
                          <Vacines refresh={loadPetData} pet={pet}></Vacines>
                        ),
                      },
                      {
                        label: t('medications'),
                        key: "4",
                        children: (
                          <Medicines
                            refresh={loadPetData}
                            pet={pet}
                          ></Medicines>
                        ),
                      },
                      {
                        label: t('documents'),
                        key: "5",
                        children: (
                          <Documents
                            refresh={loadPetData}
                            pet={pet}
                          ></Documents>
                        ),
                      },
                    ]}
                  />
                </Row>
                <div className="w-full h-8 bg-[#F5F5F5] mt-4 md:hidden"></div>
              </S.GestorPetWrapper>
            )}
          </S.StyledLayout>
        </>
    </>
  );
};

const InfoPet = (props) => {
  const { pet, petFeaturesProfile, icon, title, info } = props;

  if (pet && petFeaturesProfile) {
    return (
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <Row align="middle">
          <Col span={2}>{icon}</Col>
          <Col span={12}>
            <S.InfoTitle>{title}</S.InfoTitle>
          </Col>

          <Col span={24}>
            <Row gutter={[8, 8]} justify="start">
              {petFeaturesProfile.map((e) => {
                if (!pet[e.value]) {
                  return null;
                }

                return pet[e.value] >= 4 ? (
                  <Col>
                    <Tag
                      icon={<CheckCircleTwoTone twoToneColor="#52c41a" />}
                      title={e.label}
                      className="mt-2"
                    >
                      {e.label}
                    </Tag>
                  </Col>
                ) : null;
              })}
            </Row>
          </Col>
        </Row>
      </Col>
    );
  }

  return (
    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
      <Row>
        <Col span={4}>{icon}</Col>
        <Col span={20}>
          <Col>
            <S.InfoTitle>{title}</S.InfoTitle>
          </Col>
          <S.InfoPet>{info}</S.InfoPet>
        </Col>
      </Row>
    </Col>
  );
};
