import { Button, Col, Form, Modal, Row } from "antd";
import * as S from "./styles";
import Swal from "sweetalert2";
import { GestorPetAPI } from "./gestorPetAPI";
import { useForm } from "antd/es/form/Form";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getUser } from "../../app/store/authSlice";
import { UploadInput } from "../../components/uploadInput";
import { useTranslation } from 'react-i18next';

const documents = [
  {
    label: "Carteira de Vacinação",
    value: "carteiraVacinacao",
  },
  {
    label: "Certificado de Pedigree",
    value: "certificadoPedigree",
  },
  {
    label: "RG",
    value: "rg",
  },
  {
    label: "Número do Chip",
    value: "numeroChip",
  },
];

export const Documents = (props) => {
  const { t } = useTranslation();
  const { updatePet } = GestorPetAPI();
  const user = useSelector(getUser);
  const [newConsultModal, setNewCosultModal] = useState(false);
  const [document, setDocument] = useState("");

  const [photo, setPhoto] = useState([]);

  const isOwner = () => {
    if (user == null) return false;
    return props.pet.tutor?._id === user._id;
  };

  const [form] = useForm();

  const createHistoric = async () => {
    setNewCosultModal(true);
  };

  const onFinish = async (e) => {
    if (!props.pet.documents) {
      props.pet.documents = [];
    }
    e.photo = photo[0] ?? null;
    props.pet.documents.push(e);

    await updatePet(props.pet._id, {
      documents: props.pet.documents,
    });

    setNewCosultModal(false);
    props.refresh();
    form.setFieldsValue({});
  };

  const getDocumentLabel = (value) => {
    const documentObject = documents.find((doc) => doc.value === value);
    return documentObject ? documentObject.label : value;
  };

  return (
    <Col style={{ overflowX: "scroll" }} span={24}>
      <Modal
        onCancel={() => {
          setNewCosultModal(false);
          form.setFieldsValue({});
        }}
        onOk={form.submit}
        open={newConsultModal}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 24 }}
          labelAlign="top"
          labelWrap
          wrapperCol={{ flex: 1 }}
          colon={false}
          style={{ width: "80%" }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item name="document" label={t('documentName')}>
            <S.StyledSelect
              options={[
                ...documents,
                {
                  label: t('other'),
                  value: "other",
                },
              ]}
              onChange={(e) => setDocument(e)}
            />
          </Form.Item>

          <UploadInput
            label={t('uploadDocumentImage')}
            onChange={setPhoto}
            paths={photo}
          ></UploadInput>
        </Form>
      </Modal>
      {isOwner() && (
        <Row justify={"end"}>
          <Button onClick={createHistoric}>{t('newDocument')}</Button>
        </Row>
      )}

      <Col  className="w-full">
        {props.pet.documents &&
          props.pet.documents.map((e) => {
            return (
              <Row style={{ marginTop: "3rem" }}>
                <InfoPet title="Nome" info={getDocumentLabel(e.document)} />
                <Button
                  onClick={() => {
                    if (e.photo) {
                      Swal.fire({
                        imageUrl: e.photo.url,
                        imageAlt: "imagemUrl",
                      });
                    }
                  }}
                >
                  {" "}
                  Ver imagem
                </Button>
              </Row>
            );
          })}
      </Col>
    </Col>
  );
};

const InfoPet = (props) => {
  return (
    <Col span={4}>
      <Row>
        <Col span={24}>
          <Col>
            <S.InfoTitle>{props.title}</S.InfoTitle>
          </Col>
          <S.InfoPet>{props.info}</S.InfoPet>
        </Col>
      </Row>
    </Col>
  );
};
