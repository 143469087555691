// import Input from '../../Input'
import React, { useEffect, useState } from "react";
import * as S from "../styles";
import { useForm } from "react-hook-form";
import Button from "../../Button";
import { ConfigUserAPI } from "../ConfigUserAPI";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { getUser, logout } from "../../../app/store/authSlice";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { formatarCPF, validarCPF } from "../../../data/utils/formatarCPF";
import { useTranslation } from 'react-i18next';

function Security() {
  const { t } = useTranslation();
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { updatePassword, updateUser } = ConfigUserAPI();
  const user = useSelector(getUser);
  const dispatch = useDispatch();

  const {
    register,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      cpf: "",
    },
  });

  const handleChange = (event) => {
    const cpfFormatado = formatarCPF(event.target.value);
    setValue("cpf", cpfFormatado, { shouldValidate: true });
  };

  useEffect(() => {
    setValue("cpf", user.document ? formatarCPF(user.document) : "");
  }, [user.document, setValue]);

  const setDocument = () => {
    const { cpf } = getValues();
    if (!cpf) {
      Swal.fire({ title: "Preencha o CPF", icon: "error" });
      return;
    }

    if (!validarCPF(cpf)) {
      Swal.fire({ title: "CPF inválido", icon: "error" });
      return;
    }

    const cpfLimpo = formatarCPF(cpf, false);
    updateUser({ documento: cpfLimpo });
  };

  const disableAccount = async () => {
    const { isConfirmed } = await Swal.fire({
      showDenyButton: true,
      showConfirmButton: true,
      confirmButtonText: "Sim",
      denyButtonText: "Não",
      title: "Deseja realmente desativar sua conta?",
    });

    if (isConfirmed) {
      updateUser({ deletedAt: new Date() });
      dispatch(logout());
    }
  };

  const setPass = () => {
    const { currentPassword, password, newPassword } = getValues();
    updatePassword(currentPassword, password, newPassword)();
  };

  return (
    <>
      <S.WrapperSection>
        <S.Subtitle>{t('changePassword')}</S.Subtitle>
        <S.ContainerInput>
          <S.Label>{t('currentPassword')}</S.Label>
          <S.InputContainer>
            <S.Input
              type={showCurrentPassword ? "text" : "password"}
              name="current-password"
              placeholder={t('oldPassword')}
              control
              {...register("currentPassword")}
            />
            <S.IconButton
              onClick={() => setShowCurrentPassword((prev) => !prev)}
            >
              {showCurrentPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
            </S.IconButton>
          </S.InputContainer>
        </S.ContainerInput>
        <S.WrapperInputs>
          <S.ContainerInput>
            <S.Label>{t('newPassword')}</S.Label>
            <S.InputContainer>
              <S.Input
                type={showNewPassword ? "text" : "password"}
                name="password"
                placeholder={t('newPassword1')}
                control
                {...register("password")}
              />

              <S.IconButton onClick={() => setShowNewPassword((prev) => !prev)}>
                {showNewPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
              </S.IconButton>
            </S.InputContainer>
          </S.ContainerInput>

          <S.ContainerInput>
            <S.Label>{t('confirmNewPassword')}</S.Label>
            <S.InputContainer>
              <S.Input
                type={showConfirmPassword ? "text" : "password"}
                name="new-password"
                placeholder={t('confirmPassword')}
                control
                {...register("newPassword")}
              />

              <S.IconButton
                onClick={() => setShowConfirmPassword((prev) => !prev)}
              >
                {showConfirmPassword ? (
                  <EyeOutlined />
                ) : (
                  <EyeInvisibleOutlined />
                )}
              </S.IconButton>
            </S.InputContainer>
          </S.ContainerInput>
        </S.WrapperInputs>
        <Button onClick={setPass}>{t('changePassword')}</Button>
      </S.WrapperSection>
      <S.WrapperSection>
        <S.Subtitle>{t('changeCpf')}</S.Subtitle>
        <S.ContainerInput>
          <S.Label>{t('changeCpf')}</S.Label>
          <S.InputContainer>
            <S.Input
              type="text"
              placeholder={t('editCpf')}
              {...register("cpf", {
                onChange: handleChange,
                validate: validarCPF,
              })}
              isInvalid={errors.cpf}
            />
          </S.InputContainer>
        </S.ContainerInput>
        <Button onClick={setDocument}>{t('changeCpf')}</Button>
      </S.WrapperSection>

      {/*   <S.WrapperSection>
        <S.Subtitle>Deletar conta</S.Subtitle>

        <S.WrapperOptionDelete onClick={disableAccount}>
          <S.SpanBold>Excluir conta definitivamente</S.SpanBold>
          <S.DescribeDeleteOption>
            Esta opção ira deletar sua conta e todos os dados
          </S.DescribeDeleteOption>
        </S.WrapperOptionDelete>
      </S.WrapperSection> */}
    </>
  );
}

export default Security;
