import { Button, Col, DatePicker, Form, Modal, Row } from "antd";
import * as S from "./styles";
import Swal from "sweetalert2";
import { GestorPetAPI } from "./gestorPetAPI";
import { useState } from "react";
import { useForm } from "antd/es/form/Form";
import { useSelector } from "react-redux";
import { getUser } from "../../app/store/authSlice";
import { useTranslation } from 'react-i18next';

export const Consults = (props) => {
  const { t } = useTranslation();
  const { updatePet } = GestorPetAPI();
  const [newConsultModal, setNewCosultModal] = useState(false);
  const user = useSelector(getUser);

  const [form] = useForm();
  const createHistoric = async () => {
    setNewCosultModal(true);
  };
  const isOwner = () => {
    if (user == null) return false;

    return props.pet.tutor?._id === user._id;
  };
  const onFinish = async (e) => {
    if (!props.pet.medicalAppointment) {
      props.pet.medicalAppointment = [];
    }

    props.pet.medicalAppointment.push(e);

    await updatePet(props.pet._id, {
      consultas: props.pet.medicalAppointment,
    });

    setNewCosultModal(false);
    form.setFieldsValue({});
  };

  return (
    <Col span={24}>
      <Modal
        onCancel={() => {
          setNewCosultModal(false);
          form.setFieldsValue({});
        }}
        onOk={form.submit}
        open={newConsultModal}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 24 }}
          labelAlign="top"
          labelWrap
          wrapperCol={{ flex: 1 }}
          colon={false}
          style={{ width: "80%" }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            name="local"
            label={t('clinicLocation')}
            rules={[{ required: true, message: t('enterClinicLocation') }]}
          >
            <S.StyledInput />
          </Form.Item>
          <Form.Item
            name="doctor"
            label={t('veterinarian')}
            rules={[{ required: true, message: t('enterVeterinarian') }]}
          >
            <S.StyledInput />
          </Form.Item>

          <Form.Item
            name="descricao"
            label={t('diagnosis')}
            rules={[{ required: true, message: t('enterDiagnosis') }]}
          >
            <S.StyledInput />
          </Form.Item>

          <Form.Item initialValue={false} name="return" label={t('followUp')}>
            <S.StyledSelect
              options={[
                { label: t('yes'), value: true },
                { label: t('no'), value: false },
              ]}
            />
          </Form.Item>

          <Form.Item
            initialValue={false}
            name="prescription"
            label={t('prescription')}
          >
            <S.StyledSelect
              options={[
                { label: t('yes'), value: true },
                { label: t('no'), value: false },
              ]}
            />
          </Form.Item>

          <Form.Item
            name="data"
            label={t('consultationDate')}
            rules={[{ required: true, message: t('enterConsultationDate') }]}
          >
            <DatePicker
              format={"DD/MM/YYYY"}
              placeholder={t('selectConsultationDate')}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Form>
      </Modal>
      {isOwner() && (
        <Row justify={"end"}>
          <Button onClick={createHistoric}>{t('newConsultation')}</Button>
        </Row>
      )}
      <Col className="w-full">
        {props.pet.medicalAppointment &&
          props.pet.medicalAppointment.map((e) => {
            console.log(e);
            return e.local ? (
              <Row style={{ marginTop: "3rem" }}>
                <Col span={4}>
                  <S.InfoPet>{e.local}</S.InfoPet>
                </Col>
                <InfoPet
                  title={t('veterinarian')}
                  info={e.doctor ? e.doctor : e.local}
                />
                <InfoPet title={t('diagnosis')} info={e.descricao} />
                <InfoPet title={t('followUp1')} info={e.return ? t('yes') : t('no')} />
                <InfoPet
                  title={t('prescription1')}
                  info={e.prescription ? t('yes') : t('no')}
                />
                <InfoPet
                  title={t('date')}
                  info={new Date(e.data).toLocaleDateString("pt-BR")}
                />
              </Row>
            ) : (
              ""
            );
          })}
      </Col>
    </Col>
  );
};

const InfoPet = (props) => {
  return (
    <Col span={4}>
      <Row>
        <Col span={24}>
          <Col>
            <S.InfoTitle>{props.title}</S.InfoTitle>
          </Col>
          <S.InfoPet>{props.info}</S.InfoPet>
        </Col>
      </Row>
    </Col>
  );
};
