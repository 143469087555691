import AuthPhoneAPI from "./AuthPhoneAPI";
import * as S from "./styles";
import { Form, Input, Space, Spin } from "antd";
import Swal from "sweetalert2";
import { useAPITag } from "../../../services/api";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import RegisterPetLayout from "../../../components/layouts/RegisterPetLayout";
import { useSelector } from "react-redux";
import { getWhatsappStatus, selectRegisterstate } from "../RegisterPetSlice";
import { WhatsAppOutlined } from "@ant-design/icons";
import Modal from "./Modal";
import { PhoneNumberField } from "./PhoneNumberField";
import { formatUserPhoneForDisplay } from "../../../utils/formatUserPhoneForDisplay";

export const AuthPhone = () => {
  const { id } = useParams();
  const { http } = useAPITag();
  const { ValidateTag, AuthPhone } = AuthPhoneAPI();

  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [inputDisabled, setInputDisabled] = useState(true);
  const [whatsAppConfirmModalVisible, setwhatsAppConfirmModalVisible] =
    useState(false);
  const [smsConfirmModalVisible, setsmsConfirmModalVisible] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");

  const registerState = useSelector(selectRegisterstate);
  const wppStatus = useSelector(getWhatsappStatus);

  useEffect(() => {
    ValidateTag(id);
  }, []);

  useEffect(() => {
    setInputDisabled(!phoneNumber);
  }, [phoneNumber]);

  const onFinishFailed = (errorInfo) => {
    console.log("Erro na validação:", errorInfo);
    Swal.fire({
      title: "Preencha todos os campos corretamente",
      text: JSON.stringify(errorInfo),
      icon: "error",
    });
  };

  const savePreRegister = async (data) => {
    try {
      await http.patch("/tags/change-status/" + id, data);
    } catch (error) {
      console.log(error);
    }
  };

  const onNavigateToConfirmCode = async (grantType) => {
    if (!phoneNumber) {
      Swal.fire({
        title: "Número de telefone inválido!",
        icon: "error",
        confirmButtonText: "Corrigir",
      });

      setwhatsAppConfirmModalVisible(false);
      setsmsConfirmModalVisible(false);

      return;
    }

    const countryCode = `+${phoneNumber.slice(1, 3)}`;
    const userPhone = phoneNumber.slice(3);

    try {
      await savePreRegister({
        statusQr: "pending",
        countryCode,
        userPhone,
        grantType,
      });

      navigate(`/confirm-code/${id}`);
    } catch (error) {
      console.error("Erro ao navegar para confirmar código:", error);

      Swal.fire({
        title: "Erro ao enviar o código!",
        text: error?.response?.data?.message || "Ocorreu um erro inesperado.",
        icon: "error",
        confirmButtonText: "Tentar novamente",
      });

      setwhatsAppConfirmModalVisible(false);
      setsmsConfirmModalVisible(false);
    }
  };

  const handleConfirmWhatsappClick = () => {
    form.setFieldsValue({ grantType: "whatsapp" });
    form
      .validateFields()
      .then(() => {
        form.submit();
        onNavigateToConfirmCode("whatsapp");
      })
      .catch(() => {
        setwhatsAppConfirmModalVisible(false);
      });
  };

  const handleConfirmSmsClick = () => {
    form.setFieldsValue({ grantType: "sms" });
    form
      .validateFields()
      .then(() => {
        form.submit();
        onNavigateToConfirmCode("sms");
      })
      .catch(() => {
        setsmsConfirmModalVisible(false);
      });
  };

  return !registerState.config.logo ? (
    <div className="flex items-center justify-center w-full h-screen">
      <Spin size="large" />
    </div>
  ) : (
    <RegisterPetLayout
      hasHeader
      description="Insira o número do seu celular e receba o código de ativação da coleira."
      title="Cadastrar nova coleira"
      size={5}
      step={1}
    >
      <Form
        form={form}
        name="basic"
        layout="horizontal"
        colon={false}
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: 25,
          marginTop: "1rem",
        }}
        initialValues={{ userPhone: phoneNumber }}
        onFinish={AuthPhone}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Space
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
        >
          <Form.Item
            name="userPhone"
            rules={[
              { required: true, message: "Informe o número do telefone" },
            ]}
          >
            <PhoneNumberField
              value={phoneNumber || ""}
              onChange={(value) => {
                setPhoneNumber(value || "");
                form.setFieldsValue({ userPhone: value || "" });
              }}
            />
          </Form.Item>

          <Form.Item initialValue={"sms"} name="grantType">
            <Input type="hidden" />
          </Form.Item>
        </Space>
      </Form>

      <div className="flex flex-col w-full gap-4 mt-4 justify-center items-center">
        {/* {wppStatus && (
          <S.SubmitWhatsappButton
            onClick={() => setwhatsAppConfirmModalVisible(true)}
            disabled={inputDisabled}
            type="primary"
            htmlType="submit"
          >
            <WhatsAppOutlined />
            Receber por Whatsapp
          </S.SubmitWhatsappButton>
        )} */}
        <S.SubmitButton
          className="whatsapp"
          onClick={() => setsmsConfirmModalVisible(true)}
          disabled={inputDisabled}
          type="primary"
          htmlType="submit"
        >
          Receber por SMS
        </S.SubmitButton>

        <Modal
          title="Confirmação de Telefone"
          isOpen={whatsAppConfirmModalVisible}
          onClose={() => setwhatsAppConfirmModalVisible(false)}
          onConfirm={handleConfirmWhatsappClick}
        >
          <span style={{ fontSize: "16px" }}>
            Confira se este é o seu telefone! <br />
            <span style={{ fontSize: "20px", fontWeight: "bold" }}>
              {formatUserPhoneForDisplay(phoneNumber)}
            </span>
          </span>
        </Modal>
        <Modal
          title="Confirmação de Telefone"
          isOpen={smsConfirmModalVisible}
          onClose={() => setsmsConfirmModalVisible(false)}
          onConfirm={handleConfirmSmsClick}
        >
          <span style={{ fontSize: "16px" }}>
            Confira se este é o seu telefone! <br />
            <span style={{ fontSize: "20px", fontWeight: "bold" }}>
              {formatUserPhoneForDisplay(phoneNumber)}
            </span>
          </span>
        </Modal>
      </div>
    </RegisterPetLayout>
  );
};
