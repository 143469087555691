import Header from "../../components/Header";
import { HeaderHome } from "../../components/Header";
import Box from "../../components/Box";
import { Outlet } from "react-router-dom";
import * as S from "./styles";
import { ModalAuth } from "../../components/modalAuth";
import { WhatsappButton } from "../../components/WhatsappButton";
import { useSelector } from "react-redux";
import { isLoggedIn } from "../../app/store/authSlice";
import ConfigUser from "../ConfigUser";
import { HomePage } from "./home";
import FooterWeb from "../../components/Footer/FooterWeb";

const Home = () => {
  const  isLogged = useSelector(isLoggedIn)

  return (
    <>
      <S.Wrapper>
        <ModalAuth></ModalAuth>
        {isLogged && <Header></Header>}
        <WhatsappButton></WhatsappButton>
        <S.WrapperContent>
          <Box>
          {isLogged ? <ConfigUser></ConfigUser> : <HomePage></HomePage>}
          </Box>
        </S.WrapperContent>
      </S.Wrapper>
      {/* <FooterWeb></FooterWeb> */}
    </>
  );
};

export default Home;
