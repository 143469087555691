import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../app/store/authSlice";
import { useAPITag } from "../../services/api";

const RegisterPetAPI = () => {
  const { http } = useAPITag();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(getUser);

  const createPet = async (values) => {
    try {
      const response = await http.post("/pets", {
        ...values,
        tutorId: user._id,
        tutor: user._id,
        companyId: user.companyId,
      });
      window.location.reload();
    } catch (error) {
      throw error;
    }
  };

  return { createPet };
};

export default RegisterPetAPI;
