import React, { useState, useEffect } from "react";
import { PetsAPI } from "../../components/FormConfigUser/Pets/PetsAPI";
import { useNavigate } from "react-router-dom";
import { ModalRegisterPet } from "../../components/ModalRegisterPet";
import { useAPITag } from "../../services/api";
import Loader from "../../components/Loader";
import Banner from "../../components/Banner";
import fundo from "../../assets/fundo.jpg";
import AvatarChangerHome from "../../components/AvatarChanger/AvatarChangerHome";
import ChangeBanner from "../../components/AvatarChanger/ChangeBanner";
import { useTranslation } from 'react-i18next';


const ConfigUser = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [pets, setPets] = useState([]);
  const [user, setUser] = useState({});
  const [creatingPet, setCreatingPet] = useState(false);
  const { getPets } = PetsAPI();
  const navigate = useNavigate();
  const [menuItem, setMenuItem] = useState("pets");
  const [configUserHeight, setConfigUserHeight] = useState(0);


  const handleChange = (event) => {
    setMenuItem(event.value);
  };

  const { http } = useAPITag();

  const getUserData = async () => {
    setIsLoading(true);
    const user = JSON.parse(localStorage.getItem("@customer"));
    try {
      const response = await http.get("user/" + user._id);
      setUser(response.data);
      setIsLoading(false);
      console.log(response.data);
    } catch (error) {}
  };

const loadPets = async () => {
  setIsLoading(true);
  try {
    const response = await getPets();
    setPets(response);
  }	catch (error) {
    setIsLoading(false);
  }
  setIsLoading(false);
}


const userLogout = () => {
  localStorage.removeItem("@customer");
  window.location.reload();
}
const isMobile = window.innerWidth <= 640;


useEffect(() => {
  loadPets();
  getUserData();
},[]);

useEffect(() => {
  const handleResize = () => {
    setConfigUserHeight(document.getElementById("config-user-container").offsetHeight);
  };

  window.addEventListener('resize', handleResize);

  // Chame a função uma vez para definir a altura inicial
  handleResize();

  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);

  return (
    <>
    {isLoading && (
      <Loader loading={isLoading} />
    )}
      <div id="config-user-container" className="w-full xs:h-auto lg:h-screen bg-[#F5F5F5]">
        <div className="w-full xxxs:max-h-[200px] md:max-h-[400px] relative rounded-lg lg:bg-zinc-400">
          {/* <ChangeBanner></ChangeBanner>
          <img alt=""  src={user && user.capa ? user.capa : "https://www.primegattai.com.br/estruturasmetalicas/wp-content/uploads/2020/05/Laranja.jpg"} className=" h-[300px] md:h-[400px] w-full lg:rounded-lg lg:w-[1000px] lg:h-[400px] mx-auto relative">
          </img> */}
          
          <div className="absolute top-40 md:top-[150px] lg:top-[170px] lg:left-48 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col w-64 rounded-xl items-center justify-center p-4 bg-[#FCFCFD] shadow-md border border-zinc-200">
            <div>
              <h1 className="font-bold text-[24px]">{t('welcome')}</h1>
            </div>

            <div className="flex flex-col justify-center items-center mt-4">
              <div className="border border-zinc-400 w-[112px] h-[112px] rounded-full">
                <div>
                    <AvatarChangerHome text="Alterar imagem de Perfil"></AvatarChangerHome>
                </div>
                <div className="bg-cover bg-center w-full h-full rounded-full" style={{ backgroundImage: `url(${user && user.avatar ? user.avatar : 'https://example.com/default-avatar.jpg'})` }}></div>
              </div>
                <div className="mt-2">
                  <h1 className="font-bold text-[28px]">{user && user.name}</h1>
                </div>
            </div>

            <div className="w-full flex justify-center items-center mt-4">
              <button onClick={() => {
                navigate("/user-settings");
              }} className="border border-zinc-400 rounded-full text-sm p-2">{t('settings')}</button>
            </div> 
          </div>
        </div>
        <div className="w-full h-auto  flex flex-col lg:flex-row justify-center items-center md:mt-32 lg:mt-0 mt-12 xxs:mt-0">
          <div className="flex flex-col py-8 w-full lg:w-[650px] lg:ml-[350px] mx-auto h-auto xxxs:mt-72 md:mt-40 lg:mt-[22px] lg:lg:max-h-[400px]">
            <div className="flex flex-row gap-4 lg:justify-start justify-center items-center mb-8 z-50">
              <h1 className="text-[24px] ml-4 font-semibold">{t('myPets')}</h1>
              <button  
                  onClick={() => {
                    setCreatingPet(true);
                  }}
                  className="w-20 h-8 flex justify-center items-center rounded-full border border-zinc-500 border-dashed text-xs text-zinc-500">
                  {t('add')}
                </button>

            </div>
            <div className="flex flex-row w-full px-8 xxs:px-14 xs:px-4 md:px-12 lg:px-0 items-center justify-start  flex-wrap mx-auto gap-4 lg:border-t lg:border-b border-zinc-200">
              <div className="w-full flex flex-grow-0 flex-row flex-wrap justify-center lg:justify-start items-start min-h-[300px] lg:overflow-y-auto lg:py-4 no-scrollbar">
              {pets &&
                pets.map((e) => {
                  return (
                    <div
                      onClick={() => {
                        navigate("/gestor-pet/" + e._id);
                      }}
                      key={e._id}
                      className="w-28 h-36 flex flex-col items-center justify-center cursor-pointer"
                      >
                      <img alt=""  className="object-cover w-24 h-24 rounded-full" src={e.avatar}>
                      </img>
                      <div className="w-full">
                        <div className="text-center text-sm mt-2 h-8 w-full">{e.nome}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="w-full lg:flex flex-wrap flex-row items-center xxxs:hidden">
                

                {creatingPet && (
                  <ModalRegisterPet
                    open={creatingPet}
                    cancel={() => {
                      setCreatingPet(false);
                      loadPets();
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <button onClick={userLogout} className="lg:hidden w-full flex justify-center items-center text-orange-500 mb-12 mt-4">
        {t('disconnect')}
        </button>
      </div> 
    </>
  );
};

export default ConfigUser;
