import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";

const RedirectToWhatsApp = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const whatsappUrl =
      "https://wa.me/+5531988884400?text=Olá,%20gostaria%20de%20uma%20ajuda";
    window.location.href = whatsappUrl;
  }, []);

  return <Loader />;
};

export default RedirectToWhatsApp;
