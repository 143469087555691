import { useEffect, useRef, useState } from 'react';
import { Html5Qrcode } from 'html5-qrcode';
import './QrStyles.css';
import QrFrame from '../../assets/qr-frame.svg';
import { GestorPetAPI } from '../gestor-pet/gestorPetAPI';
import { getPet } from '../../app/store/authSlice';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';

export const QRReader = ({ add }) => {
  const qrCodeScannerRef = useRef(null);
  const isScannerRunningRef = useRef(false);
  const [qrOn, setQrOn] = useState(true);

  const [cameras, setCameras] = useState([]);
  const [selectedCamera, setSelectedCamera] = useState(null);
  const [usingFacingMode, setUsingFacingMode] = useState(false);

  const { updatePet } = GestorPetAPI();
  const pet = useSelector(getPet);
  const navigate = useNavigate();

  const onScanSuccess = (decodedText) => {
    if (isScannerRunningRef.current) {
      isScannerRunningRef.current = false;
      handleQRCodeProcess(decodedText);
    }
  };

  const handleQRCodeProcess = (value) => {
    Swal.fire({
      title: 'Processando...',
      text: 'Aguarde estamos processando sua solicitação.',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    if (add) {
      if (!pet.qrCodes) pet.qrCodes = [];
      pet.qrCodes.push(value.split('6e.ae/')[1]);
      updatePet(pet._id, pet)
        .then(() => {
          navigate('/gestor-pet/' + pet._id);
        })
        .finally(() => {
          Swal.close();
          if (qrCodeScannerRef.current) {
            qrCodeScannerRef.current.resume();
            isScannerRunningRef.current = true;
          }
        });
      return;
    }

    setTimeout(() => {
      window.location.href = value;
      Swal.close();
    }, 1000);
  };

  const startScanner = async (constraints) => {
    if (qrCodeScannerRef.current && isScannerRunningRef.current) {
      await qrCodeScannerRef.current.stop();
      isScannerRunningRef.current = false;
    }

    qrCodeScannerRef.current = new Html5Qrcode('qr-reader');
    const config = {
      fps: 10,
      qrbox: 250,
      aspectRatio: 1.0,
      disableFlip: false,
      videoConstraints: constraints,
    };

    return qrCodeScannerRef.current.start(
      constraints,
      config,
      onScanSuccess,
      (errorMessage) => {
        if (!errorMessage.includes('NotFoundException')) {
          console.warn(`Erro ao ler QR Code: ${errorMessage}`);
        }
      }
    ).then(() => {
      isScannerRunningRef.current = true;
    });
  };

  const tryFacingMode = async (mode) => {
    try {
      await startScanner({ facingMode: { exact: mode } });
      setUsingFacingMode(true);
      setSelectedCamera(mode);
      return true;
    } catch (e) {
      console.warn(`Não foi possível iniciar com facingMode: ${mode}`, e);
      return false;
    }
  };

  useEffect(() => {
    Html5Qrcode.getCameras()
      .then(async (devices) => {
        if (!devices || devices.length === 0) {
          setQrOn(false);
          Swal.fire({
            icon: 'error',
            title: 'Erro ao iniciar a câmera',
            text: 'Nenhuma câmera encontrada. Conecte uma câmera e recarregue a página.',
          });
          return;
        }

        let success = await tryFacingMode('environment');
        if (!success) {
          success = await tryFacingMode('user');
        }

        if (!success) {
          const allCameras = devices.map(d => ({
            id: d.id,
            label: d.label || 'Câmera sem rótulo'
          }));
          setCameras(allCameras);

          const firstCamera = allCameras[0];
          setSelectedCamera(firstCamera.id);
          setUsingFacingMode(false);
          try {
            await startScanner({ deviceId: { exact: firstCamera.id } });
          } catch (err) {
            console.error("Falha ao iniciar com deviceId:", err);
            setQrOn(false);
            Swal.fire({
              icon: 'error',
              title: 'Erro ao iniciar a câmera',
              text: 'Certifique-se de que a câmera está acessível e recarregue a página.',
            });
          }
        } else {
          setCameras([
            { id: 'environment', label: 'Câmera Traseira' },
            { id: 'user', label: 'Câmera Frontal' },
          ]);
        }
      })
      .catch((err) => {
        console.error("Erro ao obter câmeras:", err);
        setQrOn(false);
        Swal.fire({
          icon: 'error',
          title: 'Erro ao iniciar a câmera',
          text: 'Não foi possível obter a lista de câmeras. Recarregue a página.',
        });
      });

    return () => {
      if (qrCodeScannerRef.current && isScannerRunningRef.current) {
        qrCodeScannerRef.current.stop().then(() => {
          isScannerRunningRef.current = false;
        });
      }
    };
  }, []);

  useEffect(() => {
    if (!qrOn) {
      Swal.fire({
        icon: 'warning',
        title: 'Câmera bloqueada ou inacessível',
        text: 'Permita o acesso à câmera nas configurações do navegador e recarregue a página.',
      });
    }
  }, [qrOn]);

  const handleChangeCamera = async (newCamera) => {
    if (newCamera !== selectedCamera) {
      if (qrCodeScannerRef.current && isScannerRunningRef.current) {
        await qrCodeScannerRef.current.stop();
        isScannerRunningRef.current = false;
      }
      setSelectedCamera(newCamera);
      if (usingFacingMode && (newCamera === 'environment' || newCamera === 'user')) {
        try {
          await startScanner({ facingMode: { exact: newCamera } });
        } catch (err) {
          console.error(`Falha ao mudar para facingMode: ${newCamera}`, err);
        }
      } else {
        try {
          await startScanner({ deviceId: { exact: newCamera } });
        } catch (err) {
          console.error("Falha ao trocar câmera por deviceId:", err);
          setQrOn(false);
          Swal.fire({
            icon: 'error',
            title: 'Erro ao trocar de câmera',
            text: 'Não foi possível acessar a câmera selecionada.',
          });
        }
      }
    }
  };

  return (
    <div className="qr-reader-container">
      <h2>Leia o QrCode</h2>
      <p>Direcione sua câmera para o QRCODE de sua Coleira ou Plaquinha.</p>

      <div className="qr-reader-frame">
        <div id="qr-reader">
          <img src={QrFrame} alt="QR Code Frame" className="qr-frame-overlay" />
        </div>
      </div>

      <p className="camera-instructions">
        Caso sua câmera não esteja focando, tente selecionar outra abaixo.
      </p>

      <div className="camera-selection">
        <select
          id="camera-select"
          value={selectedCamera || ''}
          onChange={(e) => handleChangeCamera(e.target.value)}
          className="custom-select"
        >
          {cameras.length > 0 ? (
            cameras.map((camera) => (
              <option key={camera.id} value={camera.id}>
                {camera.label}
              </option>
            ))
          ) : (
            <option>Nenhuma câmera detectada</option>
          )}
        </select>
      </div>
    </div>
  );
};

export default QRReader;
