import { Button, Drawer, Form, Input, Switch } from "antd";
import Swal from "sweetalert2";
import useRequestCollarsMultiple from "../../../data/usecases/request-collars-multiple-usecase";
import { useSelector } from "react-redux";
import { selectAuthState } from "../login/loginSlice";
import { useForm } from "antd/es/form/Form";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

export const GenerateCollars = ({ selected, setSelected, closeDrawer }) => {
  const authState = useSelector(selectAuthState);
  const requestMultipleCollars = useRequestCollarsMultiple();
  const [form] = useForm();

  const needRapports = (
    required,
    backImage,
    frontImg,
    sizeHeight,
    totalSize,
    isFrontAndBack = true
  ) => {
    if (backImage && required && frontImg && sizeHeight) {
      if (backImage.length > 0 && frontImg.length > 0 && sizeHeight > 0) {
        return {
          frontImageUrl: frontImg,
          backImageUrl: backImage,
          assets: [],
          rapportHeight: sizeHeight,
          rapportQuantity: totalSize / sizeHeight,
        };
      }
    }

    return null;
  };

  const onFinish = async (values) => {
    console.log(values);
    let { repeat, quantity, generateRapports, isTest, columns, needToDivide } =
      values;
    let realQuantity = Number(quantity);
    console.log(needToDivide);
    console.log(isTest);
    console.log(quantity % columns);
    if (needToDivide && quantity % columns !== 0) {
      Swal.fire({
        icon: "error",
        title:
          "A quantidade coleiras precisa ser divisiel pela quantidade de colunas para fazer a divisão corretamente",
      });
    } else if (needToDivide) {
      realQuantity = Number(quantity) / Number(columns);
    }

    if (selected.length > 0) {
      if (quantity) {
        const groupedCollars = selected.reduce((acc, collar) => {
          const identifier = collar.collarType._id;
          if (!acc[identifier]) {
            acc[identifier] = [];
          }
          acc[identifier].push(collar);
          return acc;
        }, {});

        const date = new Date();
        const parsedQuantity = parseInt(quantity);

        for (let j in groupedCollars) {
          const first = groupedCollars[j][0];
          const request = {
            columnsQuantity: parseInt(columns),
            emailsToSend: [authState.user.email],
            companyId: authState.user._id,
            tagDomain: "http://8e.ae",
            tagCodeLength: 4,
            webhookUrl: "https://api-dev.ajuda.pet/",
            spaceInMiddle: first.collarType.columnGap,
            columnGap: selected[0].collarType.columnGap,
            pdfFileKey: `${authState.user._id}/${
              first.collarType.title
            }_${groupedCollars[j].map((collar) => collar.title).join("_")}_${
              first.collarType.size
            }`,
            collars: [],
          };

          for (let i in groupedCollars[j]) {
            const row = groupedCollars[j][i];
            const rapports = [
              needRapports(
                generateRapports,
                row.rapportBack,
                row.rapportFront,
                parseInt(row.rapportHeight),
                parseInt(quantity) * row.collarType.height
              ),
            ].filter((e) => e !== null);

            const collar = {
              collarImageUrl: selected[i].frontUrl,
              spaceInMiddle: row.collarType.columnGap,
              genQrs: isTest,
              rapports: rapports.length > 0 ? rapports : undefined,
              qrcodeType: row.collarType.kind,
              collarWidth: row.collarType.width,
              collarHeight: row.collarType.height,
              collarQuantity: realQuantity,
              codeBoxCMYKColor: row.codeBoxCMYKColor,
              codeFontCMYKColor: row.codeFontCMYKColor,
              qrBoxCMYKColor: row.qrBoxCMYKColor,
              qrcodeCMYKColor: row.qrcodeCMYKColor,
              codeKerning: row.collarSize.codeLetterSpacing,
              codeLetterThickening: row.collarSize.engrossamentoLetra,
              ...row.collarType,
              ...row.collarSize,
            };

            if (needToDivide) {
              for (let index = 0; index < Number(columns); index++) {
                const newCollar = JSON.parse(JSON.stringify(collar));
                newCollar.columnIndex = index;
                console.log(newCollar);
                request.collars.push(newCollar);
              }
            } else {
              request.collars.push(collar);
            }
          }

          if (!repeat) {
            repeat = 1;
          }

          for (let i = 0; i < repeat; i++) {
            requestMultipleCollars(request);
            //await delay(5000);
          }
        }

        closeDrawer();
        Swal.fire({
          title: "Coleiras enviadas para a Fila de Produção",
          icon: "success",
        });
      } else {
        setSelected([]);
      }
    }

    function delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }
  };

  return (
    <Drawer open={true} onClose={closeDrawer}>
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 24 }}
        labelAlign="top"
        labelWrap
        wrapperCol={{ flex: 1 }}
        colon={false}
        style={{ width: "80%" }}
        initialValues={{
          needToDivide: false,
          generateRapports: false,
          isTest: true,
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item name="repeat" label="Repetições">
          <Input type="number" />
        </Form.Item>

        <Form.Item
          name="quantity"
          label="Quantidade de coleiras"
          rules={[
            { required: true, message: "Informe a quantidade de coleiras" },
          ]}
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item
          name="columns"
          label="Quantidade de colunas"
          rules={[
            { required: true, message: "Informe a quantidade de colunas" },
          ]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          valuePropName="checked"
          name="generateRapports"
          label="Gerar com Rapport"
        >
          <Switch />
        </Form.Item>

        <Form.Item
          initialValue={true}
          valuePropName="checked"
          name="needToDivide"
          label="Necessário dividir entre as colunas?"
        >
          <Switch />
        </Form.Item>

        <Form.Item valuePropName="checked" name="isTest" label="Gerar QRCodes">
          <Switch />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 12, offset: 7 }}>
          <Button htmlType="submit">Gerar coleiras</Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};
