import { useEffect, useState } from "react";
import useCreateCollar from "../../data/usecases/create-collar-usecase";
import { useForm } from "antd/es/form/Form";
import { Checkbox, Form, Input, Modal, Row, Select, Space } from "antd";
import { useSelector } from "react-redux";
import { selectAuthState } from "../../features/admin/login/loginSlice";
import CollarTypeAPI from "../../features/admin/collar-type/CollarTypeAPI";
import { UploadInputAdmin } from "../uploadInputAdmin";

export const ModalCreateCollar = (props) => {
  const [frontImage, setFrontImage] = useState([]);
  const [backImage, setBackImage] = useState([]);
  const [rapportFrontImage, setRapportFront] = useState([]);
  const [rapportBackImage, setRapportBack] = useState([]);
  const createCollarUseCase = useCreateCollar();
  const [options, setOptions] = useState([]);
  const [frontEquals, setFrontEquals] = useState(false);
  const [frontRapportEquals, setFrontRapportEquals] = useState(false);

  const [sizeOptions, setSizeOptions] = useState([]);
  const authState = useSelector(selectAuthState);
  const { getCollarTypes } = CollarTypeAPI();
  const [values, setValues] = useState({
    title: "",
    size: "G",
    type: "collar",
    width: 0,
    height: 0,
    kind: "dog",
  });
  const [modalIsOpen, setIsOpen] = useState(true);

  const [form] = useForm();
  const collarPresets = {
    dog: {
      sizes: {
        G: { width: 30, height: 695 },
        M: { width: 25, height: 585 },
        P: { width: 20, height: 430 },
      },
    },
    cat: {
      sizes: {
        Normal: { width: 20, height: 410 },
      },
    },
  };

  function openModal() {
    //setIsOpen(true);
  }

  function closeModal() {
    form.resetFields();

    props.setModal(false);
  }

  const createNewCollar = () => {
    openModal();
  };

  const SizeOptions = () => {
    const options = [];
    for (let i in collarPresets[values.kind].sizes) {
      options.push(<option> {i} </option>);
    }

    return options;
  };

  const createCollar = async (e) => {
    if (frontImage == []) {
      alert("Selecione a imagem de frente da coleira!");
      return;
    }

    if (backImage == [] && !frontEquals) {
      alert("Selecione a imagem do verso da coleira!");
      return;
    }

    if (e.title === "") {
      alert("Selecione um nome para a coleira");
      return;
    }
    const selectedType = options.find((option) => option._id === e.collarType);
    const selectedSize = sizeOptions.find(
      (option) => option._id === e.collarSize
    );

    const collar = {
      title: e.title,
      frontUrl: frontImage[0].url,
      backUrl: frontEquals ? frontImage[0].url : backImage[0].url,
      companyId: authState.user._id,
      codeBoxCMYKColor: JSON.parse(e.codeBoxCMYKColor),
      codeFontCMYKColor: JSON.parse(e.codeFontCMYKColor),
      qrBoxCMYKColor: JSON.parse(e.qrBoxCMYKColor),
      qrcodeCMYKColor: JSON.parse(e.qrcodeCMYKColor),
      barcode: e.barcode,
      collarType: selectedType,
      collarSize: selectedSize,
      rapportHeight: e.rapportHeight,
      rapportBack: !frontRapportEquals
        ? rapportBackImage.length > 0
          ? rapportBackImage[0].url
          : ""
        : rapportFrontImage.length > 0
        ? rapportFrontImage[0].url
        : "",
      rapportFront:
        rapportFrontImage.length > 0 ? rapportFrontImage[0].url : "",
    };

    const res = await createCollarUseCase(collar);
    props.setCollars(res);

    setTimeout(() => {
      closeModal();
    }, 500);
  };

  const updateInput = (name) => (e) => {
    values[name] = e.target.value;
    setValues({ ...values });
  };

  const tryToCreateCollar = () => {
    console.log(form.getFieldsValue());
    form.submit();
  };

  useEffect(() => {
    const loadOptions = async () => {
      const data = await getCollarTypes();
      setOptions(data);
    };

    loadOptions();
  }, []);

  const getOptions = () => {
    return options.map((e) => ({
      label: `${e.title} - ${e.size}`,
      value: e._id,
    }));
  };

  const onChangeEquals = (e) => {
    setFrontEquals(e.target.checked);
  };

  const onChangeRapportEquals = (e) => {
    setFrontRapportEquals(e.target.checked);
  };

  return (
    <Modal
      title="Criar nova Coleira"
      centered
      open={modalIsOpen}
      onOk={tryToCreateCollar}
      onCancel={closeModal}
      width={1000}
    >
      <Form form={form} labelCol={{ span: 24 }} onFinish={createCollar}>
        <Form.Item initialValue={""} label="Titulo" name="title">
          <Input />
        </Form.Item>
        <Form.Item name="collarType" label="Tipo De Coleira">
          <Select options={getOptions()}></Select>
        </Form.Item>
        <Form.Item initialValue={""} label="Código de Barras" name="barcode">
          <Input />
        </Form.Item>
        <Space direction="vertical">
          <Row>
            <UploadInputAdmin
              key="upload-front-url"
              label="Enviar Frente da Coleira"
              onChange={setFrontImage}
            />
            <Checkbox onChange={onChangeEquals}>Verso igual a frente</Checkbox>
          </Row>

          {!frontEquals && (
            <Row>
              <UploadInputAdmin
                key="upload-back-url"
                label="Enviar Verso da Coleira"
                onChange={setBackImage}
              />
            </Row>
          )}

          <Row>
            <UploadInputAdmin
              key="upload-rapport-front"
              label="Enviar Frente do Rapport"
              onChange={setRapportFront}
            />
            <Checkbox onChange={onChangeRapportEquals}>
              Verso do Rapport igual a frente
            </Checkbox>
          </Row>

          {!frontRapportEquals && (
            <Row>
              <UploadInputAdmin
                key="upload-back-rapport"
                label="Enviar Verso do Rapport"
                onChange={setRapportBack}
              />
            </Row>
          )}
        </Space>

        <Form.Item
          initialValue={0}
          label="Tamanho do Rapport (em mm)"
          name="rapportHeight"
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item initialValue={"[0,0,0,0]"} name={`codeBoxCMYKColor`}>
          <Input type="hidden" />
        </Form.Item>

        <Form.Item initialValue={"[0,0,0,100]"} name={`codeFontCMYKColor`}>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item initialValue={"[0,0,0,0]"} name={`qrBoxCMYKColor`}>
          <Input type="hidden" />
        </Form.Item>

        <Form.Item initialValue={"[0,0,0,100]"} name={`qrcodeCMYKColor`}>
          <Input type="hidden" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
