import * as S from "./styles";
import { Checkbox, Form } from "antd";
import Swal from "sweetalert2";
import RegisterPetLayout from "../../../components/layouts/RegisterPetLayout";
import RegisterUserAPI from "./RegisterUserAPI";
import { useDispatch, useSelector } from "react-redux";
import { saveState, selectRegisterstate, setPhone } from "../RegisterPetSlice";
import { useEffect } from "react";
import { useAPITag } from "../../../services/api";
import { formatUserPhoneForDisplay } from "../../../utils/formatUserPhoneForDisplay";

export const RegisterUser = () => {
  const [form] = Form.useForm();
  const { CreateUser } = RegisterUserAPI();
  const { http } = useAPITag();
  const registerState = useSelector(selectRegisterstate);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!registerState.code) return;

    const fetchToken = async () => {
      try {
        const response = await http.get(
          "/user-token/auth-code/" + registerState.code
        );

        dispatch(
          setPhone({
            phone: response.data.token.userId,
            maskedPhone: formatUserPhoneForDisplay(
              `${response.data.token.countryCode}${response.data.token.userId}`
            ),
            grantType: response.data.token.tokenType,
            countryCode: response.data.token.countryCode,
          })
        );

        dispatch(saveState());
      } catch (error) {
        console.error("Erro ao buscar o token:", error);
      }
    };

    fetchToken();
  }, []);

  const onFinishFailed = () => {
    Swal.fire({
      title: "Preencha todos os campos",
      icon: "error",
    });
  };

  return (
    <RegisterPetLayout
      hasHeader
      description="Insira suas informações para reconhecermos você como tutor(a) do pet."
      title="Preencha seus dados"
      size={5}
      step={3}
    >
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 24 }}
        labelAlign="top"
        labelWrap
        wrapperCol={{ flex: 1 }}
        colon={false}
        style={{ width: "80%" }}
        initialValues={{ remember: true, terms: true }}
        onFinish={CreateUser}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          name="nome"
          label="Nome"
          rules={[{ required: true, message: "Informe o seu nome" }]}
        >
          <S.StyledInput />
        </Form.Item>
        <Form.Item
          name="sobrenome"
          label="Sobrenome"
          rules={[{ required: true, message: "Informe o seu sobrenome" }]}
        >
          <S.StyledInput />
        </Form.Item>
        <Form.Item
          label="E-mail"
          name="email"
          rules={[
            { required: true, message: "Informe o seu email", type: "email" },
          ]}
        >
          <S.StyledInput />
        </Form.Item>
        <Form.Item
          initialValue={""}
          label={"Senha"}
          rules={[
            {
              type: "text",
              min: 8,
            },
            { required: true },
          ]}
          name="password"
        >
          <S.StylePassInput />
        </Form.Item>
        <Form.Item
          labelCol={{ span: 12 }}
          labelAlign="left"
          valuePropName="checked"
          name="terms"
          label={"Termos"}
          rules={[
            { required: true, message: "Aceite os termos", type: "boolean" },
          ]}
        >
          <Checkbox>
            {" "}
            <span>
              Eu aceito{" "}
              <a href="/legal" target="_blank">
                os termos de uso
              </a>{" "}
              e{" "}
              <a href="/privacy" target="_blank">
                Politicas de privacidade
              </a>
            </span>
          </Checkbox>
        </Form.Item>
      </Form>

      <S.SubmitButton
        onClick={() => form.submit()}
        type="primary"
        htmlType="submit"
      >
        Avançar
      </S.SubmitButton>
    </RegisterPetLayout>
  );
};
