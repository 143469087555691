import ConfirmCodeAPI from './ConfirmCodeAPI';
import { useParams } from 'react-router-dom';
import { SavePreRegister } from './ConfirmCodeAPI';
import * as S from './styles';
import { useEffect, useMemo, useState } from 'react';
import RegisterPetLayout from '../../../components/layouts/RegisterPetLayout';
import PinField from 'react-pin-field';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { selectRegisterstate } from '../RegisterPetSlice';
import { useNavigate } from 'react-router';
import { useAPITag } from '../../../services/api';

const COUNTDOWN_SECONDS = 60;

export const ConfirmCode = (param) => {
  const { id } = useParams();
  const { http } = useAPITag();
  const navigate = useNavigate();

  const registerState = useSelector(selectRegisterstate);
  const { CheckCode, resendSMS, resendWhatsappCode, savePreRegister } =
    ConfirmCodeAPI();

  const [inputDisabled, setInputDisabled] = useState(true);
  const [code, setCode] = useState(true);
  const [isResendEnabled, setIsResendEnabled] = useState(false);
  const [counter, setCounter] = useState(COUNTDOWN_SECONDS);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // if(counter === 0){
    //   savePreRegister({ statusQr: "pending"});
    //   console.log("salvou", counter);
    // }
    const timer = setTimeout(() => {
      if (counter > 0) {
        setCounter(counter - 1);
      } else {
        setIsResendEnabled(true);
        if (!inputDisabled) {
          navigate('..', { replace: true });
        }
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [counter, isResendEnabled]);

  const handleCheck = () => {
    CheckCode({ key: '', phone: registerState.phone, code: parseInt(code) });
  };

  const formattedPhone = useMemo(() => {
    const splitPhone = registerState.phone.match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
    return `(${splitPhone[1]}) ${splitPhone[2]}-${splitPhone[3]}`;
  }, [registerState.phone]);

  const resendCode = async () => {
    setIsResendEnabled(true);
    Swal.showLoading();
    try {
      if (registerState.grantType === 'whatsapp') {
        await resendWhatsappCode(registerState.phone);
      } else {
        await resendSMS(registerState.phone);
      }

      Swal.fire({
        icon: 'success',
        titleText: 'Código enviado!',
      });
      setCounter(COUNTDOWN_SECONDS);
    } catch {
      Swal.fire({
        titleText: 'Ocorreu um erro!',
        text: 'O reenvio do código falhou. Tente novamente em alguns instantes, por favor.',
        icon: 'error',
      });
      setIsResendEnabled(false);
    } finally {
      setIsResendEnabled(false);
    }
  };

  const onKeyDown = (e) => {
    if (inputDisabled) {
      return;
    }

    if (e.key === 'Enter') {
      handleCheck();
    }
  };

  const handleResetPhoneNumber = async () => {
    setIsLoading(true);

    const tag = id;
    if (!tag) {
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: "Tag não encontrada no parâmetro de rota.",
        confirmButtonText: "OK",
      });
      setIsLoading(false);
      return;
    }

    try {
      await http.patch(`/tags/change-status-admin/${tag}`, {
        statusQr: "",
        userPhone: "",
      });

      navigate(`/${id}`);
    } catch (error) {
      console.error("Erro ao redefinir o número de telefone:", error);

      Swal.fire({
        icon: "error",
        title: "Erro",
        text: "Não foi possível alterar o número. Tente novamente.",
        confirmButtonText: "OK",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <RegisterPetLayout
      hasHeader
      description={
        <span>
          Insira o código de 6 dígitos que enviamos via{' '}
          {registerState.grantType === 'whatsapp' ? 'Whatsapp' : 'SMS'} para o
          número
        </span>
      }
      title="Confirme o código"
      size={5}
      step={2}
      showBackButton
      onClickBackButton={() => navigate('..', { replace: true })}
    >
      <b
        style={{
          fontSize: '2rem',
          marginBottom: '10px',
          color: '#888',
        }}
      >
        {' '}
        {formattedPhone}.
      </b>
      <S.InputWrapper onKeyDown={(e) => onKeyDown(e)}>
        <PinField
          pattern="[0-9]*"
          inputMode="numeric"
          enterKeyHint={inputDisabled ? undefined : 'done'}
          onChange={(e) => {
            if (e.length > 5) {
              setInputDisabled(false);
              setCode(e);
              return;
            }
            setInputDisabled(true);
          }}
          length={6}
        />
      </S.InputWrapper>
      <S.ResendCodeButton
        onClick={resendCode}
        disabled={!isResendEnabled || counter > 0}
      >
        {`Reenviar codigo ${counter > 0 ? `(em ${counter} seg)` : ''}`}
      </S.ResendCodeButton>
      <S.SubmitButton
        onClick={handleCheck}
        disabled={inputDisabled}
        type="primary"
        htmlType="submit"
        style={{
          marginTop: '25px',
          textTransform: 'uppercase',
        }}
      >
        Confirmar
      </S.SubmitButton>

      <S.SubmitButton
        onClick={handleResetPhoneNumber}
        type="primary"
        htmlType="submit"
        disabled={isLoading}
        style={{
          backgroundColor: '#FFF',
          color: '#000',
          border: '1px solid #CCC',
          marginTop: '15px',
          textTransform: 'uppercase',
        }}
      >
        Alterar número celular
      </S.SubmitButton>
    </RegisterPetLayout>
  );
};
