const ThemeData = {
  primaryColor: "#f67e48",
  secondaryColor: "#f67e48",
  set setColors(e) {
    this.primaryColor = e.primaryColor;
    this.secondaryColor = e.secondaryColor;
  },
};

export default ThemeData;
