import { useSelector } from "react-redux";
import Button from "../../Button";
import Input from "../../InputNew";
import * as S from "../styles";
import { Form } from "antd";
import { getUser } from "../../../app/store/authSlice";
import { useForm } from "antd/es/form/Form";
import { ConfigUserAPI } from "../ConfigUserAPI";
import { useGetAddress } from "../../../data/usecases/use-get-address";
import { useTranslation } from 'react-i18next';

const Address = () => {
  const { t } = useTranslation();
  const user = useSelector(getUser);
  const [form] = useForm();
  const { updateUser } = ConfigUserAPI();
  const getAddress = useGetAddress();

  const onCepChange = async (e) => {
    if (e.unmaskedValue.length >= 8) {
      const response = await getAddress(e.unmaskedValue);
      console.log(response.data);
      console.log(form.getFieldsValue());
      form.setFieldsValue({
        ...form.getFieldsValue(),
        ...response.data,
        cep: e.maskedValue,
      });
    }
  };
  return (
    <>
      <Form
        onFinish={updateUser}
        form={form}
        labelCol={{ span: 24 }}
        labelAlign="top"
        labelWrap
        wrapperCol={{ flex: 1 }}
      >
        <S.WrapperSection>
          <S.Subtitle>{t('address')}</S.Subtitle>
          <Input
            onChange={onCepChange}
            initialValue={user.cep}
            name="cep"
            label={t('zipCode')}
            mask="00000-000"
          />
          <Input
            initialValue={user.neighborhood}
            name="bairro"
            label={t('neighborhood')}
          />
          <Input
            initialValue={user.place}
            name="logradouro"
            label={t('street')}
          />
          <S.WrapperInputs>
            <Input initialValue={user.number} name="numero" label={t('number')} />
            <Input
              initialValue={user.complement}
              name="complemento"
              label={t('complement')}
            />
          </S.WrapperInputs>
          <S.WrapperInputs>
            <Input
              disabled
              initialValue={user.city}
              name="cidade"
              label={t('city')}
            />
            <Input
              disabled
              initialValue={user.state}
              name="estado"
              label={t('state')}
            />
          </S.WrapperInputs>
        </S.WrapperSection>
        <S.WrapperSection>
          <S.WrapperButtons position="right">
            <Button color="secundary">{t('cancel')}</Button>
            <Button onClick={form.submit}>{t('save')}</Button>
          </S.WrapperButtons>
        </S.WrapperSection>
      </Form>
    </>
  );
};

export default Address;
