import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../../components/Header';
import Button from '../../../components/Button';
import { useAPITag } from '../../../services/api';
import { WhatsappButton } from '../../../components/WhatsappButton';

export default function PreRegister() {
  const { id } = useParams();
  const { http } = useAPITag();
  const [userPhone, setUserPhone] = useState('');
  const navigate = useNavigate();

  const getTag = async (id) => {
    try {
      const response = await http.get(`tags/${id}`);
      const { tag, hasPet } = response.data;

      if (hasPet) {
        navigate(`/profile/${id}`);
        return;
      }

      const fullPhone = `${tag.countryCode + tag.userPhone}`;
      setUserPhone(fullPhone);
    } catch (error) {
      console.error('Erro ao buscar tag:', error);
    }
  };

  useEffect(() => {
    getTag(id);
  }, [id]);

  return (
    <>
      <Header />
      <div className="w-full h-screen px-4">
        <div className="flex flex-col justify-center items-center bg-[#F67E48] text-white w-full h-48 mt-12 mx-auto rounded-lg">
          <span className="text-xl">ENCONTROU ESSE PET?</span>
          <span className="text-sm mt-2">
            Clique abaixo e avise os tutores!
          </span>
        </div>
        <div className="text-white h-[515px]">
          <Button
            onClick={() => {
              window.open(`https://wa.me/${userPhone.replace(/\D/g, '')}`);
            }}
            className="w-full mt-8"
          >
            Whatsapp
          </Button>
          <button
            onClick={() => {
              window.open(`tel:${userPhone.replace(/\D/g, '')}`);
            }}
            className="mt-4 w-full border border-[#F67E48] text-[15px] min-h-[48px] p-[12px] rounded-full text-[#F67E48]"
          >
            Ligar
          </button>
        </div>
        <WhatsappButton />
      </div>
    </>
  );
}
