import { Button, Dropdown, Input, Row, Table } from "antd";
import Dashboard from "../../../components/layouts/DashLayout";
import * as S from "./styles";
import { useEffect, useState, useCallback } from "react";
import Swal from "sweetalert2";
import { EllipsisOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import CompaniesAPI from "./CompaniesAPI";

const MySwal = withReactContent(Swal);

export const Companies = () => {
  const [search, setSearch] = useState("");
  const [companies, setCompanies] = useState([]);

  const { getAllCompanies, deleteCompany } = CompaniesAPI();
  const navigate = useNavigate();

  const fetchCompanies = useCallback(async (query = "") => {
    try {
      const response = await getAllCompanies(query);
      setCompanies(response);
    } catch (error) {
      console.error("Error fetching companies:", error);
      Swal.fire("Erro", "Não foi possível carregar as empresas.", "error");
    }
  }, [getAllCompanies]);

  useEffect(() => {
    fetchCompanies();
  }, []);

  const handleSearch = () => {
    fetchCompanies(search);
  };

  const columns = [
    {
      title: "Nome",
      render: (row) => `${row.razao_social}`,
    },
    {
      title: "Email",
      render: (row) => `${row.email}`,
    },
    {
      title: "Data de criação",
      render: (row) => `${new Date(row.createdAt).toLocaleDateString("pt-BR")}`,
    },
    {
      title: "Ações",
      render: (row, index) => {
        const items = [
          {
            key: "edit",
            label: (
              <Button
                color="danger"
                onClick={() => navigate(`/admin/companies/${row._id}`)}
              >
                Editar
              </Button>
            ),
          },
          {
            key: "delete",
            label: (
              <Button
                color="danger"
                onClick={() => {
                  Swal.fire({
                    title: "Deseja mesmo deletar essa empresa?",
                    showDenyButton: true,
                    confirmButtonText: "Deletar",
                    denyButtonText: "Não Deletar",
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      await deleteCompany(row._id);
                      setCompanies((prev) =>
                        prev.filter((company) => company._id !== row._id)
                      );
                      Swal.fire("Empresa deletada com sucesso!", "", "success");
                    }
                  });
                }}
              >
                Deletar
              </Button>
            ),
          },
        ];

        return (
          <Dropdown
            menu={{
              items,
            }}
          >
            <Button>
              <EllipsisOutlined style={{ fontSize: "25px" }} />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <Dashboard selected="10">
      <S.Wrapper>
        <Row>
          <Button onClick={() => navigate("/admin/companies/create")}>
            Criar nova empresa
          </Button>
        </Row>
        <div className="flex flex-row gap-2 w-72">
          <Input
            type="text"
            placeholder="Busca pelo nome, e-mail ou cnpj"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <Button
            type="primary"
            className="bg-orange-600 text-white"
            onClick={handleSearch}
          >
            Buscar
          </Button>
        </div>
        <Table
          style={{ width: "80vw" }}
          columns={columns}
          dataSource={companies}
        />
      </S.Wrapper>
    </Dashboard>
  );
};
