/**
 * Gera uma string aleatória para uso em identificadores
 * @param {number} length - Comprimento da string aleatória (padrão: 8)
 * @returns {string} String aleatória
 */
export const generateRandomString = (length = 8) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

/**
 * Extrai a extensão de um nome de arquivo
 * @param {string} fileName - Nome do arquivo
 * @returns {string} Extensão do arquivo ou 'jpeg' se não for encontrada
 */
export const getFileExtension = (fileName) => {
  if (!fileName) return "jpeg";

  const lastDot = fileName.lastIndexOf(".");
  if (lastDot === -1) return "jpeg";

  const extension = fileName.substring(lastDot + 1).toLowerCase();
  const validImageExtensions = ["jpg", "jpeg", "png", "gif", "webp", "bmp"];

  if (extension === "jpg") return "jpeg";

  if (validImageExtensions.includes(extension)) return extension;

  return "jpeg";
};

/**
 * Gera um nome de arquivo único para upload de imagens
 * @param {string} originalName - Nome original do arquivo (opcional)
 * @param {string} prefix - Prefixo personalizado (padrão: 'ajudapet')
 * @returns {string} Nome de arquivo único
 */
export const generateUniqueFileName = (
  originalName = null,
  prefix = "ajudapet"
) => {
  const now = new Date();
  const dateStr =
    now.getFullYear() +
    (now.getMonth() + 1).toString().padStart(2, "0") +
    now.getDate().toString().padStart(2, "0");

  const timeStr =
    now.getHours().toString().padStart(2, "0") +
    now.getMinutes().toString().padStart(2, "0") +
    now.getSeconds().toString().padStart(2, "0");

  const randomId = generateRandomString();

  const extension = getFileExtension(originalName);

  return `${prefix}_${dateStr}_${timeStr}_${randomId}.${extension}`;
};

/**
 * Cria um nome de arquivo único com uma estrutura mais simples
 * @param {string} originalName - Nome original do arquivo (opcional)
 * @returns {string} Nome de arquivo único simplificado
 */
export const createSimpleFileName = (originalName = null) => {
  const timestamp = Date.now();
  const randomPart = generateRandomString(6);
  const extension = getFileExtension(originalName);

  return `image_${timestamp}_${randomPart}.${extension}`;
};

/**
 * Cria um nome de arquivo específico para fotos de usuário/perfil
 * @param {string} userId - ID do usuário (opcional)
 * @returns {string} Nome de arquivo para foto de perfil
 */
export const createProfilePhotoName = (userId = null) => {
  const timestamp = Date.now();
  const userPart = userId ? `_${userId.substring(0, 8)}` : "";
  return `profile${userPart}_${timestamp}_${generateRandomString(4)}.jpeg`;
};
