import { useNavigate } from "react-router-dom";
import { useAPITag } from "../../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthState } from "../login/loginSlice";

const TagsAPI = () => {
  const { http } = useAPITag();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authState = useSelector(selectAuthState);
  const getAllTags = async () => {
    try {
      const response = await http.get("/tags");
      return response.data;
    } catch (error) {}
  };

  const getTags = async (skip) => {
    try {
      const response = await http.get(
        "/tags/company/" + authState.user._id + `?skip=${skip}`
      );

      return response.data;
    } catch (error) {}
  };

  const generateTags = async (quantity) => {
    try {
      const response = await http.post("/tags", {
        path: "https://8e.ae",
        length: 4,
        tags: quantity,
        companyId: authState.user._id,
      });

      const toSave = response.data.map((e) => {
        e.isActive = true;
        return e;
      });

      const responseSave = await http.put("/tags", toSave);

      return response.data;
    } catch (error) {}
  };

  const downloadTags = async () => {
    try {
      const response = await http.post("/filecsv");

      const blob = new Blob([response.data.csv], { type: "text/csv" });

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = response.data.fileName;
      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Erro ao fazer o download do arquivo CSV:", error);
    }
  };

  return {
    getTags,
    getAllTags,
    generateTags,
    downloadTags,
  };
};

export default TagsAPI;
