import { useNavigate } from 'react-router-dom';
import { useAPITag } from '../../../services/api';
import { useDispatch, useSelector } from 'react-redux';
import { saveState, selectConfig } from '../RegisterPetSlice';

const RegisterPetAPI = () => {
  const { http } = useAPITag();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const config = useSelector(selectConfig);

  const createPet = async (values) => {
    try {
      const response = await http.post('/pets', {
        ...values,
        companyId: config.companyId,
      });
      console.log(response);
      dispatch(saveState());

      navigate('/success');
    } catch (error) {
      throw error;
    }
  };

  return { createPet };
};

export default RegisterPetAPI;
