import { useState } from "react";
import { useAPITag } from "../../../services/api";
import { Button, Checkbox, Form, Input } from "antd";
import Dashboard from "../../../components/layouts/DashLayout";
import { LoaderAdmin } from "../../../components/Loader";
import { set } from "react-hook-form";



export default function ChangeStatus() {

  const { http } = useAPITag();
  const [searchedTag, setSearchedTag] = useState(false);
  const [tag, setTag] = useState('');
  const [ phone, setPhone ] = useState('');
  const [status, setStatus] = useState('');
  const [form] = Form.useForm();
  const [statusQr, setStatusQr] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [isTagValid, setIsTagValid] = useState(false);
  const [ isChecked, setIsChecked ] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataSended, setDataSended] = useState(false);
  const [erro, setErro] = useState(false);



  const getTag = async (tag) => {
    try{
      const response = await http.get(`/tags/${tag}`);
      return response.data;
    }catch(error) {
      console.log(error);
      setErro(true);
      await new Promise(resolve => setTimeout(resolve, 3000));
      setErro(false);
    }
  }

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setTag(inputValue);
    setIsTagValid(inputValue.length >= 5);
  }

  const handleButtonClick = async () => {
    const response = await getTag(tag);
    setStatusQr(response.tag.statusQr);
    setUserPhone(response.tag.userPhone);
    setStatus(response.tag.statusQr)
    setPhone(response.tag.userPhone);
    setSearchedTag(true);
  }

  const handleCheck = (e) => {
    setIsChecked(e.target.checked);
    if (e.target.checked) {
      setStatusQr('');
      setUserPhone('');
    } else {
      setStatusQr(status);
      setUserPhone(phone);
    }
  }

  const changeStatusQr = (e) => {
    if (e.target.checked) {
      setStatusQr('pending');
    }
    console.log(statusQr);
  }
  const changeStatusEmpty = (e) => {
    if (e.target.checked) {
      setStatusQr('');
    }
    console.log(statusQr);
  }

  const sendData = async () => {
    try {
      setIsLoading(true);
      const response = await http.patch(`/tags/change-status-admin/${tag}`, {
        statusQr: statusQr,
        userPhone: userPhone
      });
      console.log(response.data)
      setDataSended(true);
      await new Promise(resolve => setTimeout(resolve, 3000));
      setDataSended(false);
    }catch(error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }
  

  return (
    <Dashboard selected="13">
      {isLoading && <LoaderAdmin />}
      <div className="h-screen w-8/12 px-4 bg-white mx-auto mb-8 rounded-md relative">
        {dataSended && <div className="bg-green-500 text-white p-2 rounded-md absolute right-0 z-50">Dados enviados com sucesso</div>}
        {erro && <div className="bg-red-500 text-white p-2 rounded-md absolute right-0 z-50">Coleira não encontrada</div>}
        <div className="absolute left-0 top-10 flex flex-row flex-wrap w-full gap-2 sm:w-72">
          <Input onChange={handleInputChange} placeholder="Insira o código da coleira" />
          <Button onClick={handleButtonClick} disabled={!isTagValid} className="bg-orange-600 text-white">Buscar</Button>
        </div>

        <div className="absolute left-0 top-32 mt-12">
            <Form.Item>
              <Checkbox type="checkbox" checked={isChecked} onChange={handleCheck}>Resetar PréCadastro</Checkbox>
            </Form.Item>
          <Form form={form} disabled={!searchedTag || isChecked } layout="vertical">

            <Form.Item label="Telefone do PréCadastro">
              <Input type="number" value={userPhone} onChange={(e) => setUserPhone(e.target.value)} />
            </Form.Item>


            <Form.Item label="Status">
              <Checkbox onChange={changeStatusQr} checked={statusQr === 'pending'}>Pendente</Checkbox>
              <Checkbox onChange={changeStatusEmpty} checked={statusQr === ''}>Sem Status</Checkbox>
            </Form.Item>
          </Form>
            <Form.Item disabled={!isTagValid}>
              <Button onClick={sendData} className="bg-orange-500 text-white" type="primary">Salvar</Button>
            </Form.Item>
        </div>
      </div>
    </Dashboard>
  );
}