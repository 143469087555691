import {
  Button,
  Col,
  Dropdown,
  Modal,
  Progress,
  Row,
  Select,
  Table,
  notification,
} from "antd";
import Dashboard from "../../../components/layouts/DashLayout";
import * as S from "./styles";
import { useEffect, useState } from "react";
import { selectAuthState } from "../login/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import useRequestCollars from "../../../data/usecases/request-collars-usecase";
import useGetCollars from "../../../data/usecases/get-collars-usecase";
import Swal from "sweetalert2";
import useDeleteCollar from "../../../data/usecases/delete-collars-usecase";
import { ModalCreateCollar } from "../../../components/modalCreateCollar";
import { EllipsisOutlined } from "@ant-design/icons";
import { ModalEditCollar } from "../../../components/modalEditCollar";
import FilesModal from "../../../components/filesModal";
import { setModalFile } from "../../../components/filesModal/filesModalSlice";
import CompaniesAPI from "../companies/CompaniesAPI";
export const CompaniesCollars = () => {
  const [collars, setCollars] = useState([]);
  const [companies, setCompanies] = useState([]);
  const authState = useSelector(selectAuthState);
  const getCollarsUseCase = useGetCollars();
  const requestcollars = useRequestCollars();
  const deleteColllar = useDeleteCollar();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalUpdateOpen, setModalUpdate] = useState(false);
  const [currentCollar, setCurrentCollar] = useState({});
  const [nameFilters, setNameFilters] = useState([]);
  const [selected, setSelected] = useState([]);
  const { getAllCompanies } = CompaniesAPI();

  const dispatch = useDispatch();
  useEffect(() => {
    const loadCompanies = async () => {
      const response = await getAllCompanies();
      setCompanies(response);
    };

    loadCompanies();
    getCollars(authState.user._id);
  }, []);

  const columns = [
    {
      title: "Nome da Estampa",
      render: (row) => row.title,
      onFilter: (value, record) => record.title.includes(value),
      filters: nameFilters,
      filterSearch: true,
      onFilter: (value, record) => record.title.startsWith(value),
    },
    {
      title: "Espécie",
      render: (row) => row.collarType.kind,
    },
    {
      title: "Tipo",
      render: (row) => row.collarType.title,
      filterSearch: true,
      onFilter: (value, record) => record.collarType.title.includes(value),
      filters: [
        {
          text: "Pescoço",
          value: "Pescoço",
        },
        {
          text: "Peitoral H",
          value: "Peitoral H",
        },
        {
          text: "Peitoral 8",
          value: "Peitoral 8",
        },
        {
          text: "Antipuxão",
          value: "Antipuxão",
        },
      ],
    },
    {
      title: "Tamanho",
      render: (row) => row.collarType.size,
      filterSearch: true,
      onFilter: (value, record) => record.collarType.size.includes(value),
      filters: [
        {
          text: "P",
          value: "P",
        },
        {
          text: "PP",
          value: "PP",
        },
        {
          text: "M",
          value: "M",
        },
        {
          text: "G",
          value: "G",
        },
        {
          text: "Único",
          value: "Único",
        },
      ],
    },
    {
      title: "Código de Barras",
      render: (row) => row.barcode,
    },
    {
      title: "Ações",
      render: (row, index, column, i) => {
        const items = [
          {
            key: "actions_1_" + index,
            label: (
              <Button
                onClick={async () => {
                  const { value: answer } = await Swal.fire({
                    title: "Quantas coleiras deseja fazer?",
                    input: "number",
                    inputLabel: "Quantidade de Coleira",
                    inputValue: 0,
                    showCancelButton: true,
                    inputValidator: (value) => {
                      if (!value) {
                        return "Informe a quantidade!";
                      }
                    },
                  });

                  const { value: columns } = await Swal.fire({
                    title: "Quantas colunas deseja fazer?",
                    input: "number",
                    inputLabel: "Quantidade de Colunas",
                    inputValue: 0,
                    showCancelButton: true,
                    inputValidator: (value) => {
                      if (!value) {
                        return "Informe a quantidade!";
                      }
                    },
                  });

                  if (answer) {
                    const quantity = parseInt(answer);
                    console.log(row);
                    const date = new Date();
                    const request = {
                      backImageUrl: row.backUrl,
                      frontImageUrl: row.frontUrl,
                      qrcodeType: row.collarType.kind,
                      collarWidth: row.collarType.width,
                      collarHeight: row.collarType.height,
                      columnMinHeight:
                        (row.collarType.height * parseInt(answer)) /
                        parseInt(columns),
                      collarQuantity: parseInt(answer),
                      emailsToSend: [authState.user.email],
                      companyId: row.companyId,
                      tagDomain: "http://8e.ae",
                      tagCodeLength: 4,
                      codeBoxCMYKColor: row.codeBoxCMYKColor,
                      codeFontCMYKColor: row.codeFontCMYKColor,
                      qrBoxCMYKColor: row.qrBoxCMYKColor,
                      qrcodeCMYKColor: row.qrcodeCMYKColor,
                      codeKerning: row.collarSize.codeLetterSpacing,
                      codeLetterThickening: row.collarSize.engrossamentoLetra,
                      pdfFileKey: `${authState.user._id}/${
                        row.title
                      }_${date.getDate()}_${date.getMonth() + 1}_${
                        row.collarType.size
                      }`,
                      ...row.collarType,
                      ...row.collarSize,
                    };
                    console.log(row);
                    console.log(request);
                    requestcollars(request);

                    Swal.fire({
                      title: "Coleiras enviadas para a Fila de Produção",
                      icon: "success",
                    });
                  }
                }}
              >
                Gerar
              </Button>
            ),
          },
          {
            key: "actions_2_" + index,
            label: (
              <Button
                color="danger"
                onClick={async () => {
                  Swal.fire({
                    title: "Deseja mesmo deletar essa coleira?",
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: "Deletar",
                    denyButtonText: `Não Deletar`,
                  }).then(async (result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                      await deleteColllar(row._id);
                      const index = collars.indexOf(row);
                      collars.splice(index, 1);
                      setCollars([...collars]);
                      Swal.fire("Coleira deletada com sucesso!", "", "success");
                    } else if (result.isDenied) {
                    }
                  });
                }}
              >
                Deletar
              </Button>
            ),
          },
          {
            key: "actions_3_" + index,
            label: (
              <Button
                color="danger"
                onClick={async () => {
                  setCurrentCollar(row);
                  setModalUpdate(true);
                }}
              >
                Editar
              </Button>
            ),
          },
        ];
        return (
          <Dropdown
            menu={{
              items,
            }}
          >
            <Button href="#" onClick={(e) => e.preventDefault()}>
              <EllipsisOutlined style={{ fontSiz: "25px" }} />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const deleteSelectedTags = () => {
    Swal.fire({
      title: "Deseja mesmo deletar essas coleiras?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Deletar",
      denyButtonText: `Não Deletar`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await Promise.all(
          selected.map(async (row) => {
            await deleteColllar(row._id);
            const index = collars.indexOf(row);
            collars.splice(index, 1);
            setCollars([...collars]);
          })
        );

        Swal.fire("Coleiras deletada scom sucesso!", "", "success");
        setSelected([]);
      } else if (result.isDenied) {
      }
    });
  };

  const getCollars = async (id) => {
    const response = await getCollarsUseCase(id);
    const filters = [];
    response.forEach((e) => {
      const hasFilter = filters.find((j) => {
        return j.text === e.title;
      });
      if (!hasFilter) {
        filters.push({
          value: e.title,
          text: e.title,
          key: e.title,
        });
      }
    });

    setNameFilters(filters);
    setCollars(
      response.map((e) => {
        e.key = e._id;
        return e;
      })
    );
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );

      setSelected(selectedRows);
    },
    getCheckboxProps: (row) => {
      return {
        name: row._id + row.collarType.size,
        id: row._id,
      };
    },
    type: "checkbox",
  };

  const generateSelected = async () => {
    if (selected.length > 0) {
      const { value: answer } = await Swal.fire({
        title: "Quantas coleiras deseja fazer?",
        input: "number",
        inputLabel: "Quantidade de Coleira",
        inputValue: 0,
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return "Informe a quantidade!";
          }
        },
      });

      const { value: columns } = await Swal.fire({
        title: "Quantas colunas deseja fazer?",
        input: "number",
        inputLabel: "Quantidade de Colunas",
        inputValue: 0,
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return "Informe a quantidade!";
          }
        },
      });

      if (answer) {
        const quantity = parseInt(answer);
        const date = new Date();
        await Promise.all(
          selected.map(async (row) => {
            const request = {
              backImageUrl: row.backUrl,
              frontImageUrl: row.frontUrl,
              qrcodeType: row.collarType.kind,
              collarWidth: row.collarType.width,
              collarHeight: row.collarType.height,
              columnMinHeight:
                (row.collarType.height * quantity) / parseInt(columns),
              collarQuantity: quantity,
              emailsToSend: [authState.user.email],
              companyId: row.companyId,
              tagDomain: "http://8e.ae",
              tagCodeLength: 4,
              codeBoxCMYKColor: row.codeBoxCMYKColor,
              codeFontCMYKColor: row.codeFontCMYKColor,
              qrBoxCMYKColor: row.qrBoxCMYKColor,
              qrcodeCMYKColor: row.qrcodeCMYKColor,
              codeKerning: row.collarSize.codeLetterSpacing,
              codeLetterThickening: row.collarSize.engrossamentoLetra,
              pdfFileKey: `${authState.user._id}/${
                row.title
              }_${date.getDate()}_${date.getMonth() + 1}_${
                row.collarType.size
              }`,
              ...row.collarType,
              ...row.collarSize,
            };

            requestcollars(request);
          })
        );

        Swal.fire({
          title: "Coleiras enviadas para a Fila de Produção",
          icon: "success",
        });
      } else {
        setSelected([]);
      }
    }
  };
  const key = "COLLAR_DELETE_NOTIFY";

  const [api, contextHolder] = notification.useNotification();

  const openNotification = (totalFiles, filesCount, currentFileName) => {
    api.open({
      key,
      message: "Deletando Coleiras - " + `${filesCount} / ${totalFiles}`,
      description: (
        <Col>
          <p>{currentFileName}</p>
          <Progress percent={Math.floor((filesCount / totalFiles) * 100)} />
        </Col>
      ),
    });
  };

  return (
    <Dashboard selected="11">
      <FilesModal></FilesModal>
      <S.Wrapper>
        <Row>
          <Select
            placeholder="Selecione uma empresa"
            style={{ width: 350 }}
            onChange={(e) => {
              getCollars(e);
            }}
            options={companies.map((e) => ({
              value: e._id,
              label: e.razao_social,
            }))}
          ></Select>
          <Button
            color="primary"
            style={{ marginLeft: "1rem" }}
            onClick={generateSelected}
          >
            Gerar Selecionados ({selected.length})
          </Button>

          <Button
            color="primary"
            style={{ marginLeft: "1rem" }}
            onClick={deleteSelectedTags}
          >
            Deleta Selecionados ({selected.length})
          </Button>
        </Row>
        <Table
          rowSelection={rowSelection}
          pagination={{ pageSize: 100 }}
          style={{ width: "80vw" }}
          columns={columns}
          dataSource={collars}
        />
      </S.Wrapper>
      {isModalUpdateOpen && (
        <ModalEditCollar
          collar={currentCollar}
          setModal={setModalUpdate}
          setCollars={setCollars}
        ></ModalEditCollar>
      )}
    </Dashboard>
  );
};
