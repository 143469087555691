import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './i18n/en.json';
import es from './i18n/es.json';
import pt from './i18n/pt.json';

const domain = window.location.hostname;

let defaultLanguage = 'pt';

if (domain === 'ajuda.pet') {
    defaultLanguage = 'pt';
} else if (domain === 'help.pet') {
    defaultLanguage = 'en';
} else if (domain === 'ayuda.pet') {
    defaultLanguage = 'es';
}

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: { translation: en },
            es: { translation: es },
            pt: { translation: pt },
        },
        lng: defaultLanguage,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
