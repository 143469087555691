import { useEffect, useState } from 'react';
import { PetsAPI } from '../../../components/FormConfigUser/Pets/PetsAPI';
import RegisterPetLayout from '../../../components/layouts/RegisterPetLayout';
import { Row } from 'antd';
import * as S from './styles';
import { PlusIcon } from '@radix-ui/react-icons';
import { useNavigate } from 'react-router-dom';
import SelectPetAPI from './selectPetAPI';

export const SelectPet = () => {
  const { getPets } = PetsAPI();
  const { selectPet } = SelectPetAPI();
  const [pets, setPets] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const loadPets = async () => {
      const petsData = await getPets();
      setPets(petsData);
    };

    loadPets();
  }, []);

  return (
    <RegisterPetLayout
      hasHeader
      description="Associe a nova coleira a um pet já cadastrado ou adicione um novo pet."
      title="Selecione seu Pet"
      size={4}
      step={4}
    >
      <Row justify={'center'}>
        <S.PetWrapper
          onClick={() => navigate('/register-pet')}
          sm={5}
          key={'add_new_pet'}
        >
          <div className="w-20">
            <PlusIcon className="w-full h-full"></PlusIcon>
          </div>
          <S.PetTitleWrapper>
            <S.PetTitle>Adicionar Novo</S.PetTitle>
          </S.PetTitleWrapper>
        </S.PetWrapper>
        {pets &&
          pets.map((e) => {
            return (
              <S.PetWrapper onClick={selectPet(e._id)} key={e._id}>
                <S.PetAvatar
                  className="rounded-full"
                  src={e.avatar}
                ></S.PetAvatar>
                <S.PetTitleWrapper>
                  <S.PetTitle>{e.nome}</S.PetTitle>
                </S.PetTitleWrapper>
              </S.PetWrapper>
            );
          })}
      </Row>
    </RegisterPetLayout>
  );
};
