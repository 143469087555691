import React, { useState, useEffect, useRef, memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import QrScanner from "qr-scanner";
import { useTranslation } from "react-i18next";
import { FaQrcode } from "react-icons/fa";
import { IoCamera, IoCameraReverse, IoReload } from "react-icons/io5";
import { AiOutlineWarning } from "react-icons/ai";
import { BiLoaderAlt } from "react-icons/bi";
import { MdCameraswitch } from "react-icons/md";
import { Popover, Tooltip } from "antd";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { getPet } from "../../app/store/authSlice";
import { GestorPetAPI } from "../gestor-pet/gestorPetAPI";
import Header from "../../components/Header";

const isAndroid = /Android/i.test(navigator.userAgent);
const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
const isMobile = isAndroid || isIOS || /Mobile/i.test(navigator.userAgent);

const showCameraChangeToast = (cameraName) => {
  const toastContainer = document.createElement("div");
  toastContainer.id = "camera-toast-container";
  document.body.appendChild(toastContainer);

  toastContainer.className =
    "fixed top-16 left-1/2 transform -translate-x-1/2 bg-black/70 text-white px-4 py-2 rounded-full text-sm z-50 flex items-center";
  toastContainer.innerHTML = `
    <svg class="h-4 w-4 mr-2" fill="currentColor" viewBox="0 0 24 24">
      <path d="M12 9a3 3 0 100 6 3 3 0 000-6z"/>
      <path d="M20 4h-3.17L15 2H9L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z"/>
    </svg>
    <span>${cameraName}</span>
  `;

  setTimeout(() => {
    toastContainer.style.opacity = "0";
    toastContainer.style.transition = "opacity 0.5s ease";
    setTimeout(() => {
      if (document.body.contains(toastContainer)) {
        document.body.removeChild(toastContainer);
      }
    }, 500);
  }, 2000);
};

const ScanFrame = memo(({ processing, scanError }) => {
  const frameColor = processing
    ? "border-green-500"
    : scanError
    ? "border-red-500"
    : "border-[#ff6e30]";

  return (
    <div className="absolute inset-0 z-10 pointer-events-none">
      <div className="absolute inset-0">
        <div
          className={`absolute border-2 ${frameColor} rounded-lg`}
          style={{
            top: "50%",
            left: "50%",
            width: "70%",
            height: "70%",
            transform: "translate(-50%, -50%)",
            boxShadow: "0 0 0 1000px rgba(0, 0, 0, 0.3)",
            transition: "border-color 0.3s ease",
          }}
        >
          <div
            className={`absolute top-0 left-0 w-6 h-6 border-t-2 border-l-2 ${frameColor}`}
            style={{ transition: "border-color 0.3s ease" }}
          ></div>
          <div
            className={`absolute top-0 right-0 w-6 h-6 border-t-2 border-r-2 ${frameColor}`}
            style={{ transition: "border-color 0.3s ease" }}
          ></div>
          <div
            className={`absolute bottom-0 left-0 w-6 h-6 border-b-2 border-l-2 ${frameColor}`}
            style={{ transition: "border-color 0.3s ease" }}
          ></div>
          <div
            className={`absolute bottom-0 right-0 w-6 h-6 border-b-2 border-r-2 ${frameColor}`}
            style={{ transition: "border-color 0.3s ease" }}
          ></div>

          {!processing && !scanError && (
            <div
              className="absolute left-2 right-2 h-0.5 bg-[#ff6e30] z-20"
              style={{
                animation: "scanAnimation 2s linear infinite",
                boxShadow: "0 0 8px 1px rgba(255, 110, 48, 0.8)",
              }}
            ></div>
          )}
        </div>
      </div>
    </div>
  );
});

const StatusMessage = memo(
  ({
    isLoading,
    processing,
    scanError,
    t,
    hasFlash,
    onToggleFlash,
    flashOn,
    onReset,
  }) => {
    return (
      <div className="mb-4 text-center bg-black/50 p-4 rounded-lg w-full max-w-md backdrop-blur-sm">
        <div className="flex items-center justify-center mb-2">
          {isLoading ? (
            <>
              <BiLoaderAlt className="text-[#ff6e30] animate-spin" size={24} />
              <span className="ml-2 font-medium">Iniciando câmera...</span>
            </>
          ) : processing ? (
            <>
              <BiLoaderAlt className="text-[#ff6e30] animate-spin" size={24} />
              <span className="ml-2 font-medium">Processando QR Code...</span>
            </>
          ) : scanError ? (
            <>
              <AiOutlineWarning className="text-[#f56565]" size={24} />
              <span className="ml-2 font-medium text-red-400">{scanError}</span>
            </>
          ) : (
            <>
              <FaQrcode className="text-[#ff6e30]" size={24} />
              <span className="ml-2 font-medium">Escaneando...</span>
            </>
          )}
        </div>

        <p className="text-sm text-gray-300">
          {isLoading
            ? "Aguarde enquanto a câmera é inicializada..."
            : processing
            ? "Aguarde enquanto verificamos o QR Code."
            : scanError
            ? "Houve um problema ao ler o QR Code. Tente novamente."
            : t(
                "qrcode.instruction",
                "Posicione o QR Code dentro do quadro central e aguarde a detecção automática."
              )}
        </p>

        {hasFlash && !isLoading && !processing && (
          <button
            onClick={onToggleFlash}
            className="mt-3 bg-[#ff6e30] hover:bg-gray-600 text-white py-2 px-4 rounded-md text-sm transition-colors flex items-center justify-center mx-auto"
          >
            <svg
              className="w-4 h-4 mr-2"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M7 2v11h3v9l7-12h-4l4-8z"></path>
            </svg>
            {flashOn ? "Desligar Lanterna" : "Ligar Lanterna"}
          </button>
        )}

        {scanError && (
          <button
            className="mt-3 bg-[#ff6e30] hover:bg-[#e05a20] text-white py-2 px-4 rounded-md w-full transition-colors flex items-center justify-center"
            onClick={onReset}
          >
            <FaQrcode className="mr-2" />
            Tentar novamente
          </button>
        )}
      </div>
    );
  }
);

const QRScanner = ({ add }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const scannerRef = useRef(null);

  const [hasPermission, setHasPermission] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [scanError, setScanError] = useState(null);
  const [cameras, setCameras] = useState([]);
  const [selectedCamera, setSelectedCamera] = useState(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [hasFlash, setHasFlash] = useState(false);
  const [flashOn, setFlashOn] = useState(false);
  const [scanAttemptCount, setScanAttemptCount] = useState(0);
  const [showCameraHint, setShowCameraHint] = useState(false);
  const scanTimerRef = useRef(null);
  const lastScanRef = useRef(Date.now());
  const switchToNextCameraRef = useRef(null);

  const pet = useSelector(getPet);
  const { updatePet } = GestorPetAPI ? GestorPetAPI() : { updatePet: null };

  const resetScanAttempts = useCallback(() => {
    setScanAttemptCount(0);
    setShowCameraHint(false);

    if (scanTimerRef.current) {
      clearInterval(scanTimerRef.current);
      scanTimerRef.current = null;
    }
  }, []);

  const resetScanner = useCallback(async () => {
    setScanError(null);
    setProcessing(false);
    resetScanAttempts();

    if (scannerRef.current) {
      try {
        await scannerRef.current.stop();
        await scannerRef.current.start();
        console.log("Scanner reiniciado com sucesso");
      } catch (error) {
        console.error("Erro ao reiniciar scanner:", error);
        setScanError("Erro ao reiniciar a câmera");
      }
    }
  }, [resetScanAttempts]);

  const toggleFlash = useCallback(async () => {
    if (!scannerRef.current || !hasFlash) return;

    try {
      await scannerRef.current.toggleFlash();
      setFlashOn(!flashOn);
      console.log("Flash toggled:", !flashOn);
    } catch (error) {
      console.error("Erro ao alternar flash:", error);
    }
  }, [flashOn, hasFlash]);

  const handleQrCodeDetected = useCallback(
    (value) => {
      console.log("QR Code detectado:", value);
      setProcessing(true);

      resetScanAttempts();

      if (scannerRef.current) {
        scannerRef.current.pause();
      }

      if (add && updatePet) {
        handleAddQRCodeToPet(value);
      } else {
        handleQRCodeProcess(value);
      }
    },
    [add, updatePet, resetScanAttempts]
  );

  const handleAddQRCodeToPet = useCallback(
    (value) => {
      if (!pet.qrCodes) pet.qrCodes = [];

      let qrCode;
      if (value.includes("6e.ae/")) {
        qrCode = value.split("6e.ae/")[1];
      } else if (value.match(/^[a-zA-Z0-9\-_]{5,20}$/)) {
        qrCode = value;
      } else if (value.startsWith("http")) {
        const urlParts = value.split("/");
        qrCode = urlParts[urlParts.length - 1];
      } else {
        qrCode = value;
      }

      console.log("Código QR extraído para adicionar ao pet:", qrCode);
      pet.qrCodes.push(qrCode);

      updatePet(pet._id, pet)
        .then(() => {
          navigate("/gestor-pet/" + pet._id);
        })
        .catch((error) => {
          console.error("Erro ao atualizar pet:", error);
          Swal.fire({
            title: "Erro",
            text: "Ocorreu um erro ao processar o QR Code.",
            icon: "error",
            confirmButtonText: "Tentar novamente",
            confirmButtonColor: "#ff6e30",
          }).then(() => {
            resetScanner();
          });
        });
    },
    [pet, updatePet, navigate, resetScanner]
  );

  const handleQRCodeProcess = useCallback(
    (value) => {
      console.log("Processando QR code para redirecionamento:", value);

      if (value.startsWith("http")) {
        setTimeout(() => {
          window.location.href = value;
        }, 1000);
      } else {
        try {
          if (value.match(/^[a-zA-Z0-9\-_]+$/)) {
            const url = `https://6e.ae/${value}`;
            console.log("Redirecionando para:", url);
            setTimeout(() => {
              window.location.href = url;
            }, 1000);
          } else {
            const match = value.match(/[a-zA-Z0-9\-_]{5,20}/);
            if (match) {
              const url = `https://6e.ae/${match[0]}`;
              console.log("Extraído e redirecionando para:", url);
              setTimeout(() => {
                window.location.href = url;
              }, 1000);
            } else {
              Swal.fire({
                title: "Erro",
                text: "QR Code inválido ou não reconhecido.",
                icon: "error",
                confirmButtonText: "Tentar novamente",
                confirmButtonColor: "#ff6e30",
              }).then(() => {
                resetScanner();
              });
            }
          }
        } catch (e) {
          console.error("Erro ao processar QR code:", e);
          Swal.fire({
            title: "Erro",
            text: "QR Code inválido ou não reconhecido.",
            icon: "error",
            confirmButtonText: "Tentar novamente",
            confirmButtonColor: "#ff6e30",
          }).then(() => {
            resetScanner();
          });
        }
      }
    },
    [resetScanner]
  );

  const getCameraScore = (camera) => {
    const label = camera.label.toLowerCase();
    let score = 0;

    if (
      label.includes("back") ||
      label.includes("traseira") ||
      label.includes("environment")
    ) {
      score += 100;
    }

    if (
      label.includes("0") ||
      label.includes("main") ||
      label.includes("primary") ||
      label.includes("principal")
    ) {
      score += 50;
    }

    if (label.includes("ultra") || label.includes("wide")) {
      score -= 20;
    }
    if (label.includes("macro")) {
      score += 10;
    }
    if (label.includes("tele") || label.includes("zoom")) {
      score -= 10;
    }

    return score;
  };

  useEffect(() => {
    let unmounted = false;

    async function initScanner() {
      console.log("Iniciando scanner simplificado");

      const isSecure =
        window.location.protocol === "https:" ||
        window.location.hostname === "localhost" ||
        window.location.hostname === "127.0.0.1";

      if (!isSecure) {
        setScanError("A câmera só pode ser acessada via HTTPS ou localhost");
        setIsLoading(false);
        return;
      }

      try {
        if (scannerRef.current) {
          scannerRef.current.stop();
          scannerRef.current.destroy();
          scannerRef.current = null;
        }

        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: true,
          });
          stream.getTracks().forEach((track) => track.stop());
          setHasPermission(true);
        } catch (err) {
          console.error("Erro ao obter permissão de câmera:", err);
          setScanError("Permissão de câmera negada");
          setIsLoading(false);
          return;
        }

        const availableCameras = await QrScanner.listCameras(true);
        console.log("Câmeras disponíveis (todas):", availableCameras);

        const filteredCameras = availableCameras.filter((camera) => {
          const label = camera.label.toLowerCase();
          const isFrontCamera =
            label.includes("front") ||
            label.includes("frontal") ||
            label.includes("selfie") ||
            label.includes("user");

          return !isFrontCamera;
        });

        console.log("Câmeras filtradas (sem frontais):", filteredCameras);

        const camerasToUse =
          filteredCameras.length > 0 ? filteredCameras : availableCameras;

        camerasToUse.sort((a, b) => getCameraScore(b) - getCameraScore(a));

        console.log("Câmeras ordenadas por qualidade estimada:", camerasToUse);

        if (camerasToUse.length === 0) {
          setScanError("Nenhuma câmera disponível");
          setIsLoading(false);
          return;
        }

        if (unmounted) return;

        setCameras(camerasToUse);

        const preferredCamera = isMobile
          ? camerasToUse.find((camera) => {
              const label = camera.label.toLowerCase();
              return (
                label.includes("back") ||
                label.includes("traseira") ||
                label.includes("environment") ||
                label.includes("0")
              );
            })?.id || camerasToUse[0].id
          : camerasToUse[0].id;

        setSelectedCamera(preferredCamera);

        scannerRef.current = new QrScanner(
          videoRef.current,
          (result) => {
            if (!processing) {
              handleQrCodeDetected(result.data);
            }
          },
          {
            preferredCamera: preferredCamera,
            highlightScanRegion: false,
            highlightCodeOutline: true,
            maxScansPerSecond: 5,
          }
        );

        try {
          await scannerRef.current.start();
          console.log("Scanner iniciado com sucesso");

          try {
            const hasFlashSupport = await scannerRef.current.hasFlash();
            setHasFlash(hasFlashSupport);
          } catch (e) {
            console.log("Flash não suportado");
            setHasFlash(false);
          }

          setIsLoading(false);
        } catch (error) {
          console.error("Erro ao iniciar scanner:", error);
          setScanError(`Erro ao iniciar câmera: ${error.message}`);
          setIsLoading(false);
        }
      } catch (err) {
        console.error("Erro geral ao configurar scanner:", err);
        setScanError("Erro ao configurar scanner");
        setIsLoading(false);
      }
    }

    const timer = setTimeout(() => {
      if (!unmounted) {
        initScanner();
      }
    }, 300);

    return () => {
      unmounted = true;
      clearTimeout(timer);

      if (scannerRef.current) {
        console.log("Limpando scanner...");
        try {
          scannerRef.current.stop();
          scannerRef.current.destroy();
        } catch (e) {
          console.error("Erro ao limpar scanner:", e);
        }
        scannerRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (isLoading || processing || scanError) return;

    scanTimerRef.current = setInterval(() => {
      const now = Date.now();
      if (now - lastScanRef.current > 1000) {
        setScanAttemptCount((prev) => {
          const newCount = prev + 1;
          console.log(`Tentativa ${newCount} sem detecção de QR code`);

          if (newCount === 3) {
            setShowCameraHint(true);
          }

          if (newCount === 6 && cameras.length > 1) {
            showCameraChangeToast("Trocando para outra câmera");
            setTimeout(() => {
              if (switchToNextCameraRef.current) {
                switchToNextCameraRef.current();
                resetScanAttempts();
              }
            }, 1000);
          }

          return newCount;
        });
      }

      lastScanRef.current = now;
    }, 1000);

    return () => {
      if (scanTimerRef.current) {
        clearInterval(scanTimerRef.current);
      }
    };
  }, [isLoading, processing, scanError, cameras, resetScanAttempts]);

  const switchCamera = useCallback(
    async (cameraId) => {
      if (!cameraId || isLoading || processing || selectedCamera === cameraId) {
        console.log("Não é possível trocar câmera agora");
        return;
      }

      console.log("Iniciando troca para câmera:", cameraId);
      setIsLoading(true);
      setScanError(null);

      await new Promise((resolve) => setTimeout(resolve, 100));

      try {
        if (scannerRef.current) {
          console.log("Parando scanner atual...");
          try {
            await scannerRef.current.stop();
            scannerRef.current.destroy();
            scannerRef.current = null;
          } catch (e) {
            console.warn("Erro ao limpar scanner anterior:", e);
          }
        }

        if (videoRef.current && videoRef.current.srcObject) {
          console.log("Liberando streams de mídia...");
          const tracks = videoRef.current.srcObject.getTracks();
          tracks.forEach((track) => {
            try {
              track.stop();
            } catch (e) {
              console.warn("Erro ao parar track:", e);
            }
          });
          videoRef.current.srcObject = null;
        }

        console.log("Aguardando liberação de recursos...");
        await new Promise((resolve) => setTimeout(resolve, 500));

        console.log("Criando novo scanner com câmera:", cameraId);

        let success = false;
        let retryCount = 0;
        const maxRetries = 3;

        while (!success && retryCount < maxRetries) {
          try {
            scannerRef.current = new QrScanner(
              videoRef.current,
              (result) => {
                if (!processing) {
                  handleQrCodeDetected(result.data);
                }
              },
              {
                preferredCamera: cameraId,
                highlightScanRegion: false,
                highlightCodeOutline: true,
                maxScansPerSecond: 5,
                returnDetailedScanResult: true,
                calculateScanRegion: (v) => {
                  const min = Math.min(v.videoWidth, v.videoHeight);
                  const size = Math.floor(min * 0.6);
                  return {
                    x: Math.floor((v.videoWidth - size) / 2),
                    y: Math.floor((v.videoHeight - size) / 2),
                    width: size,
                    height: size,
                  };
                },
              }
            );

            console.log("Iniciando scanner...");
            await scannerRef.current.start();

            success = true;
            console.log("Scanner iniciado com sucesso!");

            resetScanAttempts();

            setSelectedCamera(cameraId);

            const camera = cameras.find((cam) => cam.id === cameraId);
            if (camera) {
              showCameraChangeToast(camera.label);
            }

            try {
              const hasFlashSupport = await scannerRef.current.hasFlash();
              setHasFlash(hasFlashSupport);
              setFlashOn(false);
            } catch (e) {
              console.log("Flash não suportado nesta câmera");
              setHasFlash(false);
            }

            const videoCheckInterval = setInterval(() => {
              if (
                videoRef.current &&
                videoRef.current.videoWidth > 0 &&
                videoRef.current.videoHeight > 0
              ) {
                console.log(
                  "Stream de vídeo confirmado:",
                  videoRef.current.videoWidth,
                  "x",
                  videoRef.current.videoHeight
                );
                clearInterval(videoCheckInterval);
                setIsLoading(false);
              }
            }, 250);

            setTimeout(() => {
              clearInterval(videoCheckInterval);
              if (isLoading) setIsLoading(false);
            }, 5000);
          } catch (error) {
            console.error(`Tentativa ${retryCount + 1} falhou:`, error);
            retryCount++;

            await new Promise((resolve) => setTimeout(resolve, 500));

            if (retryCount === maxRetries) {
              throw error;
            }
          }
        }
      } catch (error) {
        console.error("Erro fatal ao trocar câmera:", error);
        setScanError("Erro ao trocar câmera. Tente novamente.");

        try {
          if (selectedCamera) {
            console.log("Tentando restaurar câmera anterior:", selectedCamera);
            scannerRef.current = new QrScanner(
              videoRef.current,
              (result) => {
                if (!processing) {
                  handleQrCodeDetected(result.data);
                }
              },
              {
                preferredCamera: selectedCamera,
                highlightScanRegion: false,
                highlightCodeOutline: true,
                maxScansPerSecond: 5,
              }
            );

            await scannerRef.current.start();
          }
        } catch (e) {
          console.error("Não foi possível restaurar a câmera anterior:", e);
        } finally {
          setIsLoading(false);
        }
      }
    },
    [
      cameras,
      handleQrCodeDetected,
      isLoading,
      processing,
      selectedCamera,
      resetScanAttempts,
    ]
  );

  const switchToNextCamera = useCallback(() => {
    if (cameras.length <= 1 || isLoading || processing) {
      return;
    }

    const currentIndex = cameras.findIndex(
      (camera) => camera.id === selectedCamera
    );
    const nextIndex = (currentIndex + 1) % cameras.length;
    switchCamera(cameras[nextIndex].id);
  }, [cameras, isLoading, processing, selectedCamera, switchCamera]);

  useEffect(() => {
    switchToNextCameraRef.current = switchToNextCamera;
  }, [switchToNextCamera]);

  const cameraListContent = (
    <div className="camera-selector-popover" style={{ maxWidth: "250px" }}>
      <div className="p-2 border-b border-gray-200 mb-2 text-gray-600 text-xs uppercase font-medium">
        Selecione uma câmera
      </div>
      {cameras.map((camera) => (
        <div
          key={camera.id}
          onClick={() => {
            switchCamera(camera.id);
            setPopoverOpen(false);
          }}
          className={`p-2 rounded cursor-pointer mb-1 flex items-center ${
            selectedCamera === camera.id
              ? "bg-[#ff6e30] text-white"
              : "text-gray-800 hover:bg-gray-100"
          }`}
        >
          <IoCamera className="mr-2" />
          <div>
            <div>{camera.label}</div>
          </div>
          {selectedCamera === camera.id && (
            <div className="ml-auto flex-shrink-0 h-2 w-2 rounded-full bg-green-500"></div>
          )}
        </div>
      ))}
    </div>
  );

  const httpsWarning =
    window.location.protocol !== "https:" &&
    window.location.hostname !== "localhost" &&
    window.location.hostname !== "127.0.0.1";

  const CameraHint = memo(({ show, cameras, onSwitchCamera, onReset }) => {
    if (!show) return null;

    const hasManyCamera = cameras.length > 1;
    const handleButtonClick = hasManyCamera ? onSwitchCamera : onReset;

    return (
      <div className="absolute inset-x-0 bottom-20 z-40 px-4 pointer-events-none">
        <div className="bg-amber-600/90 text-white rounded-lg p-3 shadow-lg pointer-events-auto">
          <div className="flex items-start space-x-2 mb-2">
            <AiOutlineWarning className="text-amber-200 mt-0.5" size={20} />
            <div className="flex-1">
              <p className="font-medium text-sm">
                Estamos tendo dificuldades para ler o QR Code
              </p>
            </div>
          </div>

          <div className="text-xs space-y-2 text-amber-50">
            <p>Sugestões:</p>
            <ul className="list-disc pl-4 space-y-1">
              <li>Certifique-se que o QR code está bem iluminado</li>
              <li>Evite reflexos e sombras sobre o código</li>
              <li>Mantenha o dispositivo estável</li>
              <li>Certifique-se que o QR code esteja limpo e sem danos</li>
              {cameras.length > 1 && <li>Tente usar outra câmera</li>}
            </ul>
          </div>

          <button
            onClick={handleButtonClick}
            className="w-full mt-4 bg-white text-amber-700 font-medium rounded py-2 px-3 text-sm flex items-center justify-center"
          >
            <IoReload className="mr-2" size={16} />
            Tentar Novamente
          </button>
        </div>
      </div>
    );
  });

  return (
    <>
      <Header />
      <div className="flex flex-col items-center justify-start min-h-screen text-white p-4 relative">
        {httpsWarning && (
          <div className="w-full max-w-md bg-red-500/80 text-white p-3 rounded-md mb-4 text-sm text-center">
            <AiOutlineWarning className="inline-block mr-1" />O acesso à câmera
            requer HTTPS. Use localhost ou https para acesso completo.
          </div>
        )}

        <div className="w-full max-w-md mb-4">
          <div className="flex justify-between items-center">
            <div className="text-xs text-gray-300 flex items-center">
              <IoCamera className="mr-1" />
              {selectedCamera && cameras.length > 0
                ? cameras.find((c) => c.id === selectedCamera)?.label ||
                  "Câmera"
                : "Carregando câmera..."}
            </div>

            {cameras.length > 1 && !processing && !isLoading && (
              <div className="flex space-x-2">
                <button
                  className="p-2 rounded-full bg-[#ff6e30] text-white hover:bg-[#e05a20] transition-colors flex items-center"
                  aria-label="Trocar para próxima câmera"
                  onClick={switchToNextCamera}
                  disabled={processing || isLoading}
                >
                  <IoCameraReverse size={20} />
                </button>

                <Popover
                  content={cameraListContent}
                  trigger="click"
                  open={popoverOpen}
                  onOpenChange={setPopoverOpen}
                  placement="bottomRight"
                  overlayStyle={{ zIndex: 1050 }}
                  overlayInnerStyle={{
                    backgroundColor: "white",
                    borderRadius: "8px",
                    padding: "8px",
                    boxShadow:
                      "0 3px 6px -4px rgba(0,0,0,.12), 0 6px 16px 0 rgba(0,0,0,.08)",
                  }}
                >
                  <Tooltip title="Ver todas as câmeras" placement="top">
                    <button
                      className="p-2 rounded-full bg-gray-700 text-white hover:bg-gray-600 transition-colors"
                      aria-label="Lista de câmeras"
                      disabled={processing || isLoading}
                    >
                      <MdCameraswitch size={18} />
                    </button>
                  </Tooltip>
                </Popover>
              </div>
            )}
          </div>
        </div>

        <div
          className={`relative w-full max-w-md overflow-hidden rounded-lg shadow-lg mb-4 ${
            processing ? "opacity-70" : ""
          }`}
          style={{
            aspectRatio: "1/1",
            maxHeight: "350px",
            backgroundColor: "black",
          }}
        >
          <video
            ref={videoRef}
            id="qr-video"
            className="h-full w-full rounded-lg"
            style={{
              objectFit: "cover",
              width: "100%",
              height: "100%",
              display: "block",
            }}
            autoPlay
            playsInline
            muted
            disablePictureInPicture
          />

          <ScanFrame processing={processing} scanError={scanError} />

          <CameraHint
            show={showCameraHint && !isLoading && !processing && !scanError}
            cameras={cameras}
            onSwitchCamera={switchToNextCamera}
            onReset={resetScanner}
          />

          {(processing || isLoading) && (
            <div className="absolute inset-0 z-30 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white text-gray-800 rounded-lg p-4 flex flex-col items-center max-w-[80%]">
                <BiLoaderAlt
                  className="text-[#ff6e30] mb-2 animate-spin"
                  size={32}
                />
                <p className="text-sm font-medium text-center">
                  {isLoading ? "Iniciando câmera..." : "Processando QR Code..."}
                </p>

                {hasFlash && !isLoading && (
                  <button
                    onClick={toggleFlash}
                    className="mt-3 bg-gray-200 text-gray-800 text-xs rounded-md px-3 py-1 flex items-center"
                  >
                    <svg
                      className="w-4 h-4 mr-1"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M7 2v11h3v9l7-12h-4l4-8z"></path>
                    </svg>
                    {flashOn ? "Desligar Lanterna" : "Ligar Lanterna"}
                  </button>
                )}
              </div>
            </div>
          )}
        </div>

        <StatusMessage
          isLoading={isLoading}
          processing={processing}
          scanError={scanError}
          t={t}
          hasFlash={hasFlash}
          onToggleFlash={toggleFlash}
          flashOn={flashOn}
          onReset={resetScanner}
        />

        <style>{`
          @keyframes scanAnimation {
            0% {
              top: 20%;
            }
            50% {
              top: 80%;
            }
            100% {
              top: 20%;
            }
          }

          .opacity-0 {
            opacity: 0;
          }
        `}</style>
      </div>
    </>
  );
};

export default QRScanner;
