import React from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import styled from "styled-components";

export const StyledPhoneInput = styled(PhoneInput)`
  display: flex;
  align-items: center;
  height: 50px;
  border-radius: 8px;
  border: 2px solid rgb(230, 232, 236);
  padding: 0 1rem;
  background-color: #fff;
  width: 100%;

  transition: border-color 0.3s ease, background-color 0.3s ease;

  @media screen and (min-width: 480px) {
    max-width: 344px;
  }

  &:hover {
    border-color: rgb(246, 126, 72);
    background-color: #fef3f0;
  }

  &:focus-within {
    border-color: rgb(246, 126, 72);
    background-color: #fff;
  }

  .PhoneInputCountry {
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
  }

  .PhoneInputCountrySelect {
    border: none;
    background: transparent;
    font-size: 1rem;
    cursor: pointer;
    outline: none;
    height: 100%;
    display: flex;
    align-items: center;
    color: rgb(246, 126, 72);
    font-weight: bold;

    &:hover {
      color: #e15d3c;
    }
  }

  .PhoneInputInput {
    flex: 1;
    border: none;
    outline: none;
    font-size: 1rem;
    height: 100%;
    background: transparent;
    color: #333;

    &::placeholder {
      color: #aaa;
    }
  }
`;

export const PhoneNumberFieldEdit = ({ value, onChange }) => {
  return (
    <StyledPhoneInput
      placeholder="Insira o número de telefone"
      defaultCountry="BR"
      value={value || ""}
      onChange={onChange}
      international
      withCountryCallingCode
      numberInputProps={{
        className: "PhoneInputInput",
        "aria-label": "Phone number",
      }}
    />
  );
};
