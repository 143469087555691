import {
  parsePhoneNumberFromString,
  getCountryCallingCode,
  getCountries,
} from "libphonenumber-js";

const detectCountryFromNumber = (phoneNumber) => {
  for (const country of getCountries()) {
    if (phoneNumber.startsWith(getCountryCallingCode(country))) {
      return country;
    }
  }
  return null;
};

export const formatUserPhoneForDisplay = (phoneNumber) => {
  if (!phoneNumber || typeof phoneNumber !== "string") {
    return "";
  }

  let userCountry = detectCountryFromNumber(phoneNumber);

  if (!userCountry) {
    userCountry = "US";
  }

  const parsed = parsePhoneNumberFromString(phoneNumber, userCountry);

  if (!parsed) {
    console.log("Número inválido ou não pôde ser processado:", phoneNumber);
    return phoneNumber;
  }

  return parsed.formatInternational();
};
