import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { useAPIPet, useAPITag } from "../../../services/api";
import { useDispatch, useSelector } from "react-redux";
import {
  saveState,
  setCode,
  setConfig,
  setPhone,
  setWhatsappStatus,
} from "../RegisterPetSlice";
import { getUser, isLoggedIn } from "../../../app/store/authSlice";

const AuthPhoneApiPre = () => {
  const tagApi = useAPITag();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLogged = useSelector(isLoggedIn);
  const user = useSelector(getUser);

  const AuthPhone = async (data) => {
    try {
      const countryCode = data.userPhone.slice(0, 3);
      const phone = data.userPhone.slice(3);

      dispatch(
        setPhone({
          phone: phone,
          maskedPhone: data.userPhone,
          grantType: data.grantType,
          countryCode: countryCode,
        })
      );

      if (data.grantType === "whatsapp") {
        const whatsappCodeDTO = {
          grantType: "whatsapp",
          tokenType: "auth-code",
          countryCode: countryCode,
          phone: phone,
          userId: phone,
        };

        await tagApi.http.post("/user-token/whatsapp", whatsappCodeDTO);

        dispatch(saveState());
        navigate(`/confirm-code/${id}`);
      } else {
        await tagApi.http.post("/usuario/verify", {
          phone: countryCode + phone,
        });

        dispatch(saveState());
        navigate(`/confirm-code/${id}`);
      }
    } catch {
      console.error("Erro no AuthPhone:", error);
    }
  };

  const ValidateTag = async (code) => {
    dispatch(setCode({ code }));
    try {
      const perfilResponse = await tagApi.http.get("tags/" + code);

      if (perfilResponse.data.hasPet) {
        navigate("/profile/" + code + "?scanned=true");
        return;
      }

      if (window.location.hostname === "6e.ae") {
        window.location.href = "https://ajuda.pet/6e" + code;
      }

      dispatch(setConfig(perfilResponse.data.config));

      // Implementar a rota de health check para o whatsapp
      dispatch(setWhatsappStatus({ status: true }));

      if (isLogged) {
        const userPhone = (user?.telefone || user?.phone)
          ?.replace(/\D/g, "")
          .trim();
        const tagPhone = perfilResponse.data.tag.userPhone
          ?.replace(/\D/g, "")
          .trim();

        if (!userPhone) {
          Swal.fire({
            title: "Ops, algo deu errado!",
            text: "Parece que você ainda não registrou um número de telefone. Por favor, atualize suas informações no perfil.",
            icon: "warning",
            confirmButtonText: "Entendido",
          });
          navigate("/");
          return;
        }

        if (userPhone !== tagPhone) {
          Swal.fire({
            title: "Acesso negado!",
            text: "Essa tag pertence a outro usuário. Por favor, verifique os dados ou entre em contato com o suporte se achar que houve um erro.",
            icon: "error",
            confirmButtonText: "Fechar",
            confirmButtonColor: "#FF6B6B",
          });
          navigate("/");
          return;
        }

        const petsResponse = await tagApi.http.get("pets/user/" + user._id);

        if (petsResponse.data.length > 0) {
          navigate("/select-pet");
          return;
        }

        navigate("/register-pet");
      }
    } catch (error) {
      console.log("ValidateTag error", error);
      Swal.fire({
        title: "Ocorreu um erro",
        text: "Não conseguimos validar a tag. Tente novamente mais tarde.",
        icon: "error",
        confirmButtonText: "Ok",
        confirmButtonColor: "#FF6B6B",
      });
    }
  };

  const ValidatePreTag = async (code) => {
    dispatch(setCode({ code }));
    try {
      const perfilResponse = await tagApi.http.get("tags/" + code);
      if (
        perfilResponse.data.tag.statusQr === "pending" &&
        (perfilResponse.data.tag.messageStatusQr === "simp1" ||
          perfilResponse.data.tag.messageStatusQr === "simp2" ||
          perfilResponse.data.tag.messageStatusQr === "ok" ||
          perfilResponse.data.tag.messageStatusQr === "")
      ) {
        navigate(`/finalRegister/${code}`);
        return;
      }
    } catch {
      console.log("ValidateTag error");
    }
  };

  return { ValidateTag, AuthPhone, ValidatePreTag };
};

export default AuthPhoneApiPre;
