import * as S from "./styles";
import { Col, DatePicker, Form, Modal, Row, Switch, Typography  } from "antd";
import Swal from "sweetalert2";
import { useCallback, useState } from "react";
import StyledDropzone from "../styledDropzone";
import RegisterPetAPI from "./RegisterPetAPI";
import { NumericFormat } from 'react-number-format';
import {
  petFeatures,
  racasCachorro,
  racasGato,
  tiposSaude,
} from "./RegisterPetMock";
import TextArea from "antd/es/input/TextArea";
import { useTranslation } from 'react-i18next';

export const ModalRegisterPet = (props) => {
  const { t } = useTranslation();
  const { Text } = Typography;
  const [form] = Form.useForm();
  const { createPet } = RegisterPetAPI();
  const [state, updateState] = useState();
  const [photos, setPhotos] = useState([]);
  const forceUpdate = useCallback(() => updateState({}), []);
  const [petSpecie, setPetSpecie] = useState("dog");
  const onFinish = (values) => {
    const { features, ...data } = values;

    // if (photos.length === 0) {
    //   Swal.fire({
    //     title: "Adicione pelo menos um foto do seu Pet",
    //     icon: "error",
    //   });
    //   return;
    // }

    data["fotos"] = photos;
    data["avatar"] = photos[0];
    data.dataNascimento = values.birthDate.toISOString();
    if (features) {
      features.forEach((e) => {
        data[e] = 5;
      });
    }

    data.qrCodes = [];

    createPet(data);
    props.cancel();
  };
  
  const onFinishFailed = () => {
    Swal.fire({
      title: "Preencha todos os campos",
      icon: "error",
    });
  };

  const onChange = (e) => {
    if (e.species && e.species !== petSpecie) {
      form.setFieldValue("breed", null);
    }
    forceUpdate();
  };

  const formatCurrency = (value) => {
    if (typeof value !== 'number') {
        return '';
    }
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 0, maximumFractionDigits: 0 });
};

  return (
    <Modal
    onCancel={props.cancel}
    open={props.open}
    onOk={() => {
      form.submit();
    }}
    okText={t('save')}
      okButtonProps={{ style: { backgroundColor: '#f67e48' } }}
    >
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 24 }}
        labelAlign="top"
        labelWrap
        wrapperCol={{ flex: 1 }}
        colon={false}
        onValuesChange={onChange}
        style={{ width: "100%" }}
        initialValues={{ remember: true }}
        onFinishFailed={onFinishFailed}
        onFinish={onFinish}
        autoComplete="off"
        className="w-full flex flex-col"
      >
        <StyledDropzone
          setValue={setPhotos}
          value={photos}
          label={t('clickToAddPetPhotos')}
        ></StyledDropzone>
        <Form.Item
          name="nome"
          label={t('name')}
          rules={[{ required: true, message: t('enterPetName') }]}
        >
          <S.StyledInput />
        </Form.Item>
        <Form.Item
          name="especie"
          label={t('species')}
          initialValue="dog"
          rules={[{ required: true, message: t('enterPetSpecies') }]}
        >
          <S.StyledSelect
            initialValue="dog"
            onChange={(event) => setPetSpecie(event)}
            options={[
              { value: "dog", label: t('dog') },
              { value: "cat", label: t('cat') },
            ]}
          />
        </Form.Item>

          <Form.Item
            name="raca"
            label={t('breed')}
            rules={[{ required: true, message: t('enterPetBreed') }]}
          >
            <S.StyledSelect options={petSpecie === 'dog' ? racasCachorro : racasGato} />
          </Form.Item>
        
        <Form.Item
          name="genero"
          label={t('gender')}
          rules={[{ required: true, message: t('enterPetGender') }]}
        >
          <S.StyledSelect
            initialValue="female"
            options={[
              { value: "fêmea", label: t('female') },
              { value: "macho", label: t('male') },
            ]}
          />
        </Form.Item>

        <Form.Item initialValue="tudoCerto" name="saude" label={t('healthStatus')}>
          <S.StyledSelect defaultValue="tudoCerto" options={tiposSaude} />
        </Form.Item>

        <Form.Item
          initialValue=""
          name="corPelo"
          label={t('furColor')}
          rules={[{ required: true, message: t('enterFurColor') }]}
        >
          <S.StyledInput defaultValue="" />

        </Form.Item>
        <Col style={{ marginBottom: '10px' }} span={24}>
          <Row gutter={16}>
            <Col span={24}>
              <Text>{t('petWeight')}</Text>
            </Col>
          </Row>
          <Row gutter={16}>
              <Col span={32}>
                <Text type="secondary">{t('exampleWeight')}</Text>
              </Col>
          </Row>

        </Col>
        <Form.Item initialValue={0} name="peso">
          <NumericFormat className="w-full px-2 border h-[38px] rounded-[8px] hover:border hover:border-[#f67e48] transition ease-out delay-100 outline-none focus:border-[#f67e48]" maxLength={6} value="20020220" allowLeadingZeros thousandSeparator=","/>
        </Form.Item>

        <Form.Item name="features" label={t('characteristics')}>
          <S.StyledSelect mode="multiple"maxTagCount={undefined} allowClear options={petFeatures} />
        </Form.Item>
        <Form.Item
          name="birthDate"
          label={t('birthDate')}
          rules={[{ required: true, message: t('enterBirthDate') }]}
        >
          <DatePicker
            format={"DD/MM/YYYY"}
            placeholder={t('selectBirthDate')}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item initialValue={false} name="tail" label={t('hasTail')}>
          <Switch 
            className="bg-gray-400"
          />
        </Form.Item>
        <Form.Item
          defaultValue={false}
          name="adoption"
          label={t('availableForAdoption')}
        >
          <Switch 
            className="bg-gray-400"
          />
        </Form.Item>
        <Form.Item initialValue={false} name="chip" label={t('hasChip')}>
          <Switch 
            className="bg-gray-400"
          />
        </Form.Item>
        {form.getFieldValue("chip") && (
          <Form.Item initialValue={""} name="chipN" label={t('chipNumber')}>
            <S.StyledInput />
          </Form.Item>
        )}
        <Form.Item initialValue={false} name="rescued" label={t('rescued')}>
          <Switch 
            className="bg-gray-400"
          />
        </Form.Item>
        
        <Form.Item initialValue={false} name="castrated" label={t('neutered')}>
          <Switch 
            className="bg-gray-400"
          />
        </Form.Item>

        {form.getFieldValue("rescued") && (
          <Form.Item
            defaultValue={""}
            name="rescueLocale"
            label={t('rescueLocation')}
          >
            <S.StyledInput />
          </Form.Item>
        )}
        <Form.Item initialValue={""} name="notes" label={t('observations')}>
          <TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
