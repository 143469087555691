import React, { useEffect, useCallback, useState, useRef } from "react";
import { useAPITag } from "../../services/api";
import ImageIcon from "../Icons/ImageIcon";
import {
  Modal,
  Slider,
  Button,
  Progress,
  notification,
  Col,
  Popover,
} from "antd";
import Cropper from "react-easy-crop";
import { useTranslation } from "react-i18next";
import {
  ZoomInOutlined,
  ZoomOutOutlined,
  CameraOutlined,
  FileImageOutlined,
} from "@ant-design/icons";
import * as S from "./styles";
import { useSelector } from "react-redux";
import { selectAuthState } from "../../features/admin/login/loginSlice";
import { getUser } from "../../app/store/authSlice";
import {
  generateRandomString,
  generateUniqueFileName,
} from "../../utils/fileNameUtils";

function StyledDropzone({ setValue, value, label, defaultFile, ...props }) {
  const { t } = useTranslation();
  const [finished, setFinished] = useState(false);
  const authState = useSelector(selectAuthState);
  const user = useSelector(getUser);
  const { http } = useAPITag();

  const [paths, setPaths] = useState(defaultFile ? [defaultFile] : []);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [optimizedImageUrl, setOptimizedImageUrl] = useState("");
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1.3);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [isCameraSupported, setIsCameraSupported] = useState(false);

  const isDraggingSliderRef = useRef(false);
  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    const checkCameraSupport = async () => {
      try {
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
          setIsCameraSupported(false);
          return;
        }

        const devices = await navigator.mediaDevices.enumerateDevices();
        const hasCamera = devices.some(
          (device) => device.kind === "videoinput"
        );
        setIsCameraSupported(hasCamera);
      } catch (error) {
        console.error("Erro ao verificar suporte à câmera:", error);
        setIsCameraSupported(false);
      }
    };

    checkCameraSupport();
  }, []);

  useEffect(() => {
    return () => {
      if (imageUrl) URL.revokeObjectURL(imageUrl);
      if (optimizedImageUrl) URL.revokeObjectURL(optimizedImageUrl);
    };
  }, [imageUrl, optimizedImageUrl]);

  useEffect(() => {
    if (defaultFile) {
      setPaths([defaultFile]);
    }
  }, [defaultFile]);

  useEffect(() => {
    console.log("StyledDropzone Auth Context:", {
      adminAuth: authState?.user ? "Logged In" : "Not Logged In",
      customerAuth: user ? "Logged In" : "Not Logged In",
      adminId: authState?.user?._id,
      customerId: user?._id,
    });
  }, [authState, user]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleZoomChange = useCallback(
    (newZoom) => {
      if (newZoom === zoom) return;
      setZoom(newZoom);
    },
    [zoom]
  );

  const zoomSliderProps = {
    min: 1,
    max: 3,
    step: 0.01,
    value: zoom,
    onChange: handleZoomChange,
    style: { flex: 1 },
    trackStyle: { backgroundColor: "#f67e48" },
    handleStyle: { borderColor: "#f67e48" },
    onBeforeChange: () => {
      isDraggingSliderRef.current = true;
    },
    onAfterChange: () => {
      isDraggingSliderRef.current = false;
    },
  };

  const NOTIFY_ID = useRef(generateRandomString(16)).current;

  const openNotification = useCallback(
    (totalFiles, filesCount, currentFileName, percentage) => {
      notification.open({
        key: NOTIFY_ID,
        message:
          t("uploadingFiles", "Arquivos sendo enviados") +
          ` ${filesCount} / ${totalFiles}`,
        description: (
          <Col>
            <p>{t("total", "Total")}</p>
            <Progress percent={Math.floor((filesCount / totalFiles) * 100)} />
          </Col>
        ),
      });
    },
    [NOTIFY_ID, t]
  );

  const optimizeImage = useCallback((file) => {
    return new Promise((resolve, reject) => {
      const tempUrl = URL.createObjectURL(file);

      const img = new Image();
      img.onload = () => {
        const MAX_WIDTH = 1200;
        const MAX_HEIGHT = 1200;

        let width = img.width;
        let height = img.height;

        if (width > MAX_WIDTH || height > MAX_HEIGHT) {
          const ratio = Math.min(MAX_WIDTH / width, MAX_HEIGHT / height);
          width = Math.floor(width * ratio);
          height = Math.floor(height * ratio);
        }

        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            const optimizedUrl = URL.createObjectURL(blob);
            URL.revokeObjectURL(tempUrl);
            resolve(optimizedUrl);
          },
          "image/jpeg",
          0.85
        );
      };

      img.onerror = () => {
        URL.revokeObjectURL(tempUrl);
        reject(new Error("Erro ao carregar a imagem"));
      };

      img.src = tempUrl;
    });
  }, []);

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.src = url;
    });

  const getCroppedImg = async (imageSrc, pixelCrop) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const maxDimension = 2000;
    let outputWidth = pixelCrop.width;
    let outputHeight = pixelCrop.height;

    if (outputWidth > maxDimension || outputHeight > maxDimension) {
      const scale = maxDimension / Math.max(outputWidth, outputHeight);
      outputWidth = Math.floor(outputWidth * scale);
      outputHeight = Math.floor(outputHeight * scale);
    }

    canvas.width = outputWidth;
    canvas.height = outputHeight;

    ctx.fillStyle = "#ffffff";
    ctx.fillRect(0, 0, outputWidth, outputHeight);

    const scale = Math.min(
      outputWidth / pixelCrop.width,
      outputHeight / pixelCrop.height
    );

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      outputWidth,
      outputHeight
    );

    return canvas;
  };

  const uploadFilePromise = useCallback(
    (canvas, index, totalFiles, name) => {
      return new Promise((resolve, reject) => {
        canvas.toBlob(
          (blob) => {
            const uniqueFileName = generateUniqueFileName(name);

            let companyId = "ajudapet";
            if (authState?.user?._id) {
              companyId = authState.user._id;
            } else if (user?._id) {
              companyId = user._id;
            }

            console.log("Usando companyId:", companyId);

            const bodyFormData = new FormData();
            bodyFormData.append("files", blob, uniqueFileName);
            bodyFormData.append("companyId", companyId);
            bodyFormData.append("filename", uniqueFileName);

            http
              .post("/files", bodyFormData, {
                headers: { "Content-Type": "multipart/form-data" },
                onUploadProgress: (progressEvent) => {
                  const percentage = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  setUploadProgress(percentage);
                  openNotification(
                    totalFiles,
                    index,
                    uniqueFileName,
                    percentage
                  );
                },
              })
              .then((response) => {
                const newFile = response.data;

                if (props.onChange) {
                  props.onChange([newFile]);
                }

                setFinished(true);
                setTimeout(() => setFinished(false), 2000);

                resolve(response);
              })
              .catch((error) => {
                console.error("Erro no upload:", error);
                reject(error);
              });
          },
          "image/jpeg",
          0.92
        );
      });
    },
    [http, openNotification, props, authState, user]
  );

  const onChangeFiles = useCallback(
    async (file) => {
      if (file) {
        if (file.type.startsWith("image/")) {
          try {
            setSelectedImage(file);

            const url = URL.createObjectURL(file);
            setImageUrl(url);

            const optimizedUrl = await optimizeImage(file);
            setOptimizedImageUrl(optimizedUrl);

            setModalVisible(true);
          } catch (error) {
            console.error("Erro ao processar imagem:", error);
            notification.error({
              message: t("error", "Erro"),
              description: t(
                "imageProcessingError",
                "Erro ao processar a imagem"
              ),
              placement: "bottom",
            });
          }
        } else {
          setSelectedImage(null);
          notification.error({
            message: t("error", "Erro"),
            description: t(
              "invalidImageType",
              "Tipo de arquivo inválido. Selecione uma imagem."
            ),
            placement: "bottom",
          });
        }
      }
    },
    [t, optimizeImage]
  );

  const handleFileChange = useCallback(
    (e) => {
      if (e.target.files && e.target.files[0]) {
        onChangeFiles(e.target.files[0]);
      }
    },
    [onChangeFiles]
  );

  const handleModalOk = async () => {
    if (!selectedImage || !croppedAreaPixels) return;

    setIsUploading(true);
    try {
      const sourceUrl = optimizedImageUrl || imageUrl;
      const canvas = await getCroppedImg(sourceUrl, croppedAreaPixels, 0);

      const response = await uploadFilePromise(
        canvas,
        1,
        1,
        selectedImage.name || "image.jpg"
      );

      // notification.success({
      //   message: t("success", "Sucesso"),
      //   description: t(
      //     "photoUpdatedSuccessfully",
      //     "Foto atualizada com sucesso"
      //   ),
      //   placement: "bottom",
      // });

      if (response && response.data && response.data.url) {
        setPaths([response.data.url]);

        if (setValue) {
          setValue([response.data.url]);
        }

        setSelectedImage(null);
        setImageUrl("");
        setOptimizedImageUrl("");
        setModalVisible(false);
      } else {
        throw new Error("URL da imagem não encontrada na resposta");
      }
    } catch (error) {
      console.error(error);
      notification.error({
        message: t("error", "Erro"),
        description: t("uploadError", "Erro ao enviar a imagem"),
        placement: "bottom",
      });
    } finally {
      setIsUploading(false);
      setUploadProgress(0);
    }
  };

  const handleModalCancel = () => {
    setModalVisible(false);
    setSelectedImage(null);
    setImageUrl("");
    setOptimizedImageUrl("");
    setCrop({ x: 0, y: 0 });
    setZoom(1.3);
  };

  const handlePopoverVisibleChange = (visible) => {
    setPopoverVisible(visible);
  };

  const handleCaptureFromCamera = useCallback(() => {
    const newInput = document.createElement("input");
    newInput.type = "file";
    newInput.accept = "image/*";
    newInput.setAttribute("capture", "environment");
    newInput.style.display = "none";

    const handleChange = (e) => {
      if (e.target.files && e.target.files[0]) {
        onChangeFiles(e.target.files[0]);
      }
      document.body.removeChild(newInput);
    };

    newInput.addEventListener("change", handleChange);
    document.body.appendChild(newInput);

    setTimeout(() => {
      newInput.click();
    }, 100);

    setPopoverVisible(false);
  }, [onChangeFiles]);

  const handleSelectFromGallery = useCallback(() => {
    const newInput = document.createElement("input");
    newInput.type = "file";
    newInput.accept = "image/*";
    newInput.style.display = "none";

    const handleChange = (e) => {
      if (e.target.files && e.target.files[0]) {
        onChangeFiles(e.target.files[0]);
      }
      document.body.removeChild(newInput);
    };

    newInput.addEventListener("change", handleChange);
    document.body.appendChild(newInput);

    setTimeout(() => {
      newInput.click();
    }, 100);

    setPopoverVisible(false);
  }, [onChangeFiles]);

  const handleContainerClick = useCallback(() => {
    setPopoverVisible(true);
  }, []);

  const popoverContent = (
    <S.PopoverButtonsContainer>
      {isCameraSupported && (
        <Button
          icon={<CameraOutlined />}
          onClick={handleCaptureFromCamera}
          style={{
            height: isMobile ? "40px" : "36px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <span style={{ marginLeft: "8px" }}>
            {t("takePhoto", "Tirar foto")}
          </span>
        </Button>
      )}

      <Button
        icon={<FileImageOutlined />}
        onClick={handleSelectFromGallery}
        style={{
          height: isMobile ? "40px" : "36px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <span style={{ marginLeft: "8px" }}>
          {t("chooseFromGallery", "Escolher da galeria")}
        </span>
      </Button>
    </S.PopoverButtonsContainer>
  );

  return (
    <div className="mb-6">
      <Popover
        content={popoverContent}
        title={t("selectImageSource", "Selecionar origem da imagem")}
        trigger="click"
        open={popoverVisible}
        onOpenChange={handlePopoverVisibleChange}
        placement="bottom"
        overlayStyle={{
          width: isMobile ? "220px" : "250px",
        }}
        destroyTooltipOnHide
      >
        <S.Container
          onClick={handleContainerClick}
          className={finished ? "success" : ""}
          style={props.style}
        >
          {paths.length === 0 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <ImageIcon width={40} height={30} />
            </div>
          ) : (
            <S.ImageContainer>
              <img src={paths[0]} alt="Upload preview" />
            </S.ImageContainer>
          )}
        </S.Container>
      </Popover>

      {label && <S.LabelText>{label}</S.LabelText>}

      <Modal
        title={t("adjustPhoto", "Ajustar foto")}
        open={modalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        okText={t("save", "Salvar")}
        okButtonProps={{
          disabled: !selectedImage || isUploading,
          style: {
            backgroundColor: selectedImage && !isUploading ? "#f67e48" : "gray",
            height: isMobile ? "44px" : "32px",
            fontSize: isMobile ? "16px" : "14px",
          },
        }}
        cancelButtonProps={{
          style: {
            height: isMobile ? "44px" : "32px",
            fontSize: isMobile ? "16px" : "14px",
          },
        }}
        width={isMobile ? "94vw" : 400}
        centered
        maskClosable={false}
        destroyOnClose={true}
      >
        {selectedImage && (optimizedImageUrl || imageUrl) ? (
          <div>
            <S.CropContainer>
              <Cropper
                image={optimizedImageUrl || imageUrl}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={
                  isDraggingSliderRef.current ? undefined : handleZoomChange
                }
                cropShape="rect"
                showGrid={true}
                disableRotation={true}
              />

              {isUploading && (
                <S.UploadOverlay>
                  <Progress
                    type="circle"
                    percent={uploadProgress}
                    width={80}
                    strokeColor="#f67e48"
                  />
                  <p style={{ marginTop: 15, color: "#333", fontSize: "16px" }}>
                    {t("uploading", "Enviando")}...
                  </p>
                </S.UploadOverlay>
              )}
            </S.CropContainer>

            <S.SliderContainer>
              <S.SliderRow>
                <S.SliderIcon>
                  <ZoomOutOutlined />
                </S.SliderIcon>
                <Slider {...zoomSliderProps} />
                <S.SliderIcon $left>
                  <ZoomInOutlined />
                </S.SliderIcon>
              </S.SliderRow>
            </S.SliderContainer>

            <S.ModalInstructions>
              {t("dragAndPinchToAdjust", "Arraste e redimensione para ajustar")}
            </S.ModalInstructions>
          </div>
        ) : (
          <S.EmptyModal>
            {t("selectImageFirst", "Selecione uma imagem primeiro")}
          </S.EmptyModal>
        )}
      </Modal>

      <input
        type="file"
        onChange={handleFileChange}
        accept="image/*"
        style={{ display: "none" }}
        {...props}
      />
    </div>
  );
}

export default StyledDropzone;
