import * as S from './styles';
import { Form, Input, Space, Spin } from 'antd';
import Swal from 'sweetalert2';
import { useAPITag } from '../../../services/api';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import RegisterPetLayout from '../../../components/layouts/RegisterPetLayout';
import { useSelector } from 'react-redux';
import { getWhatsappStatus, selectRegisterstate } from '../RegisterPetSlice';
import { WhatsAppOutlined } from '@ant-design/icons';
import Modal from './Modal';
import { PhoneNumberField } from './PhoneNumberField';
import AuthPhoneApiPre from './AuthPhoneApiPre';
import { formatUserPhoneForDisplay } from '../../../utils/formatUserPhoneForDisplay';

export const FinalRegister = () => {
  const { id } = useParams();
  const { http } = useAPITag();
  const { ValidateTag, AuthPhone } = AuthPhoneApiPre();

  const [form] = Form.useForm();

  const [inputDisabled, setInputDisabled] = useState(true);
  const [whatsAppConfirmModalVisible, setwhatsAppConfirmModalVisible] =
    useState(false);
  const [smsConfirmModalVisible, setsmsConfirmModalVisible] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [userPhone, setUserPhone] = useState('');

  const registerState = useSelector(selectRegisterstate);
  const wppStatus = useSelector(getWhatsappStatus);

  useEffect(() => {
    ValidateTag(id);
    fetchUserPhone();
  }, []);

  const fetchUserPhone = async () => {
    try {
      const response = await http.get(`/tags/${id}`);
      setUserPhone(response.data.tag.userPhone);
    } catch (error) {
      console.error('Erro ao buscar userPhone:', error);
      Swal.fire({
        title: 'Erro',
        text: 'Não foi possível carregar o número de telefone registrado.',
        icon: 'error',
        confirmButtonText: 'Tentar novamente',
      });
    }
  };

  useEffect(() => {
    setInputDisabled(!phoneNumber);
  }, [phoneNumber]);

  const onFinishFailed = (errorInfo) => {
    Swal.fire({
      title: 'Preencha todos os campos corretamente',
      text: JSON.stringify(errorInfo),
      icon: 'error',
    });
  };

  const savePreRegister = async (data) => {
    try {
      await http.patch(`/tags/change-status/${id}`, data);
    } catch (error) {
      console.error('Erro ao salvar pre-registro:', error);
      throw error;
    }
  };

  const validateAndNavigate = async (grantType) => {
    const countryCode = `+${phoneNumber.slice(1, 3)}`;
    const userPhoneClean = phoneNumber.slice(3);

    if (!userPhoneClean) {
      Swal.fire({
        title: 'Número de telefone inválido!',
        icon: 'error',
        confirmButtonText: 'Corrigir',
      });
      closeModals();
      return false;
    }

    if (userPhoneClean !== userPhone) {
      Swal.fire({
        title: 'Número de telefone inválido!',
        text: userPhone
          ? 'O número informado não corresponde ao registrado durante o pré-cadastro da tag. Por favor, verifique os dados inseridos.'
          : 'Esta tag não possui um número registrado. Certifique-se de que está na página correta.',
        icon: 'error',
        confirmButtonText: 'Corrigir',
      });
      closeModals();
      return false;
    }

    try {
      await savePreRegister({
        statusQr: 'pending',
        countryCode,
        userPhone: userPhoneClean,
        grantType,
      });

      return true;
    } catch (error) {
      console.error('Erro ao salvar registro e navegar:', error);

      Swal.fire({
        title: 'Erro ao enviar o código!',
        text: error?.response?.data?.message || 'Ocorreu um erro inesperado.',
        icon: 'error',
        confirmButtonText: 'Tentar novamente',
      });

      closeModals();
      return false;
    }
  };

  const closeModals = () => {
    setwhatsAppConfirmModalVisible(false);
    setsmsConfirmModalVisible(false);
  };

  const handleConfirmClick = async (grantType) => {
    try {
      await form.validateFields();
      form.setFieldsValue({ grantType });
      form.submit();

      const isValid = await validateAndNavigate(grantType);
      if (isValid) {
        await AuthPhone(form.getFieldsValue());
      }
    } catch (error) {
      console.error('Erro durante a validação:', error);
      closeModals();
    }
  };

  return !registerState.config.logo ? (
    <div className="flex items-center justify-center w-full h-screen">
      <Spin size="large" />
    </div>
  ) : (
    <RegisterPetLayout
      hasHeader
      description="Insira o número do seu celular e receba o código de ativação da coleira."
      title={'Validar número'}
      size={5}
      step={1}
    >
      <Form
        form={form}
        name="basic"
        layout="horizontal"
        colon={false}
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 25,
          marginTop: '1rem',
        }}
        initialValues={{ userPhone: phoneNumber }}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Space
          style={{ display: 'flex', width: '100%', justifyContent: 'center' }}
        >
          <Form.Item
            name="userPhone"
            rules={[
              { required: true, message: 'Informe o número do telefone' },
            ]}
          >
            <PhoneNumberField
              value={phoneNumber || ''}
              onChange={(value) => {
                setPhoneNumber(value || '');
                form.setFieldsValue({ userPhone: value || '' });
              }}
            />
          </Form.Item>

          <Form.Item initialValue={'sms'} name="grantType">
            <Input type="hidden" />
          </Form.Item>
        </Space>
      </Form>

      <div className="flex flex-col w-full gap-4 mt-4 justify-center items-center">
        {wppStatus && (
          <S.SubmitWhatsappButton
            onClick={() => setwhatsAppConfirmModalVisible(true)}
            disabled={inputDisabled}
            type="primary"
            htmlType="submit"
          >
            <WhatsAppOutlined />
            Receber por Whatsapp
          </S.SubmitWhatsappButton>
        )}
        <S.SubmitButton
          className="whatsapp"
          onClick={() => setsmsConfirmModalVisible(true)}
          disabled={inputDisabled}
          type="primary"
          htmlType="submit"
        >
          Receber por SMS
        </S.SubmitButton>

        <Modal
          title="Confirmação de Telefone"
          isOpen={whatsAppConfirmModalVisible}
          onClose={closeModals}
          onConfirm={() => handleConfirmClick('whatsapp')}
        >
          <span style={{ fontSize: '16px' }}>
            Confira se este é o seu telefone! <br />
            <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
              {formatUserPhoneForDisplay(phoneNumber)}
            </span>
          </span>
        </Modal>
        <Modal
          title="Confirmação de Telefone"
          isOpen={smsConfirmModalVisible}
          onClose={closeModals}
          onConfirm={() => handleConfirmClick('sms')}
        >
          <span style={{ fontSize: '16px' }}>
            Confira se este é o seu telefone! <br />
            <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
              {formatUserPhoneForDisplay(phoneNumber)}
            </span>
          </span>
        </Modal>
      </div>
    </RegisterPetLayout>
  );
};
