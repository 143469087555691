import { useEffect, useRef, useState } from "react";
import { Html5Qrcode, Html5QrcodeScanner } from "html5-qrcode";
import "./QrStyles.css";
import QrFrame from "../../assets/qr-frame.svg";
import { GestorPetAPI } from "../gestor-pet/gestorPetAPI";
import { getPet } from "../../app/store/authSlice";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

export const QRReader = ({ add }) => {
  const qrCodeScannerRef = useRef(null);
  const [qrOn, setQrOn] = useState(true);
  const [scannedResult, setScannedResult] = useState("");
  const [cameras, setCameras] = useState([]);
  const [selectedCamera, setSelectedCamera] = useState("");

  const { updatePet } = GestorPetAPI();
  const pet = useSelector(getPet);
  const navigate = useNavigate();

  const onScanSuccess = (decodedText, decodedResult) => {
    console.log("QR Code Lido: ", decodedText);
    setScannedResult(decodedText);
    handleQRCodeProcess(decodedText);
  };

  const handleQRCodeProcess = (value) => {
    Swal.fire({
      title: "Processando...",
      text: "Aguarde estamos processando sua solicitação.",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    if (add) {
      if (!pet.qrCodes) pet.qrCodes = [];
      pet.qrCodes.push(value.split("6e.ae/")[1]);
      updatePet(pet._id, pet)
        .then(() => {
          navigate("/gestor-pet/" + pet._id);
        })
        .finally(() => {
          Swal.close();
          if (qrCodeScannerRef.current) {
            qrCodeScannerRef.current.resume();
          }
        });
      return;
    }

    setTimeout(() => {
      window.location.href = value;
      Swal.close();
    }, 1000);
  };

  useEffect(() => {
    const initializeScanner = async () => {
      try {
        const availableCameras = await Html5Qrcode.getCameras();
        console.log("Câmeras encontradas:", availableCameras);
        setCameras(availableCameras);

        if (availableCameras.length === 0) {
          console.error("Nenhuma câmera disponível.");
          setQrOn(false);
          return;
        }

        const bestCamera = availableCameras.find(camera => camera.label.toLowerCase().includes('back')) || availableCameras[0];
        setSelectedCamera(bestCamera.id);
      } catch (err) {
        console.error("Erro ao listar câmeras:", err);
        setQrOn(false);
      }
    };

    initializeScanner();

    return () => {
      if (qrCodeScannerRef.current) {
        qrCodeScannerRef.current.clear();
      }
    };
  }, []);

  useEffect(() => {
    if (selectedCamera) {
      qrCodeScannerRef.current = new Html5Qrcode("qr-reader");
      const config = {
        fps: 10,
        qrbox: 250,
        aspectRatio: 1.0,
        disableFlip: false,
        videoConstraints: {
          deviceId: selectedCamera,
        },
      };

      qrCodeScannerRef.current.start(
        { deviceId: { exact: selectedCamera } },
        config,
        onScanSuccess,
        (errorMessage) => {
          console.warn(`Erro ao ler QR Code: ${errorMessage}`);
        }
      )
        .catch((err) => {
          console.error("Erro ao iniciar scanner:", err);
          setQrOn(false);
        });
    }

    return () => {
      if (qrCodeScannerRef.current) {
        qrCodeScannerRef.current.stop();
      }
    };
  }, [selectedCamera]);

  useEffect(() => {
    if (!qrOn)
      alert(
        "Câmera bloqueada ou inacessível. Permita o acesso à câmera nas configurações do navegador e recarregue a página."
      );
  }, [qrOn]);

  return (
    <div className="qr-reader-container">
      <h2>Leia o QrCode</h2>
      <p>Direcione sua câmera para o QRCODE de sua Coleira ou Plaquinha.</p>

      <div className="qr-reader-frame">
        <div id="qr-reader"></div>
      </div>

      <p className="camera-instructions">
        Caso sua câmera não esteja focando, tente selecionar outra abaixo.
      </p>

      <div className="camera-selection">
        <select
          id="camera-select"
          value={selectedCamera}
          onChange={(e) => setSelectedCamera(e.target.value)}
          disabled={cameras.length === 0}
          className="custom-select"
        >
          {cameras.length > 0 ? (
            cameras.map((camera) => (
              <option key={camera.id} value={camera.id}>
                {camera.label || `Camera ${camera.id}`}
              </option>
            ))
          ) : (
            <option>Nenhuma câmera disponível</option>
          )}
        </select>
      </div>
    </div>
  );
};

export default QRReader;
