export const SUPPORTED_LANGUAGES = ["pt", "en", "es"];

export const normalizeLanguage = (lang) => {
  try {
    if (!lang) return "pt";

    const code = lang.split("-")[0].toLowerCase();
    return SUPPORTED_LANGUAGES.includes(code) ? code : "pt";
  } catch (e) {
    console.warn("Error normalizing language:", e);
    return "pt";
  }
};

export const detectPreferredLanguage = () => {
  try {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      const normalized = normalizeLanguage(storedLanguage);
      if (normalized) return normalized;
    }

    if (navigator && navigator.language) {
      return normalizeLanguage(navigator.language);
    }
  } catch (error) {
    console.warn("Error detecting language:", error);
  }

  return "pt";
};

export const saveLanguagePreference = (language) => {
  try {
    if (language && SUPPORTED_LANGUAGES.includes(language)) {
      localStorage.setItem("language", language);
      return true;
    }
  } catch (error) {
    console.warn("Failed to save language preference:", error);
  }
  return false;
};
