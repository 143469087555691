import { Button, Form, Input, Row, Select } from "antd";
import Dashboard from "../../../components/layouts/DashLayout";
import * as S from "./styles";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import CollarAssetApi from "./collarAssetsAPI";
import { UploadInputAdmin } from "../../../components/uploadInputAdmin";

export const CreataCollarAssets = (props) => {
  const { createCollarAsset, findCollarAsset, updateCollarAsset } =
    CollarAssetApi();
  const [asset, setAsset] = useState({
    title: "",
    options: [],
    required: false,
  });
  const [form] = useForm();
  const { id } = useParams();

  useEffect(() => {
    console.log(props);
    if (props.edit) {
      const loadAsset = async () => {
        const data = await findCollarAsset(id);
        form.setFieldsValue(data);
      };
      loadAsset();
    }
  }, []);

  const onFinish = (values) => {
    if (props.edit) {
      updateCollarAsset(id)(asset);

      return;
    }

    createCollarAsset(asset);

    return;
  };

  const addListItem = (fields) => {
    const nextFields = [...fields];
    nextFields.push({ key: nextFields.length, title: "", url: "" });
    return nextFields;
  };

  // Função para remover um item da lista
  const removeListItem = (fields, index) => {
    const nextFields = [...fields];
    nextFields.splice(index, 1);
    return nextFields;
  };

  return (
    <Dashboard selected="49">
      <S.Wrapper>
        <Form
          form={form}
          labelCol={{ span: 24 }}
          onFinish={props.edit ? updateCollarAsset(id) : createCollarAsset}
        >
          <Form.Item label="Titulo" name="title">
            <Input />
          </Form.Item>

          <S.OptionsTitle>Opções</S.OptionsTitle>
          <Form.List name="options">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <div style={{ marginBottom: "1.5rem" }} key={field.key}>
                    <Form.Item label="Título" name={[field.name, "title"]}>
                      <Input />
                    </Form.Item>
                    <Form.Item hidden={true} name={[field.name, "url"]}>
                      <Input type="hidden" />
                    </Form.Item>
                    <Row>
                      <UploadInputAdmin
                        onChange={(e) => {
                          const fields = form.getFieldValue("options");
                          fields[index].url = e[0].url;
                          form.setFieldValue("options", fields);
                        }}
                        label={"Upload Asset"}
                      ></UploadInputAdmin>
                      <Button onClick={() => remove(index)}>Remover</Button>
                    </Row>
                  </div>
                ))}
                <Button onClick={() => add(fields)}>Adicionar opção</Button>
              </>
            )}
          </Form.List>

          <Form.Item
            initialValue={asset.required}
            label={"Obrigatório?"}
            name={`required`}
          >
            <Select
              options={[
                {
                  value: false,
                  label: "Não",
                },
                {
                  value: true,
                  label: "Sim",
                },
              ]}
            />
          </Form.Item>

          <Button htmlType="submit">
            {props.edit ? "Editar" : "Criar"} Asset de Coleira
          </Button>
        </Form>
      </S.Wrapper>
    </Dashboard>
  );
};
