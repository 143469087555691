import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom';
import { useAPITag } from '../../../services/api';
import { useDispatch, useSelector } from 'react-redux';
import {
  saveState,
  setCode,
  setConfig,
  setPhone,
  setWhatsappStatus,
} from '../RegisterPetSlice';
import { getUser, isLoggedIn } from '../../../app/store/authSlice';

const AuthPhoneAPI = () => {
  const tagApi = useAPITag();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLogged = useSelector(isLoggedIn);
  const user = useSelector(getUser);

  const AuthPhone = async (data) => {
    try {
      const countryCode = data.userPhone.slice(0, 3);
      const phone = data.userPhone.slice(3);

      dispatch(
        setPhone({
          phone: phone,
          maskedPhone: data.userPhone,
          grantType: data.grantType,
          countryCode: countryCode,
        })
      );

      if (data.grantType === 'whatsapp') {
        const whatsappCodeDTO = {
          grantType: 'whatsapp',
          tokenType: 'auth-code',
          countryCode: countryCode,
          phone: phone,
          userId: phone,
        };

        await tagApi.http.post('/user-token/whatsapp', whatsappCodeDTO);

        dispatch(saveState());
        navigate(`/confirm-code/${id}`);
      } else {
        await tagApi.http.post('/usuario/verify', {
          phone: countryCode + phone,
        });

        dispatch(saveState());
        navigate(`/confirm-code/${id}`);
      }
    } catch (error) {
      console.error('Erro no AuthPhone:', error);
    }
  };

  const ValidateTag = async (code) => {
    dispatch(setCode({ code }));
    try {
      const perfilResponse = await tagApi.http.get('tags/' + code);
      if (perfilResponse.data.tag.statusQr === 'pending') {
        navigate(`/preRegister/profile/${code}`);
        return;
      }
      if (perfilResponse.data.hasPet && !isLogged) {
        navigate('/profile/location/' + code);
        return;
      }
      if (perfilResponse.data.hasPet && isLogged) {
        navigate('/profile/' + code + '?scanned=true');
        return;
      }
      if (window.location.hostname === '6e.ae') {
        window.location.href = 'https://ajuda.pet/6e' + code;
      }
      dispatch(setConfig(perfilResponse.data.config));
      const statusResponse = await tagApi.http.get('whatsapp-status');

      dispatch(setWhatsappStatus(statusResponse.data.status));

      if (isLogged) {
        const petsResponse = await tagApi.http.get('pets/user/' + user._id);
        // await tagApi.http.patch('/tags/change-status/' + code, { statusQr: "pending", userPhone: user.phone });
        if (petsResponse.data.length > 0) {
          navigate('/select-pet');
          return;
        }
        navigate('/register-pet');
      }
    } catch {
      console.log('ValidateTag error');
    }
  };

  return { ValidateTag, AuthPhone };
};

export default AuthPhoneAPI;
