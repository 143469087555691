import { useNavigate } from "react-router-dom";
import { useAPITag } from "../../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthState } from "../login/loginSlice";

const CompaniesAPI = () => {
  const { http } = useAPITag();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authState = useSelector(selectAuthState);

  const getAllCompanies = async (search) => {
     try {
       const response = await http.get("/admin", {
         params: search ? { search } : {},
       });
       return response.data;
     } catch (error) {}
   };

  const getCompany = async (id) => {
    try {
      const response = await http.get("/admin/" + id);

      return response.data;
    } catch (error) {}
  };

  const deleteCompany = (id) => {
    try {
      const response = http.delete("admin/" + id);

      //navigate("/admin/companies");
    } catch (error) {}
  };

  const createCompany = (e) => {
    try {
      const response = http.post("admin", e);

      navigate("/admin/companies");
    } catch (error) {}
  };

  const updateCompany = (id) => (e) => {
    try {
      const response = http.patch("admin/" + id, e);

      navigate("/admin/companies");
    } catch (error) {}
  };

  return {
    getAllCompanies,
    getCompany,
    updateCompany,
    createCompany,
    deleteCompany,
  };
};

export default CompaniesAPI;
