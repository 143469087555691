import React from "react";
import { createRoot, hydrateRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import reportWebVitals from "./reportWebVitals";
import { router } from "./app/routes";
import { ConfigProvider } from "antd";
import { Router, RouterProvider, useSearchParams } from "react-router-dom";
import "./index.css";
import locale from "antd/locale/pt_BR";
import { setConfig } from "./features/register-pet/RegisterPetSlice";
import { logout } from "./app/store/authSlice";
import CookieConsent from "react-cookie-consent";
import Loader from "./components/Loader";
import Footer from "./components/Footer";
import { useLocation } from "react-router-dom";
import './i18n';

const container = document.getElementById("root");

const root = createRoot(container);
const LoadData = () => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  return async (dispatch) => {
    dispatch(
      setConfig({
        _id: params._id,
        primaryColor: params.primaryColor,
        secondaryColor: params.secondaryColor,
        logo: params.logo,
      })
    );
  };
};

store.dispatch(LoadData()).then(() => {
  const shouldRenderCookieConsent = !window.location.pathname.includes("/preRegister/profile");

    root.render(
      <>
        {shouldRenderCookieConsent && (
        <CookieConsent
          location="bottom"
          enableDeclineButton
          declineButtonText="Recuso!"
          buttonText="Aceito!"
          cookieName="ajudapetCookies"
          style={{
            background: "white",
            color: "#313131",
            boxShadow: "-2px -1px 52px -10px rgba(59,59,59,0.75)",
          }}
          buttonStyle={{
            color: "white",
            fontSize: "13px",
            backgroundColor: "#f67e48",
          }}
          expires={150}
        >
          Este site utiliza cookies para melhorar sua experiência, aceitando o uso
          deles você também aceita nossa{" "}
          <a target="_blank" href="https://ajuda.pet/privacy" rel="noreferrer">
            politica de privacidade
          </a>
          <span> </span>e<span> </span>
          <a target="_blank" href="https://ajuda.pet/legal" rel="noreferrer">
            nossos termos de uso
          </a>
        </CookieConsent>
        )}
        <ConfigProvider
          locale={locale}
          theme={{
            token: {
              colorPrimary: "#F67E48",
            },
          }}
        >
          <Provider store={store}>
            <RouterProvider router={router}>
            </RouterProvider>
          </Provider>
              <Footer />
        </ConfigProvider>
      </>
    );
    });
reportWebVitals();

export default root;
