import { useTranslation } from 'react-i18next';

export default function Footer() {
  const { t } = useTranslation();

  return (
    <div className="bg-white w-full h-16 lg:h-[60px] flex flex-col lg:flex-row lg:items-center lg:justify-between lg:px-32 justify-start py-2 items-center text-zinc-500">
      <p className="mb-2 lg:mb-0">© 2023  AjudaPet</p>
      <div className="flex flex-row gap-3 justify-center items-center text-sm ">
        <a href="https://ajuda.pet/legal" rel="noreferrer" target="_blank">{t('terms')}</a>
        <a href="https://ajuda.pet/privacy" rel="noreferrer" target="_blank">{t('privacyPolicy')}</a>
      </div>
    </div>
  )
}