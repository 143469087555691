import React, { useState, useEffect } from 'react';
import { WhatsAppOutlined } from "@ant-design/icons";
import { Button } from "antd";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';

const WhatsappFloatingButton = styled(Button)`
  display: flex;
  position: fixed;
  bottom: 15px;
  color: white !important;
  border-color: white !important;
  /* -webkit-box-pack: center; */
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  padding: 16px 24px;
  /* gap: 12px; */
  color: white;
  width: 90px;
  height: 40px;
  border-radius: 90px;
  z-index: 50;
  right: 5px;
  /* margin-top: 2rem; */
  background: #128c7e !important;
  transition: bottom 0.3s ease;
`;

export const WhatsappButton = () => {
  const { t } = useTranslation();
  const [scrollY, setScrollY] = useState(0);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
      console.log('scrollY:', window.scrollY);
    };
  
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
      console.log('screenHeight:', window.innerHeight);
    };
  
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const buttonStyle = {
    bottom: scrollY < screenHeight - 500 ? '15px' : '80px', // Ajuste a posição final desejada (100px neste caso)
  };
  return (
    <WhatsappFloatingButton
      style={buttonStyle}
      onClick={() => {
        window.open(`https://wa.me/+5531988884400`);
      }}
    >
      {t('help')} <WhatsAppOutlined />
    </WhatsappFloatingButton>
  );
};
