import { createSlice } from "@reduxjs/toolkit";
import i18n from "i18next";
import {
  detectPreferredLanguage,
  saveLanguagePreference,
  SUPPORTED_LANGUAGES,
} from "../../utils/languageUtils";

const initialLanguage = detectPreferredLanguage();

const languageSlice = createSlice({
  name: "language",
  initialState: initialLanguage,
  reducers: {
    setLanguage: (state, action) => {
      if (action.payload && SUPPORTED_LANGUAGES.includes(action.payload)) {
        saveLanguagePreference(action.payload);

        i18n.changeLanguage(action.payload);
        return action.payload;
      }

      return state;
    },
  },
});

export const { setLanguage } = languageSlice.actions;
export const selectLanguage = (state) => state.language;

export default languageSlice.reducer;
