import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { setLanguage, selectLanguage } from "../app/store/languageSlice";
import { SUPPORTED_LANGUAGES } from "../utils/languageUtils";

import BrazilFlag from "../assets/icons/brazil_flag.png";
import UnitedStatesFlag from "../assets/icons/eua_flag.png";
import SpanishFlag from "../assets/icons/spanish_flag.png";

const LanguageFloatingButton = styled.div`
  display: flex;
  position: fixed;
  bottom: 60px;
  right: 5px;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  background: white;
  color: black;
  border: 1px solid #ddd;
  border-radius: 90px;
  z-index: 50;
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const FlagIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

const LanguageChangeButton = () => {
  const dispatch = useDispatch();
  const currentLanguage = useSelector(selectLanguage);

  const languages = [
    {
      code: "pt",
      label: "Português",
      flag: BrazilFlag,
    },
    {
      code: "en",
      label: "English",
      flag: UnitedStatesFlag,
    },
    {
      code: "es",
      label: "Español",
      flag: SpanishFlag,
    },
  ];

  const safeCurrentLanguage = SUPPORTED_LANGUAGES.includes(currentLanguage)
    ? currentLanguage
    : "pt";

  const handleLanguageChange = () => {
    const currentIndex = languages.findIndex(
      (lang) => lang.code === safeCurrentLanguage
    );

    const nextIndex = (currentIndex + 1) % languages.length;
    const nextLanguage = languages[nextIndex];

    dispatch(setLanguage(nextLanguage.code));
  };

  const currentLanguageData =
    languages.find((lang) => lang.code === safeCurrentLanguage) || languages[0];

  return (
    <LanguageFloatingButton onClick={handleLanguageChange}>
      <FlagIcon
        src={currentLanguageData.flag}
        alt={`${currentLanguageData.label} Flag`}
      />
      {currentLanguageData.label}
    </LanguageFloatingButton>
  );
};

export default LanguageChangeButton;
