import {
  Button,
  DatePicker,
  Form,
  Col,
  Typography,
  Row,
  Switch,
} from "antd";
import Dashboard from "../../../components/layouts/DashLayout";
import * as S from "./styles";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import PetsAPI from "./PetsAPI";
import {
  petFeatures,
  petFeaturesProfile,
  racasCachorro,
  racasGato,
  tiposSaude,
} from "../../register-pet/register-pet/RegisterPetMock";
import StyledDropzone from "../../../components/styledDropzone";
import TextArea from "antd/es/input/TextArea";
import UsersAPI from "../users/UsersAPI";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { selectAuthState } from "../login/loginSlice";
import dayjs from "dayjs";
import { NumericFormat } from "react-number-format";
import { sortOptions } from "../../../data/utils/sortOptions";

const CreatePet = (props) => {
  const { createPet, getPet, savePet, getTags, getFilterTags } = PetsAPI();
  const { getUsers } = UsersAPI();
  const [photos, setPhotos] = useState([]);
  const [users, setUsers] = useState([]);
  const [petSpecie, setPetSpecie] = useState("dog");
  const [form] = useForm();
  const { id } = useParams();
  const { Text } = Typography;


  const authState = useSelector(selectAuthState);

  useEffect(() => {
    if (props.edit) {
      const loadPet = async () => {
        const pet = await getPet(id);
        const features = [];
        const petCodes = pet.qrCodes
        for (let i = 0; i < petCodes.length; i++) {
          petCodes[i] = petCodes[i].slice(2).toUpperCase();
        }
        const tags = await getFilterTags(petCodes);

        const filteredTags = [];
        petCodes.forEach(petCode => {
          tags.forEach(tag => {
            if (petCode === tag.code && tag.companyId === authState.user._id) {
              const slicedPath = tag.path.slice(7, 9);
              const newTagInfo = {
                code: slicedPath + tag.code,
              };
              filteredTags.push(newTagInfo.code);
            }
          });
        });
        petFeaturesProfile.forEach((e, index) => {
          if (pet[e.value] === 5) {
            features.push(petFeatures[index].value);
          }
        });

        pet.dataNascimento = dayjs(new Date(pet.birthDate));
        pet.birthDate = new Date(pet.birthDate);
        pet.features = features;
        pet.tutor = pet.tutorId;
        pet.name = pet.nome;
        pet.especie = pet.species;
        pet.raca = pet.breed;
        pet.qrCodes = filteredTags;
        pet.genero = pet.gender;
        pet.genero = pet.gender;
        pet.saude = pet.health;
        pet.corPelo = pet.furColor;
        pet.peso = pet.weight;
        pet.observacoes = pet.notes;

        form.setFieldsValue(pet);
        setPhotos(pet.pictures);
      };

      loadPet();
    }

    const loadUser = async () => {
      const response = await getUsers();
      console.log(response);
      setUsers(response);
    };

    loadUser();
  }, []);

  const handleFinish = (values) => {
    const { features, ...data } = values;

    if (photos.length === 0) {
      Swal.fire({
        title: "Adicione pelo menos um foto do seu Pet",
        icon: "error",
      });
      return;
    }

    data["fotos"] = photos;
    data["avatar"] = photos[0];
    data["companyId"] = authState.user._id;

    data.dataNascimento = values.dataNascimento.toISOString();
    if (features) {
      features.forEach((e) => {
        data[e] = 5;
      });
    }

    if (props.edit) {
      savePet(id, data);
      return;
    }
    createPet(data);
  };

  return (
    <Dashboard selected="8">
      <S.Wrapper>
        <Form form={form} labelCol={{ span: 24 }} onFinish={handleFinish}>
          <Form.Item
            name="tutor"
            label="Tutor"
            rules={[{ required: true, message: "Informe o tutor" }]}
          >
            <S.StyledSelect
              options={users.map((e) => ({ label: e.name, value: e._id }))}
            />
          </Form.Item>
          <StyledDropzone
            setValue={setPhotos}
            value={photos}
            label={"Clique para adicionar fotos do seu Pet"}
          ></StyledDropzone>
          <Form.Item
            name="name"
            label="Nome"
            rules={[
              { required: true, message: "Informe o telefone da empresa" },
            ]}
          >
            <S.StyledInput />
          </Form.Item>
          <Form.Item
            name="especie"
            label="Espécie"
            initialValue="dog"
            rules={[{ required: true, message: "Informe a Espécie do Pet" }]}
          >
            <S.StyledSelect
              initialValue="dog"
              options={[
                { value: "dog", label: "Cachorro" },
                { value: "cat", label: "Gato" },
              ]}
            />
          </Form.Item>

          {petSpecie === "dog" ? (
            <Form.Item
              name="raca"
              label="Raça"
              rules={[{ required: true, message: "Informe a Raça do Pet" }]}
            >
              <S.StyledSelect options={sortOptions(racasCachorro)} />
            </Form.Item>
          ) : (
            <Form.Item
              name="raca"
              label="Raça"
              rules={[{ required: true, message: " Informe a Raça do Pet" }]}
            >
              <S.StyledSelect options={sortOptions(racasGato)} />
            </Form.Item>
          )}

          <Form.Item
            name="genero"
            label="Gênero"
            rules={[{ required: true, message: "Informe o genêro do Pet" }]}
          >
            <S.StyledSelect
              initialValue="female"
              options={[
                { value: "fêmea", label: "Fêmea" },
                { value: "macho", label: "Macho" },
              ]}
            />
          </Form.Item>

          <Form.Item initialValue="tudoCerto" name="saude" label="Estado de saúde">
            <S.StyledSelect defaultValue="tudoCerto" options={tiposSaude} />
          </Form.Item>

          <Form.Item
            initialValue=""
            name="corPelo"
            label="Cor do Pêlo"
            rules={[{ required: true, message: "Informe a Cor do Pet" }]}
          >
            <S.StyledInput defaultValue="" />
          </Form.Item>

          <Col style={{ marginBottom: '10px' }} span={24}>
            <Row gutter={16}>
              <Col span={24}>
                <Text>Peso do Pet (KG)</Text>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={32}>
                <Text type="secondary">Ex: 20,300 = 20 Quilos e 300 gramas</Text>
              </Col>
            </Row>
          </Col>
          <Form.Item initialValue={0} name="peso">
            <NumericFormat className="w-full px-2 border h-[38px] rounded-[8px] hover:border hover:border-[#f67e48] transition ease-out delay-100 outline-none focus:border-[#f67e48]" maxLength={6} value="20020220" allowLeadingZeros thousandSeparator="," />
          </Form.Item>

          <Form.Item name="features" label="Caracteristícas">
            <S.StyledSelect mode="multiple" allowClear options={petFeatures} />
          </Form.Item>
          <Form.Item name="qrCodes" label="QRCodes ">
            <S.StyledSelect mode="tags" allowClear />
          </Form.Item>
          <Form.Item
            name="dataNascimento"
            label="Data de nascimento"
            rules={[
              { required: true, message: "Informe a data de Nascimento" },
            ]}
          >
            <DatePicker
              format={"DD/MM/YYYY"}
              placeholder="Selecione a data de nascimento"
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item initialValue={false} name="tail" label="Possui cauda?">
            <Switch />
          </Form.Item>
          <Form.Item
            defaultValue={false}
            name="adoption"
            label="Disponível para adoção?"
          >
            <Switch />
          </Form.Item>
          <Form.Item initialValue={false} name="chip" label="Possui chip?">
            <Switch />
          </Form.Item>
          {form.getFieldValue("chip") && (
            <Form.Item initialValue={""} name="chipN" label="Numéro do chip">
              <S.StyledInput />
            </Form.Item>
          )}
          <Form.Item initialValue={false} name="rescued" label="Foi resgatado?">
            <Switch />
          </Form.Item>

          {form.getFieldValue("rescued") && (
            <Form.Item
              defaultValue={""}
              name="rescueLocale"
              label="Local de regaste"
            >
              <S.StyledInput />
            </Form.Item>
          )}
          <Form.Item initialValue={""} name="notes" label="Observações">
            <TextArea rows={4} />
          </Form.Item>

          <Button htmlType="submit">
            {props.edit ? "Editar" : "Criar"} Pet
          </Button>
        </Form>
      </S.Wrapper>
    </Dashboard>
  );
};

export default CreatePet;
