import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 auto;
  justify-content: center;
  position: relative;

  &:hover {
    border-color: #2196f3;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const LabelText = styled.div`
  text-align: center;
  font-size: 12px;
  color: #777;
  margin-top: 8px;
`;

export const CropContainer = styled.div`
  position: relative;
  height: 300px;
  background-color: #f0f0f0;
  border-radius: 8px;
  overflow: hidden;
`;

export const SliderContainer = styled.div`
  margin: 20px 10px 10px 10px;
`;

export const SliderRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => (props.$noMargin ? "0" : "12px")};
`;

export const SliderIcon = styled.span`
  font-size: 16px;
  margin-right: 8px;
  margin-left: ${(props) => (props.$left ? "8px" : "0")};
  color: #555;
`;

export const UploadOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

export const ModalInstructions = styled.div`
  margin-top: 15px;
  text-align: center;
  color: #888;
  font-size: 14px;
`;

export const EmptyModal = styled.div`
  padding: 20px;
  text-align: center;
  color: #555;
`;

export const PopoverButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px 0;
`;
