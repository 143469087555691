import { Button, Dropdown, QRCode, Row, Table, Form, Input } from "antd";
import Dashboard from "../../../components/layouts/DashLayout";
import * as S from "./styles";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { EllipsisOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import PetsAPI from "./PetsAPI";

const MySwal = withReactContent(Swal);

export const MyPets = () => {
  const [code, setCode] = useState('');
  const [tagFilter, setTagFilter] = useState('');
  const [collars, setCollars] = useState([]);
  const { getPets, getPetsByCode } = PetsAPI();
  const navigate = useNavigate();
  useEffect(() => {
    loadPets();
  }, []);

  const columns = [
    {
      title: "Código",
      render: (row) =>
        `${row.qrCodes && row.qrCodes.length > 0
          ? row.qrCodes[0]
          : "Não vinculado"
        }`,
    },
    {
      title: "Nome",
      render: (row) => `${row.nome}`,
    },
    {
      title: "Data de Ativação",
      render: (row) => `${new Date(row.createdAt).toLocaleDateString("pt-BR")}`,
    },

    {
      title: "Ações",
      render: (row, index, column, i) => {
        const items = [
          {
            key: "actions_3_" + index,
            label: (
              <Button
                color="danger"
                onClick={async () => {
                  navigate("/admin/pets/edit/" + row._id);
                }}
              >
                Editar
              </Button>
            ),
          },

          {
            key: "actions_4_" + index,
            label: (
              <Button
                color="danger"
                onClick={async () => {
                  window.open("https://ajuda.pet/profile/" + row._id);
                }}
              >
                Visualizar Pet
              </Button>
            ),
          },
        ];

        return (
          <Dropdown
            menu={{
              items: [...items],
            }}
          >
            <Button href="#" onClick={(e) => e.preventDefault()}>
              <EllipsisOutlined style={{ fontSiz: "25px" }} />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const loadPets = async () => {
    const response = await getPets();
    setCollars(response);
  };


  const findByCode = async () => {
    if (!code) {
      return await loadPets()
    }
    const response = await getPetsByCode(code);
    setCollars(response);
  };

  return (
    <Dashboard selected="8">
      <S.Wrapper>
        <Row>
          <Button
            onClick={() => {
              navigate("/admin/pets/create");
            }}
          >
            Criar Pet
          </Button>
        </Row>
        <div className="flex flex-row gap-2 w-72">
        <Input
            type="text"
            placeholder="Busca por código"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          <Button
            type="primary"
            className="bg-orange-600 text-white"
            onClick={findByCode}
          >
            Buscar
          </Button>
        </div>
        <Table
          style={{ width: "80vw" }}
          columns={columns}
          dataSource={collars}
        />
      </S.Wrapper>
    </Dashboard>
  );
};
