import { Button, Form, Input } from "antd";
import Dashboard from "../../../components/layouts/DashLayout";
import * as S from "./styles";
import CollarSizeAPI from "./configsAPI";
import { useEffect, useState } from "react";
import { useForm } from "antd/es/form/Form";
import { Colorpicker } from "antd-colorpicker";
import { UploadInputAdmin } from "../../../components/uploadInputAdmin";

const Config = (props) => {
  const { saveConfig, getConfig } = CollarSizeAPI();
  const [config, setConfig] = useState({});
  const [form] = useForm();

  useEffect(() => {
    const loadConfig = async () => {
      const data = await getConfig();
      setConfig(data);
      form.setFieldsValue(data);
    };

    loadConfig();
  }, []);

  const saveLogo = (e) => {
    form.setFieldValue("logo", e[0].url);
  };

  return (
    <Dashboard selected="6">
      <S.Wrapper>
        <Form form={form} labelCol={{ span: 24 }} onFinish={saveConfig}>
          <Form.Item
            initialValue={config.primaryColor}
            label="Cor Primária"
            name="primaryColor"
          >
            <Colorpicker></Colorpicker>
          </Form.Item>
          <Form.Item
            initialValue={config.secondaryColor}
            name="secondaryColor"
            label="Cor Secundária"
          >
            <Colorpicker></Colorpicker>
          </Form.Item>
          <Form.Item initialValue={config.logo} name="logo">
            <Input type="hidden" />
          </Form.Item>

          <Form.Item label="Logo">
            <UploadInputAdmin
              key="upload-logo-configs"
              label="Enviar Logo"
              onChange={saveLogo}
            />
          </Form.Item>

          <Button htmlType="submit">Salvar configurações</Button>
        </Form>
      </S.Wrapper>
    </Dashboard>
  );
};

export default Config;
