import React from "react";
import "./modal.css";

const Modal = ({ isOpen, onClose, title, children, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <h2 style={{ fontWeight: "bold" }}>{title}</h2>
          <button className="close-button" onClick={onClose}>
            X
          </button>
        </div>
        <div className="modal-body">{children}</div>
        <div className="modal-footer">
          <button onClick={onClose} className="cancel-button">
            Cancelar
          </button>
          <button onClick={onConfirm} className="ok-button">
            Confirmar
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
