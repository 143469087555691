import React, {useState, useEffect} from "react";

import * as S from "./styles";
import ShareIcon from "../Icons/ShareIcon";
import SettingsIcon from "../Icons/SettingsIcon";
import HelpIcon from "../Icons/HelpIcon";
import LogoutIcon from "../Icons/LogoutIcon";
import Dropdown from "../Dropdown";
import MenuItem from "../MenuItem";
import UserIcon from "../Icons/User";
import ArrowIcon from "../Icons/Arrow";
import { useDispatch, useSelector } from "react-redux";
import { getUser, isLoggedIn, logout } from "../../app/store/authSlice";
import { useNavigate } from "react-router-dom";
import { useAPITag } from "../../services/api";
import { useTranslation } from 'react-i18next';

//import Icon from "../Icon";

export const MenuItems = () => {  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLogged = useSelector(isLoggedIn);
  const { t } = useTranslation();

  const logOut = () => {
    dispatch(logout());
    navigate("/");
  };

  const subMenuProfile = [
    
    {
      label: t('settings'),
      subtitle: t('accountSettings'),
      href: "/config-user",
      icon: <SettingsIcon />,
    },

    {
      label: (
        <div style={{ cursor: "pointer" }} onClick={logOut}>
          Logout
        </div>
      ),
      subtitle: "",
      href: "",
      icon: (
        <div style={{ cursor: "pointer" }} onClick={logOut}>
          <LogoutIcon />{" "}
        </div>
      ),
    },
  ];

  return isLogged ? subMenuProfile : [];
};
export default function Profile({ userName = "Nome de usuário" }) {
  const { t } = useTranslation();
  const [user , setUser] = useState({})
  const { http } = useAPITag();

  const getUserData = async () => {
    const user = JSON.parse(localStorage.getItem("@customer"));
    try {
      const response = await http.get("user/" + user._id);
      setUser(response.data);
      console.log(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    getUserData();
  },[]);
  
  const menuItems = MenuItems();
  return (
    <Dropdown
      title={
        <S.StyledProfileContainer>
          <S.StyledProfileUserInfos tabIndex={"1"}>
            <S.StyledProfileInfosConatiner>
              <S.StyledProfileTextWrapper>
                <h3>{userName}</h3>
                {t('viewProfile')} &#10549;
              </S.StyledProfileTextWrapper>
              <S.StyledProfilePic src={user.avatar}></S.StyledProfilePic>
            </S.StyledProfileInfosConatiner>
          </S.StyledProfileUserInfos>
        </S.StyledProfileContainer>
      }
    >
      <MenuItem links={menuItems} label="" icon={undefined} />
    </Dropdown>
  );
}
