import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
} from "antd";
import Dashboard from "../../../components/layouts/DashLayout";
import * as S from "./styles";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import GabaritAPI from "./gabaritAPI";
import { useRef } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { getAdminId } from "../login/loginSlice";
import Swal from "sweetalert2";
import { UploadInputAdmin } from "../../../components/uploadInputAdmin";

export const CreateGabarit = (props) => {
  const { getGabaritData, createGabarit } = GabaritAPI();
  const [form] = useForm();
  const { id } = useParams();
  const [colors, setColors] = useState([]);
  const sizes = ["PP", "P", "Único", "G", "M", "GG"];
  const [assets, setAssets] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [types, setTypes] = useState([]);
  const adminId = useSelector(getAdminId);
  const [newColor, setNewColor] = useState("");
  const inputRef = useRef(null);
  const [gabarits, setGabarits] = useState([]);
  const [rapportImages, setRapportImages] = useState({});
  const [backSameAsFront, setBackSemaAsFront] = useState(false);
  const navigate = useNavigate();
  const onNewColorChange = (event) => {
    setNewColor(event.target.value);
  };

  const addItem = (e) => {
    e.preventDefault();
    setColors([...colors, newColor]);
    setNewColor("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  useEffect(() => {
    const loadGabaritData = async () => {
      const data = await getGabaritData();
      setColors(data.colors);
      setTypes(data.types);
      setAssets(data.requiredAssets);
    };

    loadGabaritData();
  }, []);

  const rapportSizes = {
    Único: {
      width: 19,
      height: 190,
    },
    PP: {
      width: 20,
      height: 200,
    },
    P: {
      width: 20,
      height: 200,
    },
    M: {
      width: 25,
      height: 250,
    },
    G: {
      width: 30,
      height: 300,
    },
    GG: {
      width: 30,
      height: 300,
    },
  };

  const handleCreateGabarit = async ({
    title,
    verticalAlignment,
    colorGroup,
    asset_0,
  }) => {
    console.log(gabarits);
    console.log(rapportImages);

    const gabaritsDTO = gabarits.map((gabarit) => {
      console.log(gabarit);
      return {
        imageHeight: rapportSizes[gabarit.size].height,
        imageWidth: rapportSizes[gabarit.size].width,
        frontUrl: rapportImages[gabarit.size].frontUrl,
        backUrl: rapportImages[gabarit.size].frontUrl,
        collarType: gabarit.type,
        collarSize: gabarit.size,
      };
    });

    const requestDTO = {
      title: title,
      assetVerticalAlignment: verticalAlignment,
      colorGroup,
      assetColor: asset_0,
      gabarits: gabaritsDTO,
      companyId: adminId,
    };

    await createGabarit(requestDTO);
    Swal.fire({ icon: "success", text: "Gabarito enviado com sucesso" });
    navigate("/admin/collars");
  };

  const filteredSizes = sizes.filter((o) => !selectedSizes.includes(o.title));

  return (
    <Dashboard selected="1">
      <S.Wrapper>
        <Form
          form={form}
          labelCol={{ span: 24 }}
          onFinish={handleCreateGabarit}
        >
          <Form.Item label="Titulo" name="title">
            <Input />
          </Form.Item>

          <Form.Item
            initialValue={"COM BORRACHA"}
            label="Filtro de Coleiras"
            name="collarFilter"
          >
            <Select
              placeholder="Filtro de Coleiras"
              options={[
                { value: "SEM BORRACHA", label: "SEM BORRACHA" },
                { value: "COM BORRACHA", label: "COM BORRACHA" },
              ]}
              onChange={() => {
                setAssets([...assets]);
              }}
            />
          </Form.Item>

          {assets.map((e, index) => {
            return (
              <Form.Item label={e.title} name={"asset_" + index}>
                <Select
                  placeholder={e.title}
                  options={e.options.map((option) => {
                    return { value: option.title, label: option.title };
                  })}
                />
              </Form.Item>
            );
          })}
          <Form.Item label="Agrupamento de Cor" name="colorGroup">
            <Select
              placeholder="Agrupamento de cor"
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider
                    style={{
                      margin: "8px 0",
                    }}
                  />
                  <Space
                    style={{
                      padding: "0 8px 4px",
                    }}
                  >
                    <Input
                      placeholder="Nome da nova cor"
                      ref={inputRef}
                      value={newColor}
                      onChange={onNewColorChange}
                    />
                    <Button
                      type="text"
                      icon={<PlusOutlined />}
                      onClick={addItem}
                    >
                      Add Cor
                    </Button>
                  </Space>
                </>
              )}
              options={colors.map((item) => ({
                label: item,
                value: item,
              }))}
            />
          </Form.Item>
          <Form.Item name="backSameAsFront">
            <Checkbox type="checkbox">O verso é o mesmo que a frente?</Checkbox>
          </Form.Item>
          <Form.Item
            label="Alinhamento da Escrita AjudaPet"
            name="verticalAlignment"
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item label="Tamanho de Rapports">
            <Select
              mode="multiple"
              placeholder="Selecione os tamanhos"
              value={selectedSizes}
              onChange={setSelectedSizes}
              style={{ width: "100%" }}
              options={filteredSizes.map((item) => ({
                value: item,
                label: item,
              }))}
            />
          </Form.Item>
          <Row>
            {selectedSizes.map((e) => {
              return (
                <S.UploadRapportWrapper>
                  <h2>{e}</h2>
                  <UploadInputAdmin
                    onChange={(images) => {
                      if (rapportImages[e] == undefined) {
                        rapportImages[e] = {
                          frontUrl: "",
                          backUrl: "",
                        };
                      }
                      rapportImages[e].frontUrl = images[0].url;
                      setRapportImages({ ...rapportImages });
                    }}
                    label="Frente"
                  ></UploadInputAdmin>
                  <UploadInputAdmin
                    onChange={(images) => {
                      if (rapportImages[e] == undefined) {
                        rapportImages[e] = {
                          frontUrl: "",
                          backUrl: "",
                        };
                      }
                      rapportImages[e].backUrl = images[0].url;
                      setRapportImages({ ...rapportImages });
                    }}
                    label="Verso"
                  ></UploadInputAdmin>
                </S.UploadRapportWrapper>
              );
            })}
          </Row>
          <h4>Selecionar tipos para serem geradas</h4>
          <Row>
            {types
              .filter((e) => {
                if (form.getFieldValue("collarFilter") === "SEM BORRACHA") {
                  return e.title.includes("SEM BORRACHA");
                } else {
                  return !e.title.includes("SEM BORRACHA");
                }
              })
              .map((e) => {
                return (
                  <S.UploadRapportWrapper md={5}>
                    <h4>{e.title}</h4>
                    {selectedSizes.map((size) => {
                      return (
                        <div style={{ width: "100%" }}>
                          <Checkbox
                            onChange={(event) => {
                              const checked = event.target.checked;
                              if (checked) {
                                gabarits.push({
                                  size,
                                  type: e.title,
                                });

                                setGabarits([...gabarits]);
                                return;
                              }
                              gabarits.slice(
                                gabarits.findIndex((j) => j.title == e.title),
                                1
                              );
                              setGabarits([...gabarits]);
                            }}
                            type="checkbox"
                          >
                            {size} - {e.title}
                          </Checkbox>
                        </div>
                      );
                    })}
                  </S.UploadRapportWrapper>
                );
              })}
          </Row>
          <Divider></Divider>
          <Button htmlType="submit">{"Criar"} Gabarito</Button>
        </Form>
      </S.Wrapper>
    </Dashboard>
  );
};
